import LineChart from "components/Charts/LineChart";
import { ChartDatum } from "components/Charts/model";
import VerticalAxisTick from "components/Charts/VerticalAxisTick";
import { MultiValueTooltip } from "components/ChartTooltip";
import {
  chartMinHeight,
  WrapperContainer,
} from "containers/Dashboard/components/CommonComponents";
import lodash from "lodash";
import { GrossNetRenewalRateModel } from "models/report";
import { formatPercentValue, getPeriodAxisLabel } from "utils/format";

interface ContractRenewalGrossNetRateChartProps {
  error?: string;
  isLoading: boolean;
  displayData: GrossNetRenewalRateModel[];
  hideLegend?: boolean;
  legends: ChartDatum<GrossNetRenewalRateModel>[];
}

export default function ContractRenewalGrossNetRateChart(
  props: ContractRenewalGrossNetRateChartProps
) {
  const { error, isLoading, displayData, hideLegend, legends } = props;
  return (
    <WrapperContainer
      isLoading={isLoading}
      error={error}
      isNoData={displayData.length === 0}
    >
      <LineChart
        data={displayData}
        lines={legends}
        xAxisKey="xValue"
        width="100%"
        height={chartMinHeight}
        margin={{ top: 0, bottom: 16, left: 16, right: 16 }}
        xAxisProps={{
          interval: 0,
          tick: ({ payload, ...remains }) => (
            <VerticalAxisTick
              value={getPeriodAxisLabel(
                payload.value,
                props.displayData.map((x) => x.xValue)
              )}
              {...remains}
            />
          ),
        }}
        yAxisProps={{
          tickFormatter: (value) => formatPercentValue(value),
        }}
        renderTooltip={(payload) =>
          MultiValueTooltip(
            "xValue",
            lodash.orderBy(payload, (x) => x.value, "desc"),
            (value) => formatPercentValue(value as number)
          )
        }
        hideLegend={hideLegend}
      />
    </WrapperContainer>
  );
}
