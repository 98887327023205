import ChartTooltip from "components/ChartTooltip";
import {
  Legend,
  Line,
  LineChart as ReLineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { isNumber } from "lodash";

import { formatLegendLabel } from "./chartUtils";
import { ChartCommonProps, ChartDatum } from "./model";

interface Props<DataModel> extends ChartCommonProps<DataModel> {
  lines: ChartDatum<DataModel>[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomizedDot = (props: any) => {
  const { cx, cy, stroke, payload, dataKey } = props;
  const prevItem = props.data.at(payload.index - 1)?.[dataKey];
  const nextItem = props.data.at(payload.index + 1)?.[dataKey];

  if (cx && cy && !isNumber(prevItem) && !isNumber(nextItem)) {
    return (
      <svg x={cx - 2} y={cy - 2} width={4} height={4}>
        <g transform="translate(2 2)">
          <circle r="0" fill={stroke} />
        </g>
      </svg>
    );
  }
  return null;
};

export default function LineChart<DataModel>({
  data,
  width,
  height,
  xAxisKey,
  lines,
  xAxisProps,
  yAxisProps,
  margin,
  renderTooltip,
  hideLegend,
}: Props<DataModel>) {
  return (
    <ResponsiveContainer width={width} height={height}>
      <ReLineChart margin={margin} data={data} stackOffset="sign">
        {!hideLegend && (
          <Legend
            verticalAlign="top"
            align="left"
            iconType="circle"
            iconSize={12}
            wrapperStyle={{
              fontSize: "12px",
              paddingBottom: 14,
            }}
            formatter={(value) => formatLegendLabel(value)}
          />
        )}
        <XAxis
          dataKey={xAxisKey as string}
          axisLine={false}
          tickLine={false}
          tick={{ fontSize: 11 }}
          {...(xAxisProps as Object)}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tick={{ fontSize: 11 }}
          {...(yAxisProps as Object)}
        />
        <Tooltip
          isAnimationActive={false}
          content={
            renderTooltip ? (
              <ChartTooltip hoveringDataKey={null}>
                {(payload) => renderTooltip(payload)}
              </ChartTooltip>
            ) : undefined
          }
        />
        {lines.map((line) => (
          <Line
            key={line.key as string}
            type="monotone"
            dataKey={line.key as string}
            fillOpacity={1}
            stroke={line.color}
            fill={line.color}
            strokeWidth={3}
            dot={<CustomizedDot data={data} />}
          />
        ))}
      </ReLineChart>
    </ResponsiveContainer>
  );
}
