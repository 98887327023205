import {
  FormControl,
  MenuItem,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  InputLabel,
} from "@mui/material";
import {
  AnalysisType,
  CustomerLevel,
  ReportMeasurement,
  RevenueType,
  REVENUE_TYPE_PROPERTY_MAPPING,
} from "common/constants";
import { useChartSettings, useDispatch, useReportSelector } from "common/store";
import GradientCard from "components/Card/GradientCard";
import CohortHeatMap from "components/CohortHeatMap";
import lodashSortBy from "lodash/sortBy";
import lodashGroupBy from "lodash/groupBy";
import {
  ARRCohortReport,
  Axis,
  ByValue,
  CARRCohortReport,
  CohortReport,
  Measurement,
  RelativeTo,
  Show,
} from "models/report";
import { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getCustomerCohortChart } from "services/reportService";
import { setChartSettings, setChartShouldFetch } from "slices/reportSlice";
import useDidUpdateEffect from "utils/hook/useDidUpdateEffect";
import { shouldRefetchRevenueData } from "utils/report";
import { extractMonthString, formatPeriodText } from "utils/format";
import {
  getColumns,
  getRetentionTotal,
  getRows,
  getTenureHeader,
  getTenureKey,
} from "components/CohortHeatMap/utils";
import { exportToExcel } from "utils/exportExcelFile/exportExcel";
import { generateFilterSheet } from "utils/exportExcelFile/generateFilterSheet";
import moment from "moment";
import { UNIT } from "utils/exportExcelFile/constants";

import { WrapperContainer } from "../components/CommonComponents";

import {
  getByOptions,
  MEASUREMENT_MAPPING,
  getShowOptions,
} from "./SectionCohorts.utils";

export default function SectionCohorts() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    reportData,
    reportSettings,
    chartData: allChartData,
  } = useReportSelector();
  const chartSettings = useChartSettings("customer-cohorts");
  const chartData = allChartData["customer-cohorts"];

  const currentMeasurement: Measurement = useMemo(() => {
    return MEASUREMENT_MAPPING[reportSettings.measurement] as Measurement;
  }, [reportSettings.measurement]);

  const [prevMeasurement, setPrevMeasurement] = useState<ReportMeasurement>(
    reportSettings.measurement
  );

  const titleKey = useMemo(() => {
    if (chartSettings.axis === "cohort-tenure")
      return "Dashboard.CohortsCard.ByTenureTitle";
    if (chartSettings.axis === "calendar-period")
      return "Dashboard.CohortsCard.ByCalendarTitle";
    return "";
  }, [chartSettings.axis]);

  const currentShowValue = useMemo(() => {
    return chartSettings.show !== "count" && chartSettings.show !== "volume"
      ? "measure"
      : chartSettings.show;
  }, [chartSettings.show]);

  const filteredData = useMemo(() => {
    if (
      chartData.isLoading ||
      chartData.data === undefined ||
      !reportSettings.filters.minDate ||
      !reportSettings.filters.maxDate
    )
      return [];

    // Comparing format: YYYY-MM
    const startMonth = reportSettings.filters.minDate!.slice(0, -3);
    const endMonth = reportSettings.filters.maxDate!.slice(0, -3);

    const filtered = chartData.data.filter((item: CohortReport) => {
      const itemMonth = extractMonthString(item.Month);
      if (itemMonth < startMonth || itemMonth > endMonth) return false;

      let arrStartDate =
        reportSettings.measurement === ReportMeasurement.ARR ||
        reportSettings.measurement === ReportMeasurement.MRR ||
        reportSettings.measurement === ReportMeasurement.QRR
          ? (item as ARRCohortReport).ARRSTARTDATE
          : (item as CARRCohortReport).CARRSTARTDATE;
      arrStartDate = arrStartDate && arrStartDate.slice(0, 7); // Get month part of the date: YYYY-MM

      if (arrStartDate < startMonth || arrStartDate > endMonth) {
        return false;
      }
      return true;
    });

    return lodashSortBy(filtered, (item) => item.Month);
  }, [
    chartData.isLoading,
    chartData.data,
    reportSettings.measurement,
    reportSettings.filters.minDate,
    reportSettings.filters.maxDate,
  ]);

  const showOptions = useMemo(() => {
    return getShowOptions(reportSettings.measurement);
  }, [reportSettings.measurement]);

  const products = useMemo(() => {
    if (!reportData.mapping) return [];

    return Object.keys(reportData.mapping)
      .filter((x) => x.startsWith("product"))
      .map((x) => ({
        key: x,
        value: reportData.mapping![x],
      }))
      .filter((x) => x.value);
  }, [reportData.mapping]);

  function handleOnPeriodViewChanged(value: RevenueType) {
    if (value !== chartSettings.period) {
      dispatch(
        setChartSettings({
          "customer-cohorts": {
            period: value,
          },
        })
      );
      if (chartSettings.relativeTo === "prior") {
        dispatch(
          setChartSettings({
            "customer-cohorts": {
              priorPeriodComparison: Number(value) as AnalysisType,
            },
          })
        );
      }
    }
  }

  function handleOnAxisChanged(value: Axis) {
    if (value !== chartSettings.axis) {
      dispatch(setChartSettings({ "customer-cohorts": { axis: value } }));
    }
  }

  function handleOnShowChanged(value: Show) {
    if (value !== chartSettings.show) {
      dispatch(
        setChartSettings({
          "customer-cohorts": { show: value, byValue: "base" },
        })
      );
    }
  }

  function handleOnByValueChanged(value: ByValue) {
    const byOldValue = chartSettings.byValue;

    if (value !== byOldValue) {
      dispatch(
        setChartSettings({
          "customer-cohorts": { byValue: value },
        })
      );
    }
    if (reportSettings.params.customerLevel === CustomerLevel.Customer) {
      dispatch(setChartShouldFetch(["customer-cohorts"]));
    }
  }

  function handleRelativeToValueChange(value: RelativeTo) {
    if (value !== chartSettings.relativeTo) {
      dispatch(
        setChartSettings({
          "customer-cohorts": {
            relativeTo: value,
          },
        })
      );
      if (value === "prior") {
        dispatch(
          setChartSettings({
            "customer-cohorts": {
              priorPeriodComparison: Number(
                chartSettings.period
              ) as AnalysisType,
              relativeTo: value,
            },
          })
        );
      }
    }
  }

  function handlePriorPeriodComparisonChange(value: AnalysisType) {
    if (value !== chartSettings.priorPeriodComparison) {
      dispatch(
        setChartSettings({
          "customer-cohorts": { priorPeriodComparison: value },
        })
      );
    }
  }

  function handleProductChange(value?: string): void {
    if (value !== chartSettings.productType) {
      dispatch(
        setChartSettings({
          "customer-cohorts": { productType: value },
        })
      );
    }
  }

  useDidUpdateEffect(() => {
    if (chartSettings.productType) {
      return;
    }
    handleProductChange(products.length > 0 ? products[0].key : undefined);
  }, [products]);

  useEffect(() => {
    if (!reportData.isLoading && chartData.shouldFetch) {
      dispatch(getCustomerCohortChart());
    }
  }, [reportData.isLoading, chartData.shouldFetch]);

  useDidUpdateEffect(() => {
    dispatch(setChartShouldFetch(["customer-cohorts"]));
  }, [chartSettings.priorPeriodComparison]);

  useDidUpdateEffect(() => {
    if (
      reportData.file?.id &&
      shouldRefetchRevenueData(reportSettings.measurement, prevMeasurement)
    ) {
      dispatch(setChartShouldFetch(["customer-cohorts"]));
    }
    setPrevMeasurement(reportSettings.measurement);
  }, [reportSettings.measurement]);

  useDidUpdateEffect(() => {
    dispatch(setChartShouldFetch(["customer-cohorts"]));
  }, [
    reportSettings.filters.customers,
    reportSettings.filters.segmentCustomers,
    reportSettings.filters.segmentProducts,
    reportSettings.params.customerLevel,
    chartSettings.productType,
  ]);

  useDidUpdateEffect(() => {
    if (chartSettings.show !== "count" && chartSettings.show !== "volume") {
      handleOnShowChanged(currentMeasurement);
    }
  }, [currentMeasurement]);

  const headerKey = useMemo(() => {
    const axis = chartSettings.axis || "cohort-tenure";
    const revenueType = chartSettings.period || RevenueType.Monthly;
    if (axis === "calendar-period") {
      return REVENUE_TYPE_PROPERTY_MAPPING[revenueType];
    }
    return getTenureKey(reportSettings.measurement, revenueType);
  }, [chartSettings.axis, reportSettings.measurement, chartSettings.period]);

  const valueKey = useMemo(() => {
    const byValue = chartSettings.byValue || "base";
    if (byValue !== "base") {
      return "retentionValue";
    }
    if (currentShowValue === "count") {
      return "CUSTOMER_COUNT";
    }
    return "SUM_ENDING_BALANCE";
  }, [
    currentShowValue,
    reportSettings.measurement,
    chartSettings.byValue,
    reportSettings.params.customerLevel,
  ]);

  const dataGroupedByCohortMonth = useMemo(() => {
    const groupKey =
      reportSettings.measurement === ReportMeasurement.ARR ||
      reportSettings.measurement === ReportMeasurement.MRR ||
      reportSettings.measurement === ReportMeasurement.QRR
        ? "ARRSTARTDATE"
        : "CARRSTARTDATE";
    return lodashGroupBy(
      filteredData,
      (item) => item[groupKey as keyof CohortReport]
    );
  }, [filteredData, reportSettings.measurement]);

  const columns = useMemo(() => {
    return getColumns(dataGroupedByCohortMonth, headerKey);
  }, [filteredData, headerKey]);

  const rows = useMemo(() => {
    const revenueType = chartSettings.period || RevenueType.Monthly;
    const byValue = chartSettings.byValue || "base";
    return getRows(
      filteredData,
      revenueType,
      reportSettings.measurement,
      byValue,
      chartSettings.relativeTo || "initial",
      headerKey,
      reportSettings.params.customerLevel
    );
  }, [
    headerKey,
    chartSettings.period,
    chartSettings.byValue,
    chartSettings.relativeTo,
    reportSettings.measurement,
    reportSettings.params.customerLevel,
    filteredData,
  ]);

  const handleFormatPercentCell = (
    colItem: string | number
  ): number | string => {
    const percentValue = getRetentionTotal(
      rows,
      headerKey,
      colItem,
      chartSettings.byValue || "base",
      chartSettings.relativeTo || "initial",
      reportSettings.params.customerLevel
    );

    return percentValue
      ? percentValue !== "Common.NotAvailable"
        ? Number(
            (Number.parseFloat(percentValue.replace("%", "")) / 100).toFixed(4)
          )
        : 0
      : "";
  };

  const handleCellValueCalendarPeriod = (
    rowData: CohortReport[],
    colItem: string | number
  ) => {
    const cell = rowData.find(
      (item) => item[headerKey as keyof CohortReport] === colItem
    );
    if (cell && cell[valueKey] !== undefined) {
      const rawValue = Number(cell[valueKey]);
      if (Number.isNaN(rawValue)) {
        return "";
      }
      return chartSettings.byValue === "base"
        ? rawValue
        : Number((rawValue / 100).toFixed(6));
    }
    return "";
  };

  const handleCellValueCohortTenure = (
    rowData: CohortReport[],
    colIndex: number
  ) => {
    return rowData &&
      rowData[colIndex] &&
      rowData[colIndex][valueKey] !== undefined
      ? rowData[colIndex][valueKey] !== "Common.NotAvailable"
        ? chartSettings.byValue === "base"
          ? rowData[colIndex][valueKey]
          : Number(
              Number((rowData[colIndex][valueKey] as number) / 100).toFixed(6)
            )
        : ""
      : null;
  };

  const renderUnitData = () => {
    if (currentShowValue === "count") {
      return chartSettings.byValue === "customer" ? UNIT.PERCENT : "";
    }
    if (currentShowValue === "measure") {
      return chartSettings.byValue === "base" ? UNIT.THOUSANDS : UNIT.PERCENT;
    }

    return "";
  };

  const handleExport = () => {
    const revenueType = chartSettings.period || RevenueType.Monthly;
    const categories = [
      ...rows.map((item) => formatPeriodText(item.cohort)),
      "Total",
    ];
    const headerData = [
      "",
      ...columns.map((col) => getTenureHeader(revenueType, col)),
    ];
    const data = columns.map((colItem, colIndex) => {
      const xValue = getTenureHeader(revenueType, colItem);
      const cellItems: Record<string, unknown> = {};
      for (const row of rows) {
        const formattedCohort = formatPeriodText(row.cohort);
        const rowData = row.data;
        cellItems[formattedCohort] =
          chartSettings.axis === "cohort-tenure"
            ? handleCellValueCohortTenure(rowData, colIndex)
            : handleCellValueCalendarPeriod(rowData, colItem);
      }
      const numericValues = Object.values(cellItems).filter(
        (val): val is number => typeof val === "number"
      );
      const totalSum = numericValues.reduce((total, val) => total + val, 0);
      const finalTotal =
        chartSettings.byValue === "base"
          ? totalSum
          : Number((handleFormatPercentCell(colItem) as number).toFixed(4));
      cellItems.Total = finalTotal;
      return { xValue, ...cellItems };
    });

    const sheet2 = generateFilterSheet(
      reportSettings,
      reportData,
      t,
      chartSettings
    );
    const sheet1 = {
      sheetName: "Customer-Cohorts",
      header: headerData,
      categories,
      data: data || [],
      subData: {},
      option: {
        unitData: renderUnitData(),
      },
    };

    exportToExcel(
      [sheet1, sheet2],
      `${moment().format("YYYY-MM-DD")}_${t("Dashboard.Charts.SectionCohorts")}`
    );
  };

  return (
    <>
      <Typography variant="h6" marginBottom={2} color="var(--text-secondary)">
        {t("Dashboard.Charts.SectionCohorts")}
      </Typography>
      <GradientCard
        title={
          <>
            <Stack
              direction="row"
              alignItems="center"
              flexWrap="wrap"
              marginBottom={2}
              gap={2}
            >
              <Stack direction="row" alignItems="center">
                <Typography variant="h5" marginRight={2}>
                  {t(titleKey)}:
                </Typography>
                <ToggleButtonGroup
                  exclusive
                  value={chartSettings.period}
                  onChange={(_, value) =>
                    value !== null && handleOnPeriodViewChanged(value)
                  }
                >
                  <ToggleButton value={RevenueType.Monthly}>
                    {t("Common.Month")}
                  </ToggleButton>
                  <ToggleButton value={RevenueType.Quarterly}>
                    {t("Common.Quarter")}
                  </ToggleButton>
                  <ToggleButton value={RevenueType.Yearly}>
                    {t("Common.Year")}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Typography variant="h5" marginRight={2}>
                  {t("Common.Axis")}:
                </Typography>
                <ToggleButtonGroup
                  exclusive
                  value={chartSettings.axis}
                  onChange={(_, value) => handleOnAxisChanged(value)}
                >
                  <ToggleButton value="cohort-tenure">
                    {t("Dashboard.CohortsCard.AxisTenure")}
                  </ToggleButton>
                  <ToggleButton value="calendar-period">
                    {t("Dashboard.CohortsCard.AxisCalendar")}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Stack>
              {reportSettings.params.customerLevel ===
                CustomerLevel.CustomerProduct && (
                <FormControl size="small" sx={{ width: "220px" }}>
                  <InputLabel>{t("Common.ProdSeg")}</InputLabel>
                  <Select
                    MenuProps={{ disableScrollLock: true }}
                    label={t("Common.ProdSeg")}
                    value={chartSettings.productType ?? ""}
                    onChange={(e) => handleProductChange(e.target.value)}
                  >
                    {products.map((x) => (
                      <MenuItem key={x.key} value={x.key}>
                        {x.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              flexWrap="wrap"
              marginBottom={2}
              gap={2}
            >
              <Stack direction="row">
                <Typography variant="h6" marginRight={2}>
                  {t("Dashboard.CohortsCard.LabelShow")}:
                </Typography>
                <ToggleButtonGroup
                  exclusive
                  value={chartSettings.show}
                  onChange={(_, value) =>
                    value !== null && handleOnShowChanged(value)
                  }
                >
                  {showOptions.map((option) => (
                    <ToggleButton
                      key={option.value}
                      value={option.value}
                      disabled={option.disabled}
                    >
                      {t(option.labelKey)}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Stack>
              <Stack direction="row">
                <Typography variant="h6" marginRight={2}>
                  {t("Dashboard.CohortsCard.LabelBy")}:
                </Typography>
                <FormControl>
                  <Select
                    size="small"
                    sx={{ width: "250px" }}
                    value={chartSettings.byValue}
                    onChange={(e) =>
                      handleOnByValueChanged(e.target.value as ByValue)
                    }
                  >
                    {getByOptions(chartSettings.show).map((options) => (
                      <MenuItem key={options.value} value={options.value}>
                        {t(options.labelKey)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              {chartSettings.byValue !== "base" && (
                <Stack direction="row" alignItems="center">
                  <Typography variant="h6" marginRight={2}>
                    {t("Dashboard.CohortsCard.LabelRelation")}:
                  </Typography>
                  <ToggleButtonGroup
                    exclusive
                    value={chartSettings.relativeTo}
                    onChange={(_, value) =>
                      value !== null && handleRelativeToValueChange(value)
                    }
                  >
                    <ToggleButton value="initial">
                      {t("Dashboard.CohortsCard.RelationSelect.InitialPeriod")}
                    </ToggleButton>
                    <ToggleButton value="prior">
                      {t("Dashboard.CohortsCard.RelationSelect.PriorPeriod")}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Stack>
              )}
              {chartSettings.byValue !== "base" &&
                chartSettings.relativeTo === "prior" && (
                  <Stack direction="row" alignItems="center">
                    <Typography variant="h6" marginRight={2}>
                      {t("Dashboard.CohortsCard.LabelPriorPeriodComparison")}:
                    </Typography>
                    <FormControl>
                      <Select
                        size="small"
                        sx={{ width: "220px" }}
                        value={chartSettings.priorPeriodComparison}
                        onChange={(e) =>
                          handlePriorPeriodComparisonChange(
                            e.target.value as AnalysisType
                          )
                        }
                      >
                        <MenuItem value={AnalysisType.YoY}>
                          {t(
                            t(
                              "Dashboard.CohortsCard.PriorPeriodComparisonSelect.YoY"
                            )
                          )}
                        </MenuItem>
                        {chartSettings.period !== RevenueType.Yearly && (
                          <MenuItem value={AnalysisType.QoQ}>
                            {t(
                              t(
                                "Dashboard.CohortsCard.PriorPeriodComparisonSelect.QoQ"
                              )
                            )}
                          </MenuItem>
                        )}
                        {chartSettings.period === RevenueType.Monthly && (
                          <MenuItem value={AnalysisType.MoM}>
                            {t(
                              t(
                                "Dashboard.CohortsCard.PriorPeriodComparisonSelect.MoM"
                              )
                            )}
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Stack>
                )}
            </Stack>
          </>
        }
        subheader={
          chartSettings.show !== "count" && chartSettings.show !== "volume" ? (
            <Typography variant="body2" fontStyle="italic">
              {t("Dashboard.DollarsInThounsands")}
            </Typography>
          ) : undefined
        }
        onExport={handleExport}
        onMoreClick={() => {}}
        isDisabledActions={
          reportData.isLoading ||
          chartData.isLoading ||
          !!chartData.error ||
          !!reportData.error
        }
      >
        <WrapperContainer
          isLoading={reportData.isLoading || chartData.isLoading}
          error={reportData.error || chartData.error}
          isNoData={filteredData.length === 0}
          minHeight={0}
        >
          <CohortHeatMap
            axis={chartSettings.axis || "cohort-tenure"}
            revenueType={chartSettings.period || RevenueType.Monthly}
            by={chartSettings.byValue || "base"}
            relativeTo={chartSettings.relativeTo || "initial"}
            customerLevel={reportSettings.params.customerLevel}
            rows={rows}
            columns={columns}
            valueKey={valueKey}
            headerKey={headerKey}
            show={currentShowValue}
          />
        </WrapperContainer>
      </GradientCard>
    </>
  );
}
