import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  FormHelperText,
} from "@mui/material";
import { FISCAL_START_MONTHS } from "common/constants/reportFilters";
import { useState, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDataMappingCtx } from "containers/Workspace/DataMapping/DataMappingContext";

import { MappingSelectProps } from "./types";
import MappingMenuItem from "./MappingMenuItem";

export default function MappingSelect({
  row,
  sourceFields,
  mappingValues,
  invalid,
  canRemap = true,
  onChange,
}: MappingSelectProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const selectRef = useRef(null);
  const { mappingOrigin } = useDataMappingCtx();
  const { attribute } = row;

  const isMappedField = (field: string) => {
    return Object.values(mappingValues).includes(field);
  };
  const isRemapped =
    mappingOrigin[attribute] &&
    mappingValues[attribute] &&
    mappingValues[attribute] !== mappingOrigin[attribute];

  const handleClose = () => {
    setOpen(false);
  };

  const selectItem = () => {
    if (attribute === "FiscalStartMonth") {
      return FISCAL_START_MONTHS.map((field) => (
        <MenuItem value={field.value} key={field.value}>
          {field.label}
        </MenuItem>
      ));
    }
    return sourceFields.map((field) =>
      canRemap && isMappedField(field) ? (
        <MappingMenuItem
          key={field}
          value={field}
          row={row}
          handleClose={handleClose}
        >
          {field}
        </MappingMenuItem>
      ) : (
        <MenuItem value={field} key={field}>
          {field}
        </MenuItem>
      )
    );
  };
  return (
    <FormControl
      fullWidth
      size="small"
      sx={{
        color: "text.secondary",
      }}
    >
      <InputLabel>{t("MappingTable.SelectPlaceHolder")}</InputLabel>
      <Select
        ref={selectRef}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={handleClose}
        sx={{
          "& fieldset": {
            borderColor: isRemapped ? "info.main" : undefined,
          },
          "& .MuiSelect-select": {
            color: invalid
              ? "error.main"
              : isRemapped
              ? "info.dark"
              : "primary.main",
          },
        }}
        label={t("MappingTable.SelectPlaceHolder")}
        value={mappingValues[attribute] || ""}
        onChange={(e: SelectChangeEvent) => onChange(attribute, e.target.value)}
      >
        <MenuItem value="">
          <em>{t("MappingTable.OptionNone")}</em>
        </MenuItem>
        {selectItem()}
      </Select>
      {isRemapped && (
        <Stack direction="row">
          <FormHelperText
            data-testid="MappingSelect__remappedFromLabel"
            sx={{ margin: 0 }}
          >
            <Trans
              i18nKey="MappingTable.LabelRemappedFrom"
              components={{
                AttrLabel: (
                  <Typography
                    component="span"
                    color="primary"
                    sx={{ fontSize: 12, fontWeight: 700 }}
                  />
                ),
              }}
              values={{ attr: mappingOrigin[attribute] }}
            />
          </FormHelperText>
        </Stack>
      )}
    </FormControl>
  );
}
