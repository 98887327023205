import {
  DownloadOutlined,
  FilterList,
  MoreHorizOutlined,
  SettingsOutlined,
  SwapVertOutlined,
} from "@mui/icons-material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { dashboardManagerClient } from "common/client/dashboardManagerClient";
import {
  Params,
  useLoaderData,
  useNavigate,
  useParams,
} from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import { formatFileSize } from "utils/format";
import {
  DATETIME_FORMAT,
  FileStatus,
  FileStatusText,
  ROUTE_PATHS,
} from "common/constants";
import { useTranslation } from "react-i18next";
import Modal from "components/Modal";
import { useState } from "react";
import { WORKSPACE_ROUTES } from "containers/Workspace/config";
import IconMenu from "components/Menu/IconMenu";
export type DataType = {
  fileName: string;
  uploadedAt: string;
  noRequiredFields: number;
  noOptionalFields: number;
  valid: boolean;
  fileSize: number;
  columns: number;
  rows: number;
  history: { fileName: string; status: FileStatus; updatedAt: string }[];
};
export default function File() {
  const data = useLoaderData() as DataType;
  const history = [...data.history].reverse();
  const theme = useTheme();
  const params = useParams();
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <Stack
        direction={"row"}
        spacing={"24px"}
        alignItems={"flex-start"}
        sx={{ flexGrow: 1 }}
      >
        <Card sx={{ flexGrow: 1 }} elevation={0}>
          <CardHeader
            title={
              <Typography variant="h5">
                {t("ManageSourceData.File.Header")}
              </Typography>
            }
            action={
              <IconMenu
                icon={<MoreHorizOutlined sx={{ color: "white" }} />}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                options={[
                  {
                    id: "export",
                    icon: <DownloadOutlined />,
                    title: t("ManageSourceData.File.Export"),
                  },
                  {
                    id: "divider-1",
                  },
                  {
                    id: "replace",
                    icon: <SwapVertOutlined />,
                    color: theme.palette.warning.main,
                    title: t("ManageSourceData.File.Replace"),
                    onClick: () => {
                      setOpenModal(true);
                    },
                  },
                  { id: "divider-2" },
                  {
                    id: "setting",
                    icon: <SettingsOutlined />,
                    title: t("ManageSourceData.File.Settings"),
                  },
                ]}
              />
            }
            sx={{
              backgroundImage: `var(--pattern-card-background-image)`,
              backgroundSize: "auto 100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right",
              color: "var(--white)",
            }}
          />
          <Paper elevation={0} sx={{ p: "24px" }}>
            <CardContent sx={{ minWidth: "500px" }}>
              <Stack direction={"row"} spacing={1}>
                <Typography color="text.secondary" variant="h6">
                  {t("ManageSourceData.File.Name")}:
                </Typography>
                <Typography variant="h6">{data.fileName}</Typography>
              </Stack>
              <Stack direction={"row"} spacing={1}>
                <Typography color="text.secondary" variant="h6">
                  {t("ManageSourceData.File.DateAdded")}:
                </Typography>
                <Typography variant="h6">
                  {new Date(data.uploadedAt).toLocaleDateString(
                    "en-US",
                    DATETIME_FORMAT
                  )}
                </Typography>
              </Stack>
              <Stack direction={"row"} spacing={1}>
                <Typography color="text.secondary" variant="h6">
                  {t("ManageSourceData.File.RequiredField")}:
                </Typography>
                <Typography color="success.main" variant="h6">
                  {data.noRequiredFields}{" "}
                  {t("ManageSourceData.File.FieldsMapped")}
                </Typography>
              </Stack>
              <Stack direction={"row"} spacing={1}>
                <Typography color="text.secondary" variant="h6">
                  {t("ManageSourceData.File.OptionalFields")}:
                </Typography>
                <Typography color="success.main" variant="h6">
                  {data.noOptionalFields}{" "}
                  {t("ManageSourceData.File.FieldsMapped")}
                </Typography>
              </Stack>
              <Stack direction={"row"} spacing={1}>
                <Typography color="text.secondary" variant="h6">
                  {t("ManageSourceData.File.ValidationStatus")}:
                </Typography>
                {data.valid ? (
                  <Typography color="success.main" variant="h6">
                    {t("ManageSourceData.File.Valid")}
                  </Typography>
                ) : (
                  <Typography color="error.main" variant="h6">
                    {t("ManageSourceData.File.NotValid")}
                  </Typography>
                )}
              </Stack>
              <Stack direction={"row"} spacing={1}>
                <Typography color="text.secondary" variant="h6">
                  {t("ManageSourceData.File.Size")}:
                </Typography>
                <Typography variant="h6">
                  {formatFileSize(data.fileSize)}
                </Typography>
              </Stack>
              <Stack direction={"row"} spacing={1}>
                <Typography color="text.secondary" variant="h6">
                  Columns:
                </Typography>
                <Typography variant="h6">
                  {new Intl.NumberFormat("en-US").format(data.columns)}
                </Typography>
              </Stack>
              <Stack direction={"row"} spacing={1}>
                <Typography color="text.secondary" variant="h6">
                  {t("ManageSourceData.File.Rows")}:
                </Typography>
                <Typography variant="h6">
                  {new Intl.NumberFormat("en-US").format(data.rows)}
                </Typography>
              </Stack>
            </CardContent>
            <Divider sx={{ my: "24px" }} />
            <CardActions>
              <Button
                variant="outlined"
                startIcon={<DownloadOutlined />}
                sx={{ borderRadius: "120px" }}
              >
                {t("ManageSourceData.File.Download")}
              </Button>
              <Button
                startIcon={<SwapVertOutlined />}
                color="warning"
                sx={{
                  textTransform: "capitalize",
                  borderRadius: "120px",
                }}
                onClick={() => setOpenModal(true)}
              >
                {t("ManageSourceData.File.Replace")}
              </Button>
            </CardActions>
          </Paper>
        </Card>
        <Card elevation={0}>
          <CardHeader
            title={
              <Typography variant="h5">
                {t("ManageSourceData.File.History")}
              </Typography>
            }
            action={<FilterList />}
            sx={{
              backgroundImage: `var(--pattern-card-background-image)`,
              backgroundSize: "auto 100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right",
              color: "var(--white)",
            }}
          />
          <CardContent>
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              {history.map((item, i) => (
                <TimelineItem key={i}>
                  <TimelineSeparator>
                    <TimelineDot />
                    {i < history.length - 1 && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>
                    <Stack justifyContent={"center"}>
                      <Stack direction="row" spacing={2}>
                        <Typography
                          variant="body2"
                          fontWeight={700}
                          title={item.fileName}
                          sx={{
                            width: "100px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {item.fileName}
                        </Typography>
                        <Typography
                          variant="body2"
                          fontWeight={400}
                          sx={{ minWidth: "fit-content" }}
                        >
                          {FileStatusText[item.status]}
                        </Typography>
                      </Stack>
                      <Typography variant="caption" color="text.secondary">
                        <ReactTimeAgo date={Date.parse(item.updatedAt + "Z")} />
                      </Typography>
                    </Stack>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </CardContent>
        </Card>
      </Stack>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Card elevation={0}>
          <CardHeader
            title={
              <Typography variant="h5" color="error.main">
                {t("ManageSourceData.File.ConfirmHeader")}
              </Typography>
            }
            subheader={
              <Typography variant="body2" color="error.main">
                {t("ManageSourceData.File.ConfirmSubHeader")}
              </Typography>
            }
          />
          <CardContent>
            <ul className="list-disc pl-5">
              <li>
                <Typography variant="subtitle1" color="text.primary">
                  {t("ManageSourceData.File.ConfirmWarning1")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" color="text.primary">
                  {t("ManageSourceData.File.ConfirmWarning2")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" color="text.primary">
                  {t("ManageSourceData.File.ConfirmWarning3")}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" color="text.primary">
                  {t("ManageSourceData.File.ConfirmWarning4")}
                </Typography>
              </li>
            </ul>
          </CardContent>
          <CardActions>
            <Button
              variant="outlined"
              color="error"
              sx={{ borderRadius: "24px" }}
              onClick={() => {
                navigate(
                  `/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}/${params.wsId}/${WORKSPACE_ROUTES.IMPORT}?dashboardId=${params.dashboardId}`
                );
              }}
            >
              {t("ManageSourceData.File.ReplaceSourceDataBtn")}
            </Button>
            <Button
              sx={{ borderRadius: "24px" }}
              onClick={() => setOpenModal(false)}
            >
              {t("Common.Cancel")}
            </Button>
          </CardActions>
        </Card>
      </Modal>
    </>
  );
}
export async function loader({ params }: { params: Params }) {
  const dashboardId = params.dashboardId ?? "";
  const workspaceId = params.wsId ?? "";
  const source = await dashboardManagerClient.get(
    `dashboard/workspaces/${workspaceId}/dashboards/${dashboardId}/get-source-data`
  );
  return source.data.data;
}
