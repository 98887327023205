import Table from "components/Table";
import { useMemo } from "react";
import { OverviewChartModel } from "models/report";
import { useTranslation } from "react-i18next";
import lodashGroupBy from "lodash/groupBy";
import { ReportView } from "common/constants";
import { Box } from "@mui/material";

import { getOverviewTableColumns, getOverviewTableData } from "./utils";

interface OverviewDataTableProps {
  chartData: OverviewChartModel[];
  segmentItems: (string | number)[];
  segmentationView?: ReportView | null;
  isLoading?: boolean;
  formatValue?: (value: number | null) => number | string;
}

export default function OverviewDataTable({
  chartData,
  segmentItems,
  segmentationView,
  isLoading,
  formatValue,
}: OverviewDataTableProps) {
  const { t } = useTranslation();

  const periods = useMemo(() => {
    return Object.keys(lodashGroupBy(chartData, (item) => item.xValue));
  }, [chartData]);

  const columns = useMemo(() => {
    return getOverviewTableColumns(periods, t, segmentationView, formatValue);
  }, [periods, segmentationView, formatValue]);

  const tableData = useMemo(() => {
    return getOverviewTableData(chartData, segmentItems);
  }, [chartData, segmentItems]);

  if (isLoading) {
    // No need to show a loading indicator
    // Will reuse the loading indicator from chart view
    return null;
  }

  return (
    <Box marginTop={4}>
      <Table data={tableData} columns={columns} rowKey="segmentItem" />
    </Box>
  );
}
