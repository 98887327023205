import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid2";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import {
  AnalysisType,
  CustomerLevel,
  RevenueType,
  GLOBAL_SETTINGS,
} from "common/constants";
import { useDispatch, useReportSelector } from "common/store";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { setOriginalSettings, setReportParams } from "slices/reportSlice";
import useFetch from "utils/hook/useFetch";
import { updateGlobalSettings } from "services/globalSettingsService";
import { useParams } from "react-router-dom";
import { GlobalSettingCategory } from "models/globalSettings";
import { CircularProgress } from "@mui/material";

const maxThreshold = 10_000;

export default function Parameters() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id: teamId, wsId } = useParams();

  const { reportSettings, reportData } = useReportSelector();
  const { execute: executeUpdateGlobalSettings } = useFetch({
    fn: (payload: {
      category: GlobalSettingCategory;
      name: string;
      value: string;
    }) => updateGlobalSettings(teamId!, wsId!, payload),
  });

  useEffect(() => {
    dispatch(setOriginalSettings(reportSettings));
  }, []);

  function handleOnThresholdChanged(value: string): void {
    let newThreshold = Number.parseInt(value);
    if (newThreshold < 0) newThreshold = 0;
    if (newThreshold > maxThreshold) newThreshold = maxThreshold;
    dispatch(setReportParams({ reactivationThreshold: newThreshold }));
    executeUpdateGlobalSettings({
      ...GLOBAL_SETTINGS.threshold,
      value,
    });
  }

  function handleOnThresholdClick(type: "+" | "-"): void {
    if (type === "+")
      handleOnThresholdChanged(
        (reportSettings.params.reactivationThreshold + 1).toString()
      );
    if (type === "-")
      handleOnThresholdChanged(
        (reportSettings.params.reactivationThreshold - 1).toString()
      );
  }

  function handleOnTimeFrameChanged(_: unknown, value: string): void {
    const newRevenueType = Number.parseInt(value) as RevenueType;
    dispatch(setReportParams({ revenueType: newRevenueType }));
    executeUpdateGlobalSettings({
      ...GLOBAL_SETTINGS.revenueType,
      value,
    });
    if (reportSettings.params.analysisType < newRevenueType) {
      dispatch(setReportParams({ analysisType: AnalysisType.YoY }));
      executeUpdateGlobalSettings({
        ...GLOBAL_SETTINGS.analysisType,
        value: String(AnalysisType.YoY),
      });
    }
  }

  function handleOnViewChanged(_: unknown, value: string): void {
    dispatch(
      setReportParams({
        analysisType: Number.parseInt(value) as AnalysisType,
      })
    );
    executeUpdateGlobalSettings({
      ...GLOBAL_SETTINGS.analysisType,
      value,
    });
  }

  function handleOnCustomerLevelChanged(_: unknown, value: string): void {
    dispatch(
      setReportParams({
        customerLevel: Number.parseInt(value) as CustomerLevel,
      })
    );
    executeUpdateGlobalSettings({
      ...GLOBAL_SETTINGS.customerLevel,
      value,
    });
  }

  if (reportData.isLoading) {
    return <CircularProgress />;
  }

  return (
    <Grid container spacing={3}>
      <Grid size={12}>
        <Typography variant="body1">
          {t("DashboardSettings.Parameters.Description1")}
          <strong>{t("DashboardSettings.Parameters.Description2")}</strong>
          {t("DashboardSettings.Parameters.Description3")}
        </Typography>
      </Grid>
      <Grid size={6}>
        <FormControl>
          <Typography variant="caption">
            {t("DashboardSettings.Parameters.GlobalScope")}
          </Typography>
          <RadioGroup
            value={reportSettings.params.customerLevel}
            onChange={handleOnCustomerLevelChanged}
          >
            <FormControlLabel
              value={CustomerLevel.Customer}
              control={<Radio />}
              label={t("Common.Customer")}
            />
            <FormControlLabel
              value={CustomerLevel.CustomerProduct}
              control={<Radio />}
              label={t("Common.CustomerProduct")}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid size={6}>
        <FormControl>
          <Typography variant="caption">
            {t("DashboardSettings.Parameters.Threshold")}
          </Typography>
          <Box display="flex">
            <OutlinedInput
              type="number"
              id="threshold-input"
              sx={{ width: "105px" }}
              value={reportSettings.params.reactivationThreshold}
              onChange={(e) => handleOnThresholdChanged(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  {t("Common.Month")}
                </InputAdornment>
              }
            />
            <ButtonGroup
              size="small"
              orientation="vertical"
              sx={{ paddingLeft: 1 }}
            >
              <Button onClick={() => handleOnThresholdClick("+")}>+</Button>
              <Button onClick={() => handleOnThresholdClick("-")}>-</Button>
            </ButtonGroup>
          </Box>
        </FormControl>
      </Grid>
      <Grid size={6}>
        <FormControl>
          <Typography variant="caption">
            {t("DashboardSettings.Parameters.GlobalView")}
          </Typography>
          <RadioGroup
            value={reportSettings.params.analysisType}
            onChange={handleOnViewChanged}
          >
            <FormControlLabel
              value={AnalysisType.YoY}
              control={<Radio />}
              label={t("DashboardSettings.Parameters.YOY")}
            />
            <FormControlLabel
              value={AnalysisType.QoQ}
              disabled={
                reportSettings.params.revenueType === RevenueType.Yearly
              }
              control={<Radio />}
              label={t("DashboardSettings.Parameters.QOQ")}
            />
            <FormControlLabel
              value={AnalysisType.MoM}
              disabled={
                reportSettings.params.revenueType === RevenueType.Yearly ||
                reportSettings.params.revenueType === RevenueType.Quarterly
              }
              control={<Radio />}
              label={t("DashboardSettings.Parameters.MOM")}
            />
            <FormControlLabel
              disabled
              value="ytd"
              control={<Radio />}
              label={t("DashboardSettings.Parameters.YTD")}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid size={6}>
        <FormControl>
          <Typography variant="caption">
            {t("DashboardSettings.Parameters.GlobalTimeFrame")}
          </Typography>
          <RadioGroup
            value={reportSettings.params.revenueType}
            onChange={handleOnTimeFrameChanged}
          >
            <FormControlLabel
              value={RevenueType.Yearly}
              control={<Radio />}
              label={t("DashboardSettings.Parameters.Yearly")}
            />
            <FormControlLabel
              value={RevenueType.Quarterly}
              control={<Radio />}
              label={t("DashboardSettings.Parameters.Quarterly")}
            />
            <FormControlLabel
              value={RevenueType.Monthly}
              control={<Radio />}
              label={t("DashboardSettings.Parameters.Monthly")}
            />
            <FormControlLabel
              disabled
              value="ytd"
              control={<Radio />}
              label={t("DashboardSettings.Parameters.YTD")}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
}
