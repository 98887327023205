import { teamServiceClient } from "common/client/teamManagementClient";
import { createAppThunk } from "common/appThunk";
import { GlobalSettings, GlobalSettingCategory } from "models/globalSettings";
import { extractGlobalSettings } from "utils/globalSettingsUtils";

export const getSettings = createAppThunk<
  GlobalSettings,
  {
    teamId: string;
    wsId: string;
    dashboardId: string;
  }
>("globalSettings/getSettings", async ({ teamId, wsId }) => {
  const { data: settings } = await teamServiceClient.get(
    `teams/${teamId}/workspaces/${wsId}/settings`
  );
  return extractGlobalSettings(settings.data);
});

export async function updateGlobalSettings(
  teamId: string,
  workspaceId: string,
  payload: { category: GlobalSettingCategory; name: string; value: string }
) {
  const { data: response } = await teamServiceClient.put(
    `teams/${teamId}/workspaces/${workspaceId}/settings`,
    payload
  );
  return response.data;
}
