import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import GradientCard from "components/Card/GradientCard";
import ContractRenewalDetailTable from "containers/Dashboard/charts/ContractRenewalDetailTable";
import {
  ContractRenewalCategoryModel,
  ContractRenewalCategoryResponse,
} from "models/report";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ChartData,
  ReportData,
  ReportSettings,
} from "slices/models/reportSliceModel";
import ContractRenewalDetailChart from "containers/Dashboard/charts/ContractRenewalDetailChart";
import { exportToExcel } from "utils/exportExcelFile/exportExcel";
import moment from "moment";
import { UNIT } from "utils/exportExcelFile/constants";
import {
  ContractRenewalChartSettings,
  generateFilterSheet,
} from "utils/exportExcelFile/generateFilterSheet";
import {
  getContractRenewalDetailTableColumns,
  getContractRenewalDetailTableData,
} from "utils/chartUtils/contractRenewal";

import { chartMinHeight, WrapperContainer } from "../CommonComponents";

interface ContractRenewalDetailProps {
  reportSettings: ReportSettings;
  reportData: ReportData;
  chartData: ChartData<ContractRenewalCategoryResponse[]>;
  displayableReportData: ContractRenewalCategoryModel[];
  chartSettings: ContractRenewalChartSettings;
}

export function ContractRenewalDetail(props: ContractRenewalDetailProps) {
  const {
    reportSettings,
    reportData,
    chartData,
    displayableReportData,
    chartSettings,
  } = props;
  const { t } = useTranslation();
  const [viewToggle, setViewToggle] = useState(["chart"]);

  const columns = useMemo(
    () => getContractRenewalDetailTableColumns(displayableReportData),
    [displayableReportData]
  );

  const tableData = useMemo(
    () => getContractRenewalDetailTableData(displayableReportData),
    [displayableReportData]
  );

  const handleExport = () => {
    const categories = [...tableData.map((item) => item.category), "Total"];

    const headerData = [
      t("Dashboard.DollarsInThounsands"),
      ...displayableReportData.map((row) =>
        moment(row.xValue, "YYYY-MM", true).isValid()
          ? moment(row.xValue).format("YYYY-MM")
          : row.xValue
      ),
    ];

    const sheet2 = generateFilterSheet(
      reportSettings,
      reportData,
      t,
      chartSettings
    );
    const sheet1 = {
      sheetName: "Contract Renewal Detail",
      header: headerData,
      categories,
      data:
        displayableReportData.map((item) => ({
          ...item,
          Total:
            (item["Expiring ACV"] ?? 0) +
            (item["Lost ACV"] ?? 0) +
            (item["Downsell ACV"] ?? 0) +
            (item["Upsell ACV"] ?? 0),
        })) || [],
      subData: {},
      option: {
        unitData: UNIT.THOUSANDS,
      },
    };
    exportToExcel(
      [sheet1, sheet2],
      `${moment().format("YYYY-MM-DD")}_${t(
        "Dashboard.Charts.ContractRenewalDetail"
      )}`
    );
  };
  return (
    <GradientCard
      title={t("Dashboard.Charts.ContractRenewalDetail")}
      sx={{
        marginTop: 3,
      }}
      subheader={
        <Typography variant="body2" fontStyle="italic">
          {t("Dashboard.DollarsInThounsands")}
        </Typography>
      }
      onViewToggle={(x) => setViewToggle(x)}
      onMoreClick={() => console.log("more")}
      onExport={handleExport}
      isDisabledActions={
        reportData.isLoading ||
        chartData.isLoading ||
        !!chartData.error ||
        !!reportData.error
      }
    >
      <WrapperContainer
        isLoading={reportData.isLoading || chartData.isLoading}
        error={chartData.error || reportData.error}
        isNoData={displayableReportData.length === 0}
      >
        {viewToggle.includes("chart") && (
          <ContractRenewalDetailChart
            height={chartMinHeight}
            data={displayableReportData}
          />
        )}
        {viewToggle.includes("data") && (
          <Box sx={{ marginTop: 3, width: "100%" }}>
            <ContractRenewalDetailTable
              columns={columns}
              tableData={tableData}
            />
          </Box>
        )}
      </WrapperContainer>
    </GradientCard>
  );
}
