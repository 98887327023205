import { ChevronLeftOutlined, ChevronRightOutlined } from "@mui/icons-material";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import moment, { Moment } from "moment";
import React, { useMemo } from "react";
import ReactDatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.css";
import { t } from "i18next";

const MyContainer = ({
  className,
  children,
}: {
  className: string;
  children: React.ReactNode;
}) => {
  return (
    <Card elevation={8} sx={{ paddingX: 2, zIndex: 100 }}>
      <CalendarContainer className={className}>
        <div style={{ position: "relative" }}>{children}</div>
      </CalendarContainer>
    </Card>
  );
};

const MyHeader = (props: {
  monthDate: Date;
  customHeaderCount: number;
  decreaseYear: React.MouseEventHandler<HTMLButtonElement> | undefined;
  increaseYear: React.MouseEventHandler<HTMLButtonElement> | undefined;
}) => {
  return (
    <Stack direction="row">
      <Typography
        flexGrow={1}
        variant="subtitle1"
        fontFamily="Roboto"
        textAlign="left"
        fontWeight={500}
        alignContent="center"
      >
        {t("FiscalYear")}
        {props.monthDate.toLocaleString("en-US", {
          year: "numeric",
        })}
      </Typography>
      <IconButton aria-label="Previous Month" onClick={props.decreaseYear}>
        <ChevronLeftOutlined />
      </IconButton>
      <IconButton aria-label="Next Month" onClick={props.increaseYear}>
        <ChevronRightOutlined />
      </IconButton>
    </Stack>
  );
};

function getFormat(type: "month" | "quarter" | "year") {
  switch (type) {
    case "month": {
      return "yyyy, MMM";
    }
    case "quarter": {
      return "'FY'yyyy, QQQ";
    }
    case "year": {
      return "'FY'yyyy";
    }
    default: {
      throw new Error("Function not implemented.");
    }
  }
}

interface Props {
  label: string;
  value: Moment | null;
  onChange: (date: Moment | null) => void;
  minDate?: Moment;
  maxDate?: Moment;
  fiscalMonth?: number;
  size?: "medium" | "small";
  type: "month" | "quarter" | "year";
  disabled?: boolean;
}

export default function DatePicker(props: Props) {
  const {
    type,
    label,
    size,
    minDate: min,
    maxDate: max,
    value,
    fiscalMonth,
  } = props;

  const adjustedMinDate = useMemo(() => {
    if (!min) return null;
    let cloned = min.clone();
    if (fiscalMonth) {
      cloned = cloned.add(13 - fiscalMonth, "month");
    }
    return cloned.startOf(type).toDate();
  }, [min, fiscalMonth, type]);

  const adjustedMaxDate = useMemo(() => {
    if (!max) return null;
    let cloned = max.clone();
    if (fiscalMonth) {
      cloned = cloned.add(13 - fiscalMonth, "month");
    }
    return cloned.startOf(type).toDate();
  }, [max, fiscalMonth, type]);

  const selectedDate = useMemo(() => {
    if (!value) return null;
    let cloned = value.clone();
    if (fiscalMonth) {
      cloned = cloned.add(13 - fiscalMonth, "month");
    }
    return cloned.startOf(type).toDate();
  }, [value, fiscalMonth, type]);

  function handleOnChange(date: Date | null): void {
    if (!date) {
      props.onChange(null);
      return;
    }

    let newDate = moment(date);
    if (fiscalMonth) {
      newDate = newDate.add(fiscalMonth - 13, "month");
    }

    props.onChange(newDate);
  }

  return (
    <ReactDatePicker
      selected={selectedDate}
      onChange={handleOnChange}
      showPopperArrow={false}
      customInput={<TextField label={label} size={size} variant="outlined" />}
      minDate={adjustedMinDate}
      maxDate={adjustedMaxDate}
      popperPlacement="bottom-start"
      dateFormat={getFormat(type)}
      calendarContainer={MyContainer}
      renderCustomHeader={MyHeader}
      showMonthYearPicker={type === "month"}
      showQuarterYearPicker={type === "quarter"}
      showYearPicker={type === "year"}
      disabled={props.disabled}
    />
  );
}
