import {
  Paper,
  Table as MuiTable,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  tableCellClasses,
  SxProps,
  Theme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useEffect, useMemo, useRef, useState } from "react";

import { TableProps } from "./models";

const StyledTableRow = styled(TableRow)(() => ({
  position: "relative", // Ensures zIndex stacking works correctly
}));

const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "colIndex",
})<{ colIndex: number }>(({ colIndex }) => ({
  [`&.${tableCellClasses.head}, &.${tableCellClasses.body}`]: {
    backgroundColor: colIndex % 2 !== 0 ? "#eeeeee" : "inherit",
  },
}));

// Need to sticky the first column to make sure scrollable table works correctly
const StickyTableCell = styled(TableCell)(({ theme }) => ({
  position: "sticky",
  left: 0,
  backgroundColor: theme.palette.background.paper,
  zIndex: 2,
}));

export default function Table<Data>({
  data,
  columns,
  rowKey,
  stickyHeader = false,
  sx,
  headerSx,
  bodySx,
  footerSx,
  subRows,
  alternateRowColors = true,
  alternateColumnColors = false,
}: TableProps<Data>) {
  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>(
    {}
  );
  const hasFooter = useMemo(() => {
    return columns.some((col) => col.footer);
  }, [columns]);

  const tableRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollLeft = tableRef.current.scrollWidth;
    }
  }, [tableRef.current]);

  const CustomizeTableCell = alternateColumnColors
    ? StyledTableCell
    : TableCell;
  const CustomizeTableRow = alternateColumnColors ? StyledTableRow : TableRow;

  return (
    <Paper sx={{ width: "100%", overflow: "auto", boxShadow: "none" }}>
      <TableContainer sx={sx} ref={tableRef}>
        <MuiTable stickyHeader={stickyHeader} size="small">
          <TableHead sx={headerSx}>
            <CustomizeTableRow>
              {columns.map((col, colIndex) => {
                const headerCellSx: SxProps<Theme> = {
                  fontFamily: "Work Sans",
                };
                const sxProps = { ...headerCellSx, ...col.sx, ...col.headerSx };

                return colIndex === 0 ? (
                  <StickyTableCell key={`${col.header}-header`} sx={sxProps}>
                    {col.header}
                  </StickyTableCell>
                ) : (
                  <CustomizeTableCell
                    key={`${colIndex}-${col.header}-header`}
                    sx={sxProps}
                    colIndex={colIndex}
                  >
                    {col.header}
                  </CustomizeTableCell>
                );
              })}
            </CustomizeTableRow>
          </TableHead>
          <TableBody sx={bodySx}>
            {data.map((item, index) => {
              const category = item[rowKey] as string;
              const hasSubRows =
                subRows && subRows[category] && subRows[category].length > 0;

              if (hasSubRows) {
                const expandable =
                  subRows[category] && subRows[category].length > 0;

                return (
                  <>
                    <CustomizeTableRow
                      key={category}
                      onClick={() =>
                        setExpandedRows({
                          ...expandedRows,
                          [category]: !expandedRows[category],
                        })
                      }
                    >
                      {columns.map((col, colIndex) =>
                        colIndex === 0 ? (
                          <StickyTableCell
                            key={`${colIndex}-${col.header}-${item[rowKey]}`}
                            sx={[
                              (theme) => ({
                                backgroundColor:
                                  alternateRowColors && index % 2 === 1
                                    ? theme.palette.grey[300]
                                    : theme.palette.background.paper,
                                display: "flex",
                                justifyContent: "space-between",
                              }),
                              ...(Array.isArray(col.sx) ? col.sx : [col.sx]),
                            ]}
                          >
                            {col.render(item)}
                            {expandable &&
                              (expandedRows[category] ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              ))}
                          </StickyTableCell>
                        ) : (
                          <CustomizeTableCell
                            key={`${colIndex}-${col.header}-${item[rowKey]}`}
                            colIndex={colIndex}
                            sx={[
                              (theme) => ({
                                backgroundColor:
                                  alternateRowColors && index % 2 === 1
                                    ? theme.palette.grey[300]
                                    : theme.palette.background.paper,
                                display: colIndex === 0 ? "flex" : undefined,
                                justifyContent: "space-between",
                              }),
                              ...(Array.isArray(col.sx) ? col.sx : [col.sx]),
                            ]}
                            align="right"
                          >
                            {col.render(item)}
                            {colIndex === 0 &&
                              expandable &&
                              (expandedRows[category] ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              ))}
                          </CustomizeTableCell>
                        )
                      )}
                    </CustomizeTableRow>
                    {expandable &&
                      expandedRows[category] &&
                      subRows[category].map((row) => {
                        return (
                          <CustomizeTableRow key={row[rowKey] as string}>
                            {columns.map((col, subColIndex) =>
                              subColIndex === 0 ? (
                                <StickyTableCell
                                  key={`${subColIndex}-${col.header}-${row[rowKey]}`}
                                  sx={[
                                    (theme) => ({
                                      backgroundColor:
                                        alternateRowColors && index % 2 === 1
                                          ? theme.palette.grey[300]
                                          : theme.palette.background.paper,
                                      paddingLeft:
                                        subColIndex === 0
                                          ? "40px !important"
                                          : undefined,
                                    }),
                                    ...(Array.isArray(col.sx)
                                      ? col.sx
                                      : [col.sx]),
                                  ]}
                                >
                                  {col.render(row)}
                                </StickyTableCell>
                              ) : (
                                <CustomizeTableCell
                                  key={`${subColIndex}-${col.header}-${row[rowKey]}`}
                                  colIndex={subColIndex}
                                  sx={[
                                    (theme) => ({
                                      backgroundColor:
                                        alternateRowColors && index % 2 === 1
                                          ? theme.palette.grey[300]
                                          : theme.palette.background.paper,
                                      paddingLeft:
                                        subColIndex === 0
                                          ? "40px !important"
                                          : undefined,
                                    }),
                                    ...(Array.isArray(col.sx)
                                      ? col.sx
                                      : [col.sx]),
                                  ]}
                                  align="right"
                                >
                                  {col.render(row)}
                                </CustomizeTableCell>
                              )
                            )}
                          </CustomizeTableRow>
                        );
                      })}
                  </>
                );
              }
              return (
                <CustomizeTableRow key={item[rowKey] as string}>
                  {columns.map((col, colIndex) =>
                    colIndex === 0 ? (
                      <StickyTableCell
                        key={`${colIndex}-${col.header}-${item[rowKey]}`}
                        sx={[
                          (theme) => ({
                            backgroundColor:
                              alternateRowColors && index % 2 === 1
                                ? theme.palette.grey[300]
                                : theme.palette.background.paper,
                          }),
                          ...(Array.isArray(col.sx) ? col.sx : [col.sx]),
                        ]}
                      >
                        {col.render(item)}
                      </StickyTableCell>
                    ) : (
                      <CustomizeTableCell
                        key={`${colIndex}-${col.header}-${item[rowKey]}`}
                        colIndex={colIndex}
                        sx={[
                          (theme) => ({
                            backgroundColor:
                              alternateRowColors && index % 2 === 1
                                ? theme.palette.grey[300]
                                : theme.palette.background.paper,
                          }),
                          ...(Array.isArray(col.sx) ? col.sx : [col.sx]),
                        ]}
                        align="right"
                      >
                        {col.render(item)}
                      </CustomizeTableCell>
                    )
                  )}
                </CustomizeTableRow>
              );
            })}
          </TableBody>
          {hasFooter && (
            <TableFooter sx={footerSx}>
              <CustomizeTableRow>
                {columns.map((col, colIndex) =>
                  colIndex === 0 ? (
                    <StickyTableCell
                      variant="head"
                      key={`${colIndex}-${col.footer}-footer`}
                      sx={[
                        (theme) => ({
                          background: theme.palette.background.paper,
                        }),
                        ...(Array.isArray(col.sx) ? col.sx : [col.sx]),
                        ...(Array.isArray(col.footerSx)
                          ? col.footerSx
                          : [col.footerSx]),
                      ]}
                    >
                      {col.footer}
                    </StickyTableCell>
                  ) : (
                    <CustomizeTableCell
                      variant="head"
                      key={`${colIndex}-${col.footer}-footer`}
                      colIndex={colIndex}
                      sx={[
                        (theme) => ({
                          background: theme.palette.background.paper,
                        }),
                        ...(Array.isArray(col.sx) ? col.sx : [col.sx]),
                        ...(Array.isArray(col.footerSx)
                          ? col.footerSx
                          : [col.footerSx]),
                      ]}
                      align="right"
                    >
                      {col.footer}
                    </CustomizeTableCell>
                  )
                )}
              </CustomizeTableRow>
            </TableFooter>
          )}
        </MuiTable>
      </TableContainer>
    </Paper>
  );
}
