import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { ReportMeasurement } from "common/constants";
import { useDispatch, useReportSelector } from "common/store";
import GradientCard from "components/Card/GradientCard";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCountChangeChart } from "services/reportService";
import { setChartShouldFetch } from "slices/reportSlice";
import {
  computeReportData,
  getCustomersByChangeCategoryTableColumns,
  getCustomersByChangeCategoryTableData,
} from "utils/chartUtils/customersByChangeCategory";
import useDidUpdateEffect from "utils/hook/useDidUpdateEffect";
import moment from "moment";
import { generateFilterSheet } from "utils/exportExcelFile/generateFilterSheet";
import { exportToExcel } from "utils/exportExcelFile/exportExcel";
import { filterDataByDate } from "utils/report";

import CountChangesChart from "../charts/CountChange/CountChangesChart";
import CountChangesTable from "../charts/CountChange/CountChangesTable";
import {
  WrapperContainer,
  chartMinHeight,
} from "../components/CommonComponents";

export default function SectionCountChanges() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { reportData, reportSettings } = useReportSelector();
  const { filters: reportFilters, params: reportParams } = reportSettings;
  const chartData = useReportSelector().chartData["count-change"];

  const [viewToggle, setViewToggle] = useState(["chart"]);
  const [cMeasure, setCMeasure] = useState<boolean>(false);

  useEffect(() => {
    if (reportData.isLoading || !chartData.shouldFetch) return;
    dispatch(getCountChangeChart());
  }, [reportData.isLoading, chartData.shouldFetch]);

  useDidUpdateEffect(() => {
    dispatch(setChartShouldFetch(["count-change"]));
  }, [
    cMeasure,
    reportFilters.customers,
    reportFilters.segmentCustomers,
    reportFilters.segmentProducts,
    reportParams.customerLevel,
  ]);

  useEffect(() => {
    const hasCMeasure =
      reportSettings.measurement === ReportMeasurement.CARR ||
      reportSettings.measurement === ReportMeasurement.CMRR;
    setCMeasure(hasCMeasure);
  }, [reportSettings.measurement]);

  const filteredByDate = useMemo(
    () =>
      filterDataByDate(
        chartData.data,
        reportFilters.minDate,
        reportFilters.maxDate
      ),
    [chartData.data, reportFilters.minDate, reportFilters.maxDate]
  );

  // Report data with filters applied, ready to put into chart
  const displayableReportData = useMemo(() => {
    if (!filteredByDate) return [];

    return computeReportData(filteredByDate, reportSettings);
  }, [chartData.isLoading, reportSettings.measurement, filteredByDate]);

  const columns = useMemo(() => {
    return getCustomersByChangeCategoryTableColumns(displayableReportData);
  }, [displayableReportData]);

  const tableData = useMemo(() => {
    return getCustomersByChangeCategoryTableData(
      displayableReportData,
      reportParams.customerLevel
    );
  }, [displayableReportData, reportParams.customerLevel]);

  const handleExport = () => {
    const categories = [
      ...tableData.map((item) => item.category),
      "Ending Count",
    ];

    const headerData = [
      "",
      ...displayableReportData.map((row) =>
        moment(row.xValue, "YYYY-MM", true).isValid()
          ? moment(row.xValue).format("YYYY-MM")
          : row.xValue
      ),
    ];

    const sheet2 = generateFilterSheet(reportSettings, reportData, t);
    const sheet1 = {
      sheetName: "Count Change",
      header: headerData,
      categories,
      data: displayableReportData || [],
      subData: {},
      option: {
        highlight: true,
      },
    };

    exportToExcel(
      [sheet1, sheet2],
      `${moment().format("YYYY-MM-DD")}_${t(
        "Dashboard.Charts.SectionCountChanges"
      )}`
    );
  };

  return (
    <>
      <Typography variant="h6" marginBottom={2} color="var(--text-secondary)">
        {t("Dashboard.Charts.SectionCountChanges")}
      </Typography>
      <GradientCard
        title={t("Dashboard.Charts.CountChangesTitle")}
        onViewToggle={(x) => setViewToggle(x)}
        onMoreClick={() => console.log("more")}
        onExport={handleExport}
        isDisabledActions={
          reportData.isLoading ||
          chartData.isLoading ||
          !!chartData.error ||
          !!reportData.error
        }
      >
        <WrapperContainer
          isLoading={reportData.isLoading || chartData.isLoading}
          error={chartData.error || reportData.error}
          isNoData={displayableReportData.length === 0}
        >
          {viewToggle.includes("chart") && (
            <CountChangesChart
              height={chartMinHeight}
              data={displayableReportData}
            />
          )}
          {viewToggle.includes("data") && (
            <Box sx={{ marginTop: 3, width: "100%" }}>
              <CountChangesTable tableData={tableData} columns={columns} />
            </Box>
          )}
        </WrapperContainer>
      </GradientCard>
    </>
  );
}
