import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import type { GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import { PAGE_SIZE_OPTIONS } from "common/constants";
import i18n from "common/i18n";
import LoadingButton from "components/Button/LoadingButton";
import Modal from "components/Modal";
import MultipleEmailInput from "components/MultipleEmailInput";
import RoleSelect from "components/RoleSelect";
import DataGrid from "components/Table/DataGrid";
import { Collection, FilterCollectionPayload } from "models/collection";
import { Role } from "models/team";
import { WorkspaceModel } from "models/workspace";
import moment from "moment";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  inviteTeamMembersAsync,
  inviteWorkspaceMembersAsync,
} from "services/teamService";
import { searchWorkspaces } from "services/workspaceService";
import useFetch from "utils/hook/useFetch";
import { useToast } from "utils/hook/useNotification";

interface InviteTeamMemberModalProps {
  open: boolean;
  teamName: string;
  onClose: () => void;
}

const getColumns = (
  roles: {
    [wsId: string]: Role;
  },
  setRole: (wsId: string, role: Role) => void,
  disabled: boolean = false
): GridColDef<WorkspaceModel>[] => [
  {
    field: "Name",
    valueGetter: (_, row) => row.name,
    headerName: i18n.t(
      "TeamSettings.TeamUsers.InviteTeamMember.WorkspaceList.Header.Workspace"
    ),
    flex: 2,
    renderCell: (params) => (
      <Stack height="100%" direction="row" alignItems="center">
        <Typography color="primary">{params.value}</Typography>
      </Stack>
    ),
  },
  {
    field: "CreatedAt",
    valueGetter: (_, row) => moment(row.createdAt).format("MM/DD/YY"),
    headerName: i18n.t(
      "TeamSettings.TeamUsers.InviteTeamMember.WorkspaceList.Header.Created"
    ),
    renderCell: (params) => (
      <Stack height="100%" direction="row" alignItems="center">
        <Typography>{params.value}</Typography>
      </Stack>
    ),
    flex: 1,
  },
  {
    field: "Role",
    headerName: i18n.t("Common.Role"),
    valueGetter: (_, row) => row.id,
    renderCell: (params) => (
      <RoleSelect
        value={roles[params.value]}
        onRoleChange={(role) => setRole(params.value, role)}
        disabled={disabled}
      />
    ),
    flex: 1,
  },
];

export default function InviteTeamMemberModal({
  open,
  teamName,
  onClose,
}: InviteTeamMemberModalProps) {
  const { t } = useTranslation();
  const { showSuccess, showError } = useToast();

  const [userEmails, setEmails] = useState<string[]>([]);
  const [highlightEmails, setHighlightEmails] = useState<string[]>([]);
  const [hasInvalidEmails, setHasInvalidEmail] = useState(false);
  const [payload, setPayload] = useState<FilterCollectionPayload>({
    page: 0,
    pageSize: PAGE_SIZE_OPTIONS[0],
    orderBy: "CreatedAt",
    order: "desc",
  });
  const [selection, setSelection] = useState<GridRowSelectionModel>();
  const [roles, setRole] = useState<{ [wsId: string]: Role }>({});
  const [invited, setInvited] = useState<{ [wsId: string]: Role }>({});

  const [option, setOption] = useState("AssignWorkspace");

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    setOption((event.target as HTMLInputElement).value);
  }

  const params = useParams();

  const {
    data,
    isLoading: isGettingWorkspaces,
    execute: getWorkspaces,
  } = useFetch<FilterCollectionPayload, Collection<WorkspaceModel>>({
    fn: (searchPayload) => {
      return searchWorkspaces(searchPayload);
    },
    payload: {
      filters: {
        text: "",
      },
      orderBy: "CreatedAt",
      order: "desc",
      page: 0,
      pageSize: PAGE_SIZE_OPTIONS[0],
    },
  });

  const {
    execute: inviteMembers,
    isLoading: isSendingInvitations,
    data: invitationResponse,
    error: invitationError,
  } = useFetch<
    {
      emails: string[];
      tenantRole?: Role;
      workspaces?: { [wsId: string]: Role };
    },
    unknown
  >({
    fn: ({ emails, tenantRole, workspaces }) => {
      return Object.keys(workspaces ?? {}).length > 0
        ? inviteWorkspaceMembersAsync(
            params.id!,
            emails,
            Object.keys(workspaces!).map((key) => ({
              workspaceId: key,
              workspaceRole: Role[workspaces![key]],
            }))
          )
        : inviteTeamMembersAsync(params.id!, emails, Role[tenantRole!]);
    },
  });

  const handleRequestData = useCallback(
    (searchPayload: FilterCollectionPayload) => {
      getWorkspaces(searchPayload);
      setPayload(searchPayload);
    },
    []
  );
  const handleSetRole = useCallback(
    (wsId: string, role: Role) => {
      setRole({
        ...roles,
        [wsId]: role,
      });
    },
    [roles]
  );

  const shouldDisableInvite = useMemo(() => {
    return (
      isGettingWorkspaces ||
      isSendingInvitations ||
      userEmails.length === 0 ||
      hasInvalidEmails ||
      isSendingInvitations
    );
  }, [
    isGettingWorkspaces,
    isSendingInvitations,
    isSendingInvitations,
    hasInvalidEmails,
    userEmails,
  ]);

  const handleSendInvitationsClick = useCallback(async () => {
    const updatedRoles = { ...roles };
    for (const element of selection ?? []) {
      if (!updatedRoles[element]) {
        updatedRoles[element] = Role.ReportViewer;
      }
    }

    await inviteMembers({
      emails: userEmails,
      tenantRole: option === "AssignAdmin" ? Role.Admin : Role.User,
      workspaces: option === "AssignAdmin" ? {} : updatedRoles,
    });

    if (option === "AssignWorkspace") {
      setInvited(updatedRoles);
    }

    onClose();
  }, [userEmails, selection, roles, option, onClose]);

  const handleInvitationModalClose = useCallback(() => {
    onClose();
    setEmails([]);
    setOption("AssignWorkspace");
    setHasInvalidEmail(false);
    setHighlightEmails([]);
    setSelection(undefined);
  }, []);

  const generateInviteMessage = () => {
    let message = "";

    if (option === "AssignAdmin") {
      let role = "Admin";
      const msgKey =
        userEmails.length === 1
          ? "Dashboards.InviteMemberModal.InviteTeamAdminSuccess"
          : "Dashboards.InviteMemberModal.InviteMultipleTeamAdminSuccess";
      if (userEmails.length === 1) {
        role = `an ${role}`;
      } else if (userEmails.length === 0) {
        return "";
      }
      message = t(msgKey, {
        user: userEmails[0],
        userCount: userEmails.length,
        role,
      });

      return message;
    }

    if (selection?.length === 1) {
      const wsId = selection[0];
      const wsName = data?.data.find((ws) => ws.id === wsId)?.name;
      let role = invited[wsId] === Role.ReportViewer ? "viewer" : "editor";
      const msgKey =
        userEmails.length === 1
          ? "Dashboards.InviteMemberModal.InviteSuccess"
          : "Dashboards.InviteMemberModal.InviteMultipleSuccess";
      if (userEmails.length === 1) {
        role = `${invited[wsId] === Role.ReportViewer ? "a" : "an"} ${role}`;
      }
      message = t(msgKey, {
        user: userEmails[0],
        workspace: wsName,
        userCount: userEmails.length,
        role,
      });
    } else if (selection?.length === 0 || !selection) {
      const msgKey =
        userEmails.length === 1
          ? "TeamSettings.TeamUsers.InviteTeamMember.InviteToTeamSuccess"
          : "TeamSettings.TeamUsers.InviteTeamMember.InviteToTeamMultipleSuccess";
      message = t(msgKey, {
        user: userEmails[0],
        userCount: userEmails.length,
        teamName,
      });
    } else {
      const msgKey =
        userEmails.length === 1
          ? "TeamSettings.TeamUsers.InviteTeamMember.InviteSuccess"
          : "TeamSettings.TeamUsers.InviteTeamMember.InviteSuccessMultiple";
      message = t(msgKey, {
        user: userEmails[0],
        userCount: userEmails.length,
        teamName,
        wsCount: selection?.length,
      });
    }
    return message;
  };

  useEffect(() => {
    if (isSendingInvitations === true) return;
    if (invitationError) {
      showError({
        message: t("TeamSettings.TeamUsers.InviteTeamMember.InviteFailed"),
      });
    } else if (invitationResponse) {
      const message = generateInviteMessage();

      if (message) {
        showSuccess({
          message,
        });
        onClose();
      }
    }
    setEmails([]);
    setHasInvalidEmail(false);
    setSelection(undefined);
    setHighlightEmails([]);
    setRole({});
  }, [isSendingInvitations]);

  return (
    <Modal open={open} onClose={handleInvitationModalClose}>
      <DialogTitle sx={{ padding: 2 }} typography="h5">
        {t("TeamSettings.TeamUsers.InviteTeamMember.ModalTitle", {
          team: teamName,
        })}
      </DialogTitle>
      <DialogContent sx={{ padding: 2 }}>
        <MultipleEmailInput
          values={userEmails}
          onValueChanges={setEmails}
          hasInvalidEmails={hasInvalidEmails}
          setHasInvalidEmail={setHasInvalidEmail}
          setHighlightEmails={setHighlightEmails}
          helperText={t("TeamSettings.TeamUsers.InviteTeamMember.HelperText")}
          sx={{ marginY: 2 }}
          highlightEmails={highlightEmails}
        />
        <FormControl sx={{ width: "100%" }}>
          <RadioGroup
            defaultValue="AssignWorkspace"
            value={option}
            onChange={handleChange}
          >
            <FormControlLabel
              value="AssignWorkspace"
              control={<Radio />}
              label={t(
                "TeamSettings.TeamUsers.InviteTeamMember.WorkspaceAssignHeader"
              )}
            />
            <DataGrid
              columns={getColumns(
                roles,
                handleSetRole,
                !(option === "AssignWorkspace")
              )}
              data={data}
              isLoading={isGettingWorkspaces}
              payload={payload}
              hasSearchBox={false}
              hasFilterButton={false}
              checkboxSelection
              isRowSelectable={option === "AssignWorkspace"}
              rowHeight={54}
              onRequestData={handleRequestData}
              rowSelectionModel={selection}
              onRowSelectionModelChange={setSelection}
            />
            <FormControlLabel
              value="AssignAdmin"
              control={<Radio />}
              label={t(
                "TeamSettings.TeamUsers.InviteTeamMember.AssignAsTeamAdmin"
              )}
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          paddingX: 2,
          paddingY: 1,
        }}
      >
        <LoadingButton
          data-testid="InviteTeamMemberModal__inviteBtn"
          variant="contained"
          disabled={shouldDisableInvite}
          onClick={handleSendInvitationsClick}
          loading={isSendingInvitations}
        >
          {t("Invite")}
        </LoadingButton>
        <Button
          data-testid="InviteTeamMemberModal__cancelBtn"
          variant="text"
          onClick={handleInvitationModalClose}
          color="secondary"
          disabled={isSendingInvitations}
        >
          {t("Cancel")}
        </Button>
      </DialogActions>
    </Modal>
  );
}
