import extractIdFromPathname from "utils/extractIdFromPathname";
import { ROUTE_PATHS } from "common/constants";
import { teamServiceClient } from "common/client/teamManagementClient";
import { CircularProgress } from "@mui/material";
import { useReportSelector } from "common/store";

import GlobalTimeRange from "./GlobalTimeRange";

export default function Filters() {
  const {
    reportData: { globalSettings, isLoading },
  } = useReportSelector();
  const { minDate, maxDate, defaultMinDate, defaultMaxDate } =
    globalSettings || {};

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <GlobalTimeRange
      currentMinDate={minDate}
      currentMaxDate={maxDate}
      defaultMinDate={defaultMinDate}
      defaultMaxDate={defaultMaxDate}
    />
  );
}

export async function actions({ request }: { request: Request }) {
  const url = new URL(request.url);
  const teamId = extractIdFromPathname(ROUTE_PATHS.TEAMS, url.pathname);
  const workspaceId = extractIdFromPathname(
    ROUTE_PATHS.WORKSPACES,
    url.pathname
  );
  const formData = await request.formData();
  switch (request.method) {
    case "PUT": {
      try {
        await teamServiceClient.put(
          `teams/${teamId}/workspaces/${workspaceId}/settings`,
          {
            category: "GlobalFilters",
            name: formData.get("name") as string,
            value: formData.get("value") as string,
          }
        );
        return null;
      } catch (error) {
        return error;
      }
    }
  }
}
