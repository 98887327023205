import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "common/store";
import { ROUTE_PATHS } from "common/constants";

export default function Home() {
  const navigate = useNavigate();
  const { isSystemAdmin, isLoading } = useSelector((state) => state.team);

  useEffect(() => {
    if (isLoading) return;
    if (isSystemAdmin) {
      // Redirect to team list if user is a super admin
      navigate(`/${ROUTE_PATHS.TEAMS}`);
    } else {
      const lastTeamId = localStorage.getItem("lastTeamId");
      if (lastTeamId) {
        // Redirect to the current team's workspace list if user is not a super admin
        navigate(
          `/${ROUTE_PATHS.TEAMS}/${lastTeamId}/${ROUTE_PATHS.WORKSPACES}`
        );
      } else {
        navigate("/login");
      }
    }
  }, [isLoading, isSystemAdmin]);

  return <></>;
}
