import { useReportSelector } from "common/store";
import LineBarComposedChart from "components/Charts/LineBarComposedChart";
import VerticalTick from "components/Charts/VerticalAxisTick";
import { RevenueByChangeCategoryChartModel } from "models/report";
import { formatCurrencyValue, getPeriodAxisLabel } from "utils/format";
import { SingleValueTooltip } from "components/ChartTooltip";
import { getChartBars } from "utils/chartUtils/revenueByChangeCategoryReport";

import CustomYAxisTick from "../CustomYAxisTick";

interface Props {
  height: string | number;
  data: RevenueByChangeCategoryChartModel[];
  selectedMonth?: string;
  selectedCategory?: string;
  onSelectMonth: (value?: string) => void;
  onSelectCategory: (value?: string) => void;
  onDrilldownExit: () => void;
}

export default function RollForwardChart({
  data,
  height,
  selectedMonth,
  selectedCategory,
  onSelectMonth,
  onSelectCategory,
}: Props) {
  const { reportSettings } = useReportSelector();
  return (
    <LineBarComposedChart
      xAxisKey="xValue"
      bars={getChartBars(reportSettings.params.customerLevel)}
      line={{ key: "Ending Balance" }}
      data={data}
      height={height}
      width="100%"
      xAxisProps={{
        interval: 0,
        tick: ({ payload, ...remains }) => (
          <VerticalTick
            value={getPeriodAxisLabel(
              payload.value,
              data.map((x) => x.xValue)
            )}
            {...remains}
          />
        ),
      }}
      yAxisProps={{
        tick: ({ payload, ...remains }) => (
          <CustomYAxisTick
            value={formatCurrencyValue(payload.value)}
            isRoot={payload.value === 0}
            textAnchor="start"
            {...remains}
          />
        ),
      }}
      margin={{ top: 0, left: 24, right: 24, bottom: 28 }}
      renderTooltip={(payload) =>
        SingleValueTooltip("xValue", payload, (value) =>
          formatCurrencyValue((value as number) || 0, true)
        )
      }
      selectedXValue={selectedMonth}
      onSelectXValue={onSelectMonth}
      selectedCategory={selectedCategory}
      onSelectCategory={onSelectCategory}
    />
  );
}
