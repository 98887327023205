import {
  AnalysisType,
  CustomerLevel,
  renewalWindows,
  ReportMeasurement,
  ReportView,
  RevenueType,
} from "common/constants";
import { TFunction } from "i18next";
import moment from "moment";
import {
  ChartSettings,
  ReportData,
  ReportSettings,
} from "slices/models/reportSliceModel";

import { CATEGORIES_SHEET1 } from "./constants";
import { SheetSettingProps } from "./sheetModel";

const HEADER_SHEET1 = ["Filter Summary"];

const GLOBAL_VIEW_MAP: Record<AnalysisType, string> = {
  [AnalysisType.YoY]: "DashboardSettings.Parameters.YOY",
  [AnalysisType.QoQ]: "DashboardSettings.Parameters.QOQ",
  [AnalysisType.MoM]: "DashboardSettings.Parameters.MOM",
};

const GLOBAL_TIME_FRAME_MAP: Record<RevenueType, string> = {
  [RevenueType.Monthly]: "DashboardSettings.Parameters.Monthly",
  [RevenueType.Quarterly]: "DashboardSettings.Parameters.Quarterly",
  [RevenueType.Yearly]: "DashboardSettings.Parameters.Yearly",
};

const VIEW_BY_MAP: Record<ReportMeasurement, string> = {
  [ReportMeasurement.ARR]: "Common.ARR",
  [ReportMeasurement.CARR]: "Common.CARR",
  [ReportMeasurement.CMRR]: "Common.CMRR",
  [ReportMeasurement.CQRR]: "Common.CQRR",
  [ReportMeasurement.MRR]: "Common.MRR",
  [ReportMeasurement.QRR]: "Common.QRR",
};

export type OverviewChartSettings = Partial<ChartSettings["overview"]>;
export type TopCustomerChartSettings = Partial<ChartSettings["top-customers"]>;
export type ContractRenewalChartSettings = Partial<
  ChartSettings["contract-renewal"]
>;
export type CustomerCohortChartSettings = Partial<
  ChartSettings["customer-cohorts"]
>;

const keyMapping: Record<string, string> = {
  topCustomers: "Max Customer",
  segView: "View By",
  segKey: "Segment Key",
  month: "Month",
  period: "Tenure Period",
  axis: "Axis",
  show: "Show",
  byValue: "By",
  relativeTo: "Relative To",
  productType: "Product Type",
  priorPeriodComparison: "Prior Period Comparison",
  renewalWindow: "Review Window",
};

export function generateFilterSheet(
  reportSettings: ReportSettings,
  reportData: ReportData,
  t: TFunction<"translation", undefined>,
  chartSettings?:
    | OverviewChartSettings
    | TopCustomerChartSettings
    | ContractRenewalChartSettings
    | CustomerCohortChartSettings
): SheetSettingProps {
  const getTranslatedValue = <T extends string | number | symbol>(
    key: T,
    mapping: Record<T, string>
  ): string => (mapping[key] ? t(mapping[key]) : "");

  const getFilteredValues = (
    filterData: Record<string, { value: string }[]> | {},
    fallbackKey: string
  ): string => {
    if (!filterData || Object.keys(filterData).length === 0) {
      return t(fallbackKey);
    }

    return Object.entries(filterData)
      .map(([key, values]) => {
        if (typeof values === "string") {
          return `${key}: ${values}`;
        }

        if (Array.isArray(values)) {
          return `${key}: ${values.map((item) => item.value).join(", ")}`;
        }

        return `${key}: ${JSON.stringify(values)}`;
      })
      .join("\n"); // Use newline for line break in Excel
  };

  const getCustomerFilter = (
    filterData: Record<string, { value: string }[]> | {},
    fallbackKey: string
  ): string => {
    if (!filterData || Object.keys(filterData).length === 0) {
      return t(fallbackKey);
    }

    if (Array.isArray(filterData)) {
      return filterData.map((item) => item.value).join(", ");
    }

    return Object.values(filterData)
      .flat()
      .map((item) => item.value)
      .join(", ");
  };

  const dataSheet1: unknown[] = [
    {
      "Global View": getTranslatedValue(
        reportSettings.params.analysisType as AnalysisType,
        GLOBAL_VIEW_MAP
      ),
      "Global Time Frame": getTranslatedValue(
        reportSettings.params.revenueType as RevenueType,
        GLOBAL_TIME_FRAME_MAP
      ),
      "View By": getTranslatedValue(
        reportSettings.measurement as ReportMeasurement,
        VIEW_BY_MAP
      ),
      "Customer Level":
        reportSettings.params.customerLevel === CustomerLevel.Customer
          ? t("Common.Customer")
          : t("Common.CustomerProduct"),
      "Customer ID": getCustomerFilter(
        reportSettings.filters.customers.length === reportData.customers?.length
          ? {}
          : (reportSettings.filters.customers as unknown as Record<
              string,
              { value: string }[]
            >),
        "Common.All"
      ),
      "Customer Segments": getFilteredValues(
        reportSettings.filters.segmentCustomers as Record<
          string,
          { value: string }[]
        >,
        "Common.All"
      ),
      "Product Info": getFilteredValues(
        reportSettings.filters.segmentProducts as Record<
          string,
          { value: string }[]
        >,
        "Common.All"
      ),
      "Start Date":
        moment(reportSettings.filters.minDate).format("MM/DD/YYYY") || "-",
      "End Date":
        moment(reportSettings.filters.maxDate).format("MM/DD/YYYY") || "-",
    },
  ];

  if (chartSettings && "segView" in chartSettings) {
    if (typeof chartSettings.segView === "number") {
      const segView = chartSettings.segView;
      if (
        segView === ReportView.CustomerType ||
        segView === ReportView.TopCustomerType
      ) {
        keyMapping.segKey = t("Common.CustSeg");
      } else if (
        segView === ReportView.ProductType ||
        segView === ReportView.TopProductType
      ) {
        keyMapping.segKey = t("Common.ProdSeg");
      } else {
        // Remove segKey from the keyMapping
        delete keyMapping.segKey;
      }
    } else {
      // If segView exists but isn't a number, remove segKey.
      delete keyMapping.segKey;
    }
  }

  const chartFilterItems = Object.entries(chartSettings || {}).map(
    ([key, value]) => {
      let displayValue = value;
      if (key === "segView" && typeof value === "number") {
        // Chart 4: 0 ReportView.CustomerType, 1 ReportView.ProductType, 12 ReportView.Total
        // Top Customer: 2 ReportView.TopCustomerType, 3 ReportView.TopProductType
        switch (value) {
          case 0:
          case 2: {
            displayValue = t("Common.CustSeg");
            break;
          }
          case 1:
          case 3: {
            displayValue = t("Common.ProdSeg");
            break;
          }
          case 12: {
            displayValue = t("Common.Total");
            break;
          }
          case 999: {
            displayValue = t("Common.Other");
            break;
          }
          default: {
            break;
          }
        }
      }

      if (key === "month") {
        displayValue = moment(value).format("MM/DD/YYYY");
      }

      if (key === "period" && typeof value === "number") {
        switch (value) {
          case 0: {
            displayValue = t("Common.Month");
            break;
          }
          case 1: {
            displayValue = t("Common.Quarter");
            break;
          }
          case 2: {
            displayValue = t("Common.Year");
            break;
          }
          default: {
            break;
          }
        }
      }

      if (key === "priorPeriodComparison" && typeof value === "number") {
        switch (value) {
          case 0: {
            displayValue = t(
              "Dashboard.CohortsCard.PriorPeriodComparisonSelect.MoM"
            );
            break;
          }
          case 1: {
            displayValue = t(
              "Dashboard.CohortsCard.PriorPeriodComparisonSelect.QoQ"
            );
            break;
          }
          case 2: {
            displayValue = t(
              "Dashboard.CohortsCard.PriorPeriodComparisonSelect.YoY"
            );
            break;
          }
          default: {
            break;
          }
        }
      }

      if (key === "renewalWindow") {
        // Use the renewalWindows array to find the matching display value.
        const windowOption = renewalWindows.find((item) => item.key === value);
        if (windowOption) {
          displayValue = windowOption.value;
        }
      }

      return { [keyMapping[key] || key]: displayValue };
    }
  );

  return {
    sheetName: "Filter",
    header: HEADER_SHEET1,
    categories: CATEGORIES_SHEET1,
    data: dataSheet1,
    subData: {},
    option: {
      chartFilter: chartFilterItems,
    },
  };
}
