import React from "react";
import { ArrowForward, DeleteOutline, InfoOutlined } from "@mui/icons-material";
import {
  CardHeader,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Mapping } from "containers/Workspace/DataMapping/DataMappingContext";

import { RowType } from "./types";
import MappingSelect from "./MappingSelect";

export default function MappingTable({
  rows,
  sourceFields,
  mappingValues,
  onChange,
  onDelete,
  selectComponent = MappingSelect,
}: {
  rows: RowType[];
  sourceFields: string[];
  mappingValues: Mapping;
  onChange: (attr: string, val: string) => void;
  onDelete?: (index: number) => void;
  selectComponent?: React.ComponentType<{
    row: RowType;
    sourceFields: string[];
    mappingValues: Mapping;
    onChange: (attribute: string, value: string) => void;
  }>;
}) {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              {t("workspace.dataMapping.mappingTableFields.attribute")}
            </TableCell>
            <TableCell>
              {t("workspace.dataMapping.mappingTableFields.sourceDataField")}
            </TableCell>
            <TableCell>
              {t("workspace.dataMapping.mappingTableFields.example")}
            </TableCell>
            <TableCell>
              {t("workspace.dataMapping.mappingTableFields.dataType")}
            </TableCell>
            {onDelete && rows.length > 1 && <TableCell></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <>
              {(row.isRequiredIndex || row.isOptionalIndex) && (
                <TableRow
                  key={row.isRequiredIndex ? "Required" : "Optional"}
                  sx={{
                    backgroundColor: row.isRequiredIndex
                      ? "var(--color-row-required)"
                      : "unset",
                    width: "100%",
                  }}
                >
                  <TableCell
                    sx={{
                      padding: 0,
                      border: "none",
                    }}
                  >
                    {(row.isRequiredIndex || row.isOptionalIndex) && (
                      <CardHeader
                        sx={{
                          margin: 0,
                          padding: 0,
                          paddingLeft: "16px",
                          height: "24px",
                        }}
                        title={
                          <Typography
                            variant="overline"
                            color={
                              row.isRequiredIndex
                                ? "primary.dark"
                                : "text.primary"
                            }
                            fontWeight={700}
                            textTransform="uppercase"
                          >
                            {row.isRequiredIndex
                              ? t("workspace.dataMapping.required")
                              : t("workspace.dataMapping.optional")}
                          </Typography>
                        }
                      />
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                    }}
                  />
                  <TableCell
                    sx={{
                      border: "none",
                    }}
                  />
                  <TableCell
                    sx={{
                      border: "none",
                    }}
                  />
                </TableRow>
              )}
              <TableRow
                key={row.attribute}
                sx={{
                  backgroundColor: row.isRequired
                    ? "var(--color-row-required)"
                    : "unset",
                }}
              >
                <TableCell>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2" color="text.secondary">
                      {t(row.attributeLabel || "attribute") || row.attribute}
                      &nbsp;
                      {row.tooltip && (
                        <Tooltip title={t(row.tooltip)} placement="top">
                          <InfoOutlined color="primary" />
                        </Tooltip>
                      )}
                    </Typography>
                    <ArrowForward
                      sx={{
                        color: "action.active",
                        width: "24px",
                        height: "24px",
                      }}
                    />
                  </Stack>
                </TableCell>
                <TableCell>
                  <Stack
                    sx={{ minWidth: 250 }}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={2}
                  >
                    {React.createElement(selectComponent, {
                      row,
                      mappingValues,
                      sourceFields,
                      onChange,
                    })}
                    <ArrowForward
                      sx={{
                        color: "action.active",
                        width: "24px",
                        height: "24px",
                        visibility:
                          row.attribute === "FiscalStartMonth"
                            ? "hidden"
                            : "visible",
                      }}
                    />
                  </Stack>
                </TableCell>
                <TableCell sx={{ maxWidth: "140px", padding: "16px" }}>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      visibility:
                        row.attribute === "FiscalStartMonth"
                          ? "hidden"
                          : "visible",
                    }}
                  >
                    {row.example}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        visibility:
                          row.attribute === "FiscalStartMonth"
                            ? "hidden"
                            : "visible",
                      }}
                    >
                      {t(row.dataType)}
                    </Typography>
                  </Stack>
                </TableCell>
                {onDelete && rows.length > 1 && (
                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      onClick={() => onDelete(index)}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
