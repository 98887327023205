import { ByValue, CohortReport, RelativeTo } from "models/report";
import type { Dictionary } from "lodash";
import {
  CustomerLevel,
  ReportMeasurement,
  RevenueType,
} from "common/constants";
import { formatPeriodText, formatPercentageValue } from "utils/format";
import { getLastItemOfEachGroup } from "utils/report";
import lodashOrderBy from "lodash/orderBy";
import lodashGroupBy from "lodash/groupBy";

import { revenueLabelMapping, measurementMapping } from "./constants";

const metricsDivisors = {
  [ReportMeasurement.ARR]: 1,
  [ReportMeasurement.MRR]: 12,
  [ReportMeasurement.QRR]: 4,
  [ReportMeasurement.CARR]: 1,
  [ReportMeasurement.CMRR]: 12,
  [ReportMeasurement.CQRR]: 4,
};

export function getTenureTotal(
  groupedData: { cohort: string; data: CohortReport[] }[],
  columnKey: string,
  columnValue: string | number,
  valueKey: string
) {
  let total = 0;

  for (const group of Object.values(groupedData)) {
    const filteredValues = getLastItemOfEachGroup(
      lodashOrderBy(group.data, (item) => item.Month),
      columnKey as keyof CohortReport
    );
    const period = filteredValues.find(
      (p) => p[columnKey as keyof CohortReport] === columnValue
    );
    total += period
      ? (period[valueKey as keyof CohortReport] as number) || 0
      : 0;
  }
  return total;
}

function hsbToHsl(h: number, s: number, b: number): string {
  const l = (b / 100) * (100 - s / 2);
  const newS = l === 0 || l === 1 ? 0 : ((b - l) / Math.min(l, 100 - l)) * 100;

  return `hsl(${h}, ${newS}%, ${l}%)`;
}

export function getBgColor(
  value?: number | null,
  prevValue?: number | null
): string {
  if (
    value === undefined ||
    value === null ||
    prevValue === undefined ||
    prevValue === null
  ) {
    return hsbToHsl(151, 0, 100);
  }

  if (prevValue === undefined && prevValue === value) {
    return hsbToHsl(151, 0, 100);
  }
  let percentage = Math.abs(((value - prevValue) * 100) / prevValue);

  if (percentage > 200) {
    percentage = 200;
  }

  if (value > prevValue) {
    if (percentage <= 100) {
      return hsbToHsl(151, percentage, 100);
    }
    return hsbToHsl(151, 100, 100 - (percentage - 100) / 2);
  }
  if (value < prevValue) {
    if (percentage <= 100) {
      return hsbToHsl(14, percentage, 100);
    }
    return hsbToHsl(14, 100, 100 - (percentage - 100) / 2);
  }
  return hsbToHsl(151, 0, 100);
}

export function getTextColor(
  value?: number | null,
  prevValue?: number | null
): string {
  if (
    value === undefined ||
    value === null ||
    prevValue === undefined ||
    prevValue === null
  ) {
    return "var(--black)";
  }
  const percentage = Math.abs(((value - prevValue) * 100) / prevValue);

  if (value > prevValue && percentage > 160) {
    return "var(--white)";
  }
  if (value < prevValue && percentage > 130) {
    return "var(--white)";
  }
  return "var(--black)";
}

export function getColumns(
  groupedData: Dictionary<CohortReport[]>,
  key: string
) {
  const columnSet = new Set(
    Object.values(groupedData).flatMap((g) => {
      return g.flatMap((p) => p[key as keyof CohortReport]);
    })
  ) as Set<number | string>;

  return [...columnSet]
    .filter((col) => col !== undefined)
    .sort((a, b) => {
      if (typeof a === "string") {
        if (key === "DISPLAYQTR") {
          const [a1, a2] = a.split("-"); // e.g. Q1-24
          const [b1, b2] = (a as string).split("-");

          return `${a2}-${a1}`.localeCompare(`${b2}-${b1}`);
        }
        return (a as string).localeCompare(b as string);
      }
      return a - (b as number);
    });
}

export function getTenureKey(
  measurement: ReportMeasurement,
  revenueType: RevenueType
): string {
  const measureString =
    measurement === ReportMeasurement.ARR ||
    measurement === ReportMeasurement.MRR ||
    measurement === ReportMeasurement.QRR
      ? measurementMapping[ReportMeasurement.ARR]
      : measurementMapping[ReportMeasurement.CARR];

  return `${measureString}TENURE${revenueLabelMapping[revenueType]}`;
}

export function getTenureHeader(
  revenueType: RevenueType,
  value: number | string | undefined
): string {
  if (value === undefined) return "";

  return typeof value === "string"
    ? formatPeriodText(value)
    : `${RevenueType[revenueType][0]}${value}`;
}

function getInitialRetentionValue(
  cohort: Omit<CohortReport, "Month" | "DISPLAYQTR" | "FISCALYR">,
  by: ByValue
) {
  const {
    SUM_BEGINNING_BALANCE_INITIAL,
    SUM_DOWNSELL_INITIAL,
    SUM_UPSELL_INITIAL,
    SUM_LOST_CUSTOMER_INITIAL,
  } = cohort;

  if (
    ![
      SUM_BEGINNING_BALANCE_INITIAL,
      SUM_DOWNSELL_INITIAL,
      SUM_UPSELL_INITIAL,
      SUM_LOST_CUSTOMER_INITIAL,
    ].some((value) => value !== null && value !== undefined)
  ) {
    return null;
  }

  let dividend = 0;

  if (by === "gross") {
    dividend =
      (SUM_BEGINNING_BALANCE_INITIAL || 0) +
      (SUM_DOWNSELL_INITIAL || 0) +
      (SUM_LOST_CUSTOMER_INITIAL || 0);
  } else if (by === "net") {
    dividend =
      (SUM_BEGINNING_BALANCE_INITIAL || 0) +
      (SUM_UPSELL_INITIAL || 0) +
      (SUM_DOWNSELL_INITIAL || 0) +
      (SUM_LOST_CUSTOMER_INITIAL || 0);
  } else {
    dividend =
      (SUM_BEGINNING_BALANCE_INITIAL || 0) + (SUM_LOST_CUSTOMER_INITIAL || 0);
  }
  if (!SUM_BEGINNING_BALANCE_INITIAL) {
    if (dividend === 0) {
      return "Common.NotAvailable";
    }
    return "Common.InfiniteValue";
  }
  return dividend / SUM_BEGINNING_BALANCE_INITIAL;
}
function getPriorRetentionValue(
  cohort: Omit<CohortReport, "Month" | "DISPLAYQTR" | "FISCALYR">,
  by: ByValue
) {
  const {
    SUM_BEGINNING_BALANCE_PRIOR,
    SUM_DOWNSELL_PRIOR,
    SUM_UPSELL_PRIOR,
    SUM_LOST_CUSTOMER_PRIOR,
  } = cohort;

  if (
    ![
      SUM_BEGINNING_BALANCE_PRIOR,
      SUM_DOWNSELL_PRIOR,
      SUM_UPSELL_PRIOR,
      SUM_LOST_CUSTOMER_PRIOR,
    ].some((value) => value !== null && value !== undefined)
  ) {
    return null;
  }

  let dividend = 0;

  if (by === "gross") {
    dividend =
      (SUM_BEGINNING_BALANCE_PRIOR || 0) +
      (SUM_DOWNSELL_PRIOR || 0) +
      (SUM_LOST_CUSTOMER_PRIOR || 0);
  } else if (by === "net") {
    dividend =
      (SUM_BEGINNING_BALANCE_PRIOR || 0) +
      (SUM_UPSELL_PRIOR || 0) +
      (SUM_DOWNSELL_PRIOR || 0) +
      (SUM_LOST_CUSTOMER_PRIOR || 0);
  } else {
    dividend =
      (SUM_BEGINNING_BALANCE_PRIOR || 0) + (SUM_LOST_CUSTOMER_PRIOR || 0);
  }
  if (!SUM_BEGINNING_BALANCE_PRIOR) {
    if (dividend === 0) {
      return "Common.NotAvailable";
    }
    return "Common.InfiniteValue";
  }
  return dividend / SUM_BEGINNING_BALANCE_PRIOR;
}

function getCustomerRetentionValue(
  cohort: Omit<CohortReport, "Month" | "DISPLAYQTR" | "FISCALYR">,
  relativeTo: RelativeTo
) {
  const {
    INITIAL_CUSTOMER,
    INITIAL_LOST_CUSTOMER,
    PRIOR_CUSTOMER,
    PRIOR_LOST_CUSTOMER,
  } = cohort;

  if (relativeTo === "initial") {
    if (
      ![INITIAL_CUSTOMER, INITIAL_LOST_CUSTOMER].some(
        (value) => value !== null && value !== undefined
      )
    ) {
      return null;
    }
    const dividend = (INITIAL_CUSTOMER || 0) + (INITIAL_LOST_CUSTOMER || 0);

    if (!INITIAL_CUSTOMER) {
      if (!dividend) {
        return "Common.NotAvailable";
      }
      return "Common.InfiniteValue";
    }
    return dividend / INITIAL_CUSTOMER;
  }
  if (
    ![PRIOR_CUSTOMER, PRIOR_LOST_CUSTOMER].some(
      (value) => value !== null && value !== undefined
    )
  ) {
    return null;
  }
  const dividend = (PRIOR_CUSTOMER || 0) + (PRIOR_LOST_CUSTOMER || 0);

  if (!PRIOR_CUSTOMER) {
    if (!dividend) {
      return "Common.NotAvailable";
    }
    return "Common.InfiniteValue";
  }
  return dividend / PRIOR_CUSTOMER;
}

export function getRetentionValue(
  by: ByValue,
  relativeTo: RelativeTo,
  customerLevel: CustomerLevel,
  data: Omit<CohortReport, "Month" | "DISPLAYQTR" | "FISCALYR">
): number | string | null {
  let percentage: number | string | null = null;

  // show = "customer" & by = "customer retention"
  if (by === "customer") {
    percentage = getCustomerRetentionValue(data, relativeTo);
    // show = "revenue"
  } else {
    if (relativeTo === "initial") {
      percentage = getInitialRetentionValue(data, by);
    } else if (relativeTo === "prior") {
      percentage = getPriorRetentionValue(data, by);
    }
  }
  if (typeof percentage === "number") {
    percentage = percentage * 100;
  }
  return percentage;
}

function accumulateValue(
  current: number | null,
  value?: number | null
): number | null {
  if (typeof value === "number") {
    return (current || 0) + value;
  }
  return current;
}

// Sum retention-related values from grouped cohort, then return summed values as a CohortReport object
function getSummedRowsRetention(
  headerKey: string,
  headerValue: string | number,
  cohorts: { cohort: string; data: CohortReport[] }[]
) {
  // Revenue retention
  let SUM_BEGINNING_BALANCE_INITIAL: number | null = null;
  let SUM_DOWNSELL_INITIAL: number | null = null;
  let SUM_UPSELL_INITIAL: number | null = null;
  let SUM_LOST_CUSTOMER_INITIAL: number | null = null;
  let SUM_BEGINNING_BALANCE_PRIOR: number | null = null;
  let SUM_DOWNSELL_PRIOR: number | null = null;
  let SUM_UPSELL_PRIOR: number | null = null;
  let SUM_LOST_CUSTOMER_PRIOR: number | null = null;
  // Customer retention
  let INITIAL_CUSTOMER: number | null = null;
  let INITIAL_LOST_CUSTOMER: number | null = null;
  let PRIOR_CUSTOMER: number | null = null;
  let PRIOR_LOST_CUSTOMER: number | null = null;

  for (const p of cohorts) {
    const cohort = p.data.find(
      (i) => i[headerKey as keyof CohortReport] === headerValue
    );
    if (cohort) {
      SUM_BEGINNING_BALANCE_INITIAL = accumulateValue(
        SUM_BEGINNING_BALANCE_INITIAL,
        cohort.SUM_BEGINNING_BALANCE_INITIAL
      );
      SUM_DOWNSELL_INITIAL = accumulateValue(
        SUM_DOWNSELL_INITIAL,
        cohort.SUM_DOWNSELL_INITIAL
      );
      SUM_UPSELL_INITIAL = accumulateValue(
        SUM_UPSELL_INITIAL,
        cohort.SUM_UPSELL_INITIAL
      );
      SUM_LOST_CUSTOMER_INITIAL = accumulateValue(
        SUM_LOST_CUSTOMER_INITIAL,
        cohort.SUM_LOST_CUSTOMER_INITIAL
      );
      SUM_BEGINNING_BALANCE_PRIOR = accumulateValue(
        SUM_BEGINNING_BALANCE_PRIOR,
        cohort.SUM_BEGINNING_BALANCE_PRIOR
      );
      SUM_DOWNSELL_PRIOR = accumulateValue(
        SUM_DOWNSELL_PRIOR,
        cohort.SUM_DOWNSELL_PRIOR
      );
      SUM_UPSELL_PRIOR = accumulateValue(
        SUM_UPSELL_PRIOR,
        cohort.SUM_UPSELL_PRIOR
      );
      SUM_LOST_CUSTOMER_PRIOR = accumulateValue(
        SUM_LOST_CUSTOMER_PRIOR,
        cohort.SUM_LOST_CUSTOMER_PRIOR
      );
      INITIAL_CUSTOMER = accumulateValue(
        INITIAL_CUSTOMER,
        cohort.INITIAL_CUSTOMER
      );
      INITIAL_LOST_CUSTOMER = accumulateValue(
        INITIAL_LOST_CUSTOMER,
        cohort.INITIAL_LOST_CUSTOMER
      );
      PRIOR_CUSTOMER = accumulateValue(PRIOR_CUSTOMER, cohort.PRIOR_CUSTOMER);
      PRIOR_LOST_CUSTOMER = accumulateValue(
        PRIOR_LOST_CUSTOMER,
        cohort.PRIOR_LOST_CUSTOMER
      );
    }
  }
  return {
    SUM_BEGINNING_BALANCE_INITIAL,
    SUM_DOWNSELL_INITIAL,
    SUM_UPSELL_INITIAL,
    SUM_LOST_CUSTOMER_INITIAL,
    SUM_BEGINNING_BALANCE_PRIOR,
    SUM_DOWNSELL_PRIOR,
    SUM_UPSELL_PRIOR,
    SUM_LOST_CUSTOMER_PRIOR,
    INITIAL_CUSTOMER,
    INITIAL_LOST_CUSTOMER,
    PRIOR_CUSTOMER,
    PRIOR_LOST_CUSTOMER,
  } as Omit<CohortReport, "Month" | "DISPLAYQTR" | "FISCALYR">;
}

export function getRetentionTotal(
  rows: { cohort: string; data: CohortReport[] }[],
  headerKey: string,
  headerValue: string | number,
  by: ByValue,
  relativeTo: RelativeTo,
  customerLevel: CustomerLevel
) {
  const value = getRetentionValue(
    by,
    relativeTo,
    customerLevel,
    getSummedRowsRetention(headerKey, headerValue, rows)
  );
  if (typeof value === "number") {
    return formatPercentageValue(value);
  }
  return value || "Common.NotAvailable";
}

function getSummedCohort(
  cohorts: CohortReport[],
  measurement: ReportMeasurement,
  by: ByValue,
  relativeTo: RelativeTo,
  customerLevel: CustomerLevel
): CohortReport {
  let SUM_ENDING_BALANCE: number | null = null;
  let CUSTOMER_COUNT: number | null = null;
  // Revenue retention
  let SUM_BEGINNING_BALANCE_INITIAL: number | null = null;
  let SUM_DOWNSELL_INITIAL: number | null = null;
  let SUM_UPSELL_INITIAL: number | null = null;
  let SUM_LOST_CUSTOMER_INITIAL: number | null = null;
  let SUM_BEGINNING_BALANCE_PRIOR: number | null = null;
  let SUM_DOWNSELL_PRIOR: number | null = null;
  let SUM_UPSELL_PRIOR: number | null = null;
  let SUM_LOST_CUSTOMER_PRIOR: number | null = null;
  // Customer retention
  let INITIAL_CUSTOMER: number | null = null;
  let INITIAL_LOST_CUSTOMER: number | null = null;
  let PRIOR_CUSTOMER: number | null = null;
  let PRIOR_LOST_CUSTOMER: number | null = null;

  for (const c of cohorts) {
    SUM_ENDING_BALANCE = accumulateValue(
      SUM_ENDING_BALANCE,
      c.SUM_ENDING_BALANCE
    );
    CUSTOMER_COUNT = accumulateValue(CUSTOMER_COUNT, c.CUSTOMER_COUNT);
    SUM_BEGINNING_BALANCE_INITIAL = accumulateValue(
      SUM_BEGINNING_BALANCE_INITIAL,
      c.SUM_BEGINNING_BALANCE_INITIAL
    );
    SUM_DOWNSELL_INITIAL = accumulateValue(
      SUM_DOWNSELL_INITIAL,
      c.SUM_DOWNSELL_INITIAL
    );
    SUM_UPSELL_INITIAL = accumulateValue(
      SUM_UPSELL_INITIAL,
      c.SUM_UPSELL_INITIAL
    );
    SUM_LOST_CUSTOMER_INITIAL = accumulateValue(
      SUM_LOST_CUSTOMER_INITIAL,
      c.SUM_LOST_CUSTOMER_INITIAL
    );
    SUM_BEGINNING_BALANCE_PRIOR = accumulateValue(
      SUM_BEGINNING_BALANCE_PRIOR,
      c.SUM_BEGINNING_BALANCE_PRIOR
    );
    SUM_DOWNSELL_PRIOR = accumulateValue(
      SUM_DOWNSELL_PRIOR,
      c.SUM_DOWNSELL_PRIOR
    );
    SUM_UPSELL_PRIOR = accumulateValue(SUM_UPSELL_PRIOR, c.SUM_UPSELL_PRIOR);
    SUM_LOST_CUSTOMER_PRIOR = accumulateValue(
      SUM_LOST_CUSTOMER_PRIOR,
      c.SUM_LOST_CUSTOMER_PRIOR
    );
    INITIAL_CUSTOMER = accumulateValue(INITIAL_CUSTOMER, c.INITIAL_CUSTOMER);
    INITIAL_LOST_CUSTOMER = accumulateValue(
      INITIAL_LOST_CUSTOMER,
      c.INITIAL_LOST_CUSTOMER
    );
    PRIOR_CUSTOMER = accumulateValue(PRIOR_CUSTOMER, c.PRIOR_CUSTOMER);
    PRIOR_LOST_CUSTOMER = accumulateValue(
      PRIOR_LOST_CUSTOMER,
      c.PRIOR_LOST_CUSTOMER
    );
  }

  if (SUM_ENDING_BALANCE) {
    SUM_ENDING_BALANCE /= metricsDivisors[measurement];
  }

  const summed: CohortReport = {
    ...cohorts[0],
    SUM_ENDING_BALANCE,
    CUSTOMER_COUNT,
    SUM_BEGINNING_BALANCE_INITIAL,
    SUM_DOWNSELL_INITIAL,
    SUM_UPSELL_INITIAL,
    SUM_LOST_CUSTOMER_INITIAL,
    SUM_BEGINNING_BALANCE_PRIOR,
    SUM_DOWNSELL_PRIOR,
    SUM_UPSELL_PRIOR,
    SUM_LOST_CUSTOMER_PRIOR,
    INITIAL_CUSTOMER,
    INITIAL_LOST_CUSTOMER,
    PRIOR_CUSTOMER,
    PRIOR_LOST_CUSTOMER,
  };

  let retentionValue = getRetentionValue(by, relativeTo, customerLevel, summed);

  if (typeof retentionValue === "number") {
    retentionValue = Math.round(retentionValue * 100) / 100;
  }

  return {
    ...summed,
    retentionValue,
  };
}

function sumReportData(
  data: CohortReport[],
  revenueType: RevenueType,
  measurement: ReportMeasurement,
  by: ByValue,
  relativeTo: RelativeTo,
  headerKey: string,
  customerLevel: CustomerLevel
): CohortReport[] {
  if (revenueType === RevenueType.Monthly) {
    return data.map((cohort) => ({
      ...cohort,
      SUM_ENDING_BALANCE:
        cohort.SUM_ENDING_BALANCE &&
        cohort.SUM_ENDING_BALANCE / metricsDivisors[measurement],
      retentionValue: getRetentionValue(by, relativeTo, customerLevel, cohort),
    }));
  }
  const groupedByHeader = lodashGroupBy(
    data,
    (item) => item[headerKey as keyof CohortReport]
  );
  return Object.entries(groupedByHeader).map(([, cohorts]) =>
    getSummedCohort(cohorts, measurement, by, relativeTo, customerLevel)
  );
}

function getCohortKey(
  revenueType: RevenueType,
  measurement: ReportMeasurement
): keyof CohortReport {
  if (revenueType === RevenueType.Yearly) {
    return "COHORT_FISCALYR";
  }
  if (revenueType === RevenueType.Quarterly) {
    return "COHORT_DISPLAYQTR";
  }
  if (
    measurement === ReportMeasurement.ARR ||
    measurement === ReportMeasurement.MRR ||
    measurement === ReportMeasurement.QRR
  ) {
    return "ARRSTARTDATE" as keyof CohortReport;
  }
  return "CARRSTARTDATE" as keyof CohortReport;
}

export function getRows(
  data: CohortReport[],
  revenueType: RevenueType,
  measurement: ReportMeasurement,
  by: ByValue,
  relativeTo: RelativeTo,
  headerKey: string,
  customerLevel: CustomerLevel
): { cohort: string; data: CohortReport[] }[] {
  // Group data by cohorts
  const grouped = lodashGroupBy(
    data,
    (item) => item[getCohortKey(revenueType, measurement)]
  );
  return Object.entries(grouped).map(([cohort, tenures]) => {
    // Groups of cohorts (by month) in the selected cohort type (quarter or year)
    const groupByStartDate = lodashGroupBy(
      tenures,
      (tenure) =>
        tenure[
          (measurement === ReportMeasurement.ARR ||
          measurement === ReportMeasurement.MRR ||
          measurement === ReportMeasurement.QRR
            ? "ARRSTARTDATE"
            : "CARRSTARTDATE") as keyof CohortReport
        ]
    );
    // Filter only the last items of the selected tenure/period type
    const filtered = Object.entries(groupByStartDate).flatMap(
      ([, allTenures]) =>
        getLastItemOfEachGroup(allTenures, headerKey as keyof CohortReport)
    );
    return {
      cohort,
      data: sumReportData(
        filtered,
        revenueType,
        measurement,
        by,
        relativeTo,
        headerKey,
        customerLevel
      ),
    };
  });
}
