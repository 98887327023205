import { AuthUserContext } from "common/providers/AuthUserProvider";
import { useContext } from "react";

export function useAuthUser() {
  const context = useContext(AuthUserContext);
  if (!context) {
    throw new Error("useAuthUser must be used within an AuthUserProvider");
  }

  // Function to get the authentication token from localStorage
  const getToken = () => {
    return localStorage.getItem("userToken");
  };

  return { ...context, getToken };
}
