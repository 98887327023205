import Table from "components/Table";
import { CategoriesAndPeriodsTableModel } from "models/report";
import { Column } from "components/Table/models";

interface Props {
  tableData?: CategoriesAndPeriodsTableModel[];
  columns?: Column<CategoriesAndPeriodsTableModel>[];
}

export default function CountChangesTable(props: Props) {
  const { columns, tableData } = props;
  return (
    <Table
      data={tableData ?? []}
      columns={columns ?? []}
      rowKey="category"
      bodySx={{ zIndex: 0, position: "relative" }}
      footerSx={{
        bottom: 0,
        position: "sticky",
        backgroundColor: "white",
      }}
      alternateRowColors={false}
      alternateColumnColors={true}
      stickyHeader
    />
  );
}
