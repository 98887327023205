import { createAppThunk } from "common/appThunk";
import { reportClient, reportServiceClient } from "common/client/reportClient";
import { teamServiceClient } from "common/client/teamManagementClient";
import {
  AnalysisType,
  CustomerLevel,
  MIN_DATE,
  ROUTE_PATHS,
  ReportMeasurement,
  ReportView,
} from "common/constants";
import lodashSortBy from "lodash/sortBy";
import { ApiErrorModel } from "models/apiErrorModel";
import { Collection, Paging } from "models/collection";
import { KeyValue } from "models/common";
import { FileModel } from "models/fileModel";
import {
  ARRCohortReport,
  CARRCohortReport,
  CohortReport,
  ContractRenewalCategoryResponse,
  CustomerResponse,
  CustomersByChangeCategoryResponse,
  DashboardView,
  ExportResponse,
  OverviewChartData,
  ReportFile,
  RevenueByChangeCategoryResponse,
  RevenueByChangeCategoryVolumeImpactResponse,
  RevenueByCustomer,
  RevenueChangeDrilldownResponse,
  SegCustResponse,
  SegProdResponse,
} from "models/report";
import moment from "moment";
import { ReportData, ReportSettings } from "slices/models/reportSliceModel";
import extractIdFromPathname from "utils/extractIdFromPathname";
import {
  getMappingRecord,
  getSegmentations,
  mapCohortReportData,
} from "utils/report";
import { extractGlobalSettings } from "utils/globalSettingsUtils";
import { orderBy } from "lodash";

interface FilterPayload {
  renewalCaptureLevel: number | undefined;
  expiringToRenewingWindow: number | undefined;
  customerType?: string | null;
  productType?: string | null;
  chartMeasure: number;
  customers: string | undefined;
  byProductKeys: { [segment: string]: string } | undefined;
  byCustomerSegmentKeys: { [segment: string]: string } | undefined;
  month?: string | undefined;
  countCustomer?: boolean;
  customerLevel?: CustomerLevel;
  timeFrame?: number;
  minDate?: string;
  maxDate?: string;
  topCustomers?: number;
  incompleteView?: DashboardView;
}

function buildFilterKeyList(filteredSegments: {
  [segment: string]: KeyValue[];
}) {
  const segmentKeys: { [key: string]: string } = {};

  for (const [key, values] of Object.entries(filteredSegments)) {
    const dataKey = `${key.replaceAll(" ", "")}Key`;
    if (values.length > 0) {
      segmentKeys[dataKey] = orderBy(values, (x) => x.key)
        .map((x) => x.key)
        .join(",");
    }
  }

  return segmentKeys;
}

function buildFilterPayload(
  type?: ReportView | null,
  segmentKey?: string | null,
  reportSettings?: ReportSettings
) {
  const filters: FilterPayload = {
    customerType: segmentKey,
    productType: segmentKey,
    chartMeasure: reportSettings?.measurement as number,
    customers: reportSettings?.filters?.customers?.map((x) => x.key).join(","),
    byProductKeys: buildFilterKeyList(
      reportSettings?.filters?.segmentProducts || {}
    ),
    byCustomerSegmentKeys: buildFilterKeyList(
      reportSettings?.filters?.segmentCustomers || {}
    ),
    countCustomer:
      reportSettings?.chartSettings["customer-cohorts"]?.show === "count",
    customerLevel:
      reportSettings?.params.customerLevel || CustomerLevel.Customer,
    timeFrame: (reportSettings?.params.revenueType as number) + 1,
    renewalCaptureLevel: undefined,
    expiringToRenewingWindow: undefined,
    minDate: reportSettings?.filters.defaultMinDate,
    maxDate: reportSettings?.filters.defaultMaxDate,
    incompleteView: reportSettings?.viewBy,
  };

  if (type === ReportView.CustomerType) delete filters.productType;
  if (type === ReportView.TopCustomerType) delete filters.productType;
  if (type === ReportView.ProductType) delete filters.customerType;
  if (type === ReportView.TopProductType) delete filters.customerType;
  if (segmentKey === "") {
    delete filters.productType;
    delete filters.customerType;
  }

  const monthStr = reportSettings?.chartSettings["top-customers"]?.month;
  if (
    monthStr &&
    (type === ReportView.TopCustomerType || type === ReportView.TopProductType)
  ) {
    const month = moment(monthStr).endOf("month");
    filters.month = month.format("YYYY-MM-DD");
  }

  const topCustomers =
    reportSettings?.chartSettings["top-customers"]?.topCustomers;
  if (
    topCustomers &&
    (type === ReportView.TopCustomerType || type === ReportView.TopProductType)
  ) {
    filters.topCustomers = topCustomers;
  }

  const countCustomer = reportSettings?.chartSettings["customer-cohorts"]?.show;
  if (countCustomer === "count" && type === ReportView.CohortByTenurePeriod) {
    filters.countCustomer = countCustomer === "count";
  }
  if (type === ReportView.ContractRenewalDetail) {
    filters.renewalCaptureLevel =
      (reportSettings?.params.customerLevel || 0) + 2;
    filters.expiringToRenewingWindow =
      reportSettings?.chartSettings["contract-renewal"].renewalWindow;
  }
  if (reportSettings?.params.customerLevel === CustomerLevel.Customer) {
    filters.byProductKeys = undefined;
  }
  if (reportSettings?.measurement === ReportMeasurement.QRR) {
    filters.chartMeasure = ReportMeasurement.ARR;
  }
  if (reportSettings?.measurement === ReportMeasurement.CQRR) {
    filters.chartMeasure = ReportMeasurement.CARR;
  }
  return filters;
}

async function getReportDataRevenueBy<ResponseData = unknown>(
  fileId: string,
  reportSettings: ReportSettings,
  view?: ReportView | null,
  segmentKey?: string | null,
  filtersOverride?: {}
): Promise<ResponseData[]> {
  const filters = buildFilterPayload(view, segmentKey, reportSettings);

  const { data: response } = await reportClient.post("report", {
    view,
    fileId,
    filters: {
      ...filters,
      threshold: reportSettings.params.reactivationThreshold,
      customerLevel: reportSettings.params.customerLevel,
      monthlyRevenue: reportSettings.params.analysisType,
      ...filtersOverride,
    },
  });

  return response.data;
}

async function fetchReportDataAsync(file: FileModel) {
  const teamId = extractIdFromPathname(ROUTE_PATHS.TEAMS, location.pathname);
  const workspaceId = extractIdFromPathname(
    ROUTE_PATHS.WORKSPACES,
    location.pathname
  );
  const responses = await teamServiceClient.get(
    `teams/${teamId}/workspaces/${workspaceId}/settings`
  );
  const globalSettings = extractGlobalSettings(responses.data.data);

  const dateRangeQuery =
    !globalSettings.minDate || !globalSettings.maxDate
      ? ""
      : `minDate=${globalSettings.minDate}&maxDate=${globalSettings.maxDate}`;
  const filterResponses = await Promise.all([
    reportServiceClient.get(
      `teams/${file.tenantId}/workspaces/${file.workspaceId}/files/${file.id}/customers?${dateRangeQuery}`
    ),
    reportServiceClient.get(
      `teams/${file.tenantId}/workspaces/${file.workspaceId}/files/${file.id}/product-segments?${dateRangeQuery}`
    ),
    reportServiceClient.get(
      `teams/${file.tenantId}/workspaces/${file.workspaceId}/files/${file.id}/customer-segments?${dateRangeQuery}`
    ),
  ]);

  const mapping = getMappingRecord(file.mapping ?? []);
  const customers = (filterResponses[0].data.data as CustomerResponse[]).map(
    (x) => ({
      key: x.K,
      value: x.V,
    })
  );
  const segProducts = filterResponses[1].data.data as SegProdResponse[];
  const segCustomers = filterResponses[2].data.data as SegCustResponse[];
  const grpSegCustomers = (
    getSegmentations(segCustomers, mapping) as {
      segmentName: string;
      data: SegCustResponse[];
    }[]
  ).map((x) => ({
    segment: x.segmentName,
    data: x.data.map((y) => ({
      key: y.CUSTOMERSEGMENTKEY,
      value: y.VALUE,
    })),
  }));
  const grpSegProducts = (
    getSegmentations(segProducts, mapping) as {
      segmentName: string;
      data: SegProdResponse[];
    }[]
  ).map((x) => ({
    segment: x.segmentName,
    data: x.data.map((y) => ({
      key: y.PRODUCTKEY,
      value: y.VALUE,
    })),
  }));

  return {
    mapping,
    customers,
    grpSegCustomers,
    grpSegProducts,
    globalSettings,
  };
}

export const getReportData = createAppThunk<ReportData, FileModel>(
  "report/filterData",
  async (file) => {
    try {
      const {
        mapping,
        customers,
        grpSegCustomers: segmentCustomers,
        grpSegProducts: segmentProducts,
        globalSettings,
      } = await fetchReportDataAsync(file);

      const minimumDate = moment(MIN_DATE).format("YYYY-MM-01");
      const current = moment(new Date()).format("YYYY-MM-01");
      let minDate = globalSettings.minDate ?? minimumDate;
      let maxDate = globalSettings.maxDate ?? current;
      minDate = moment(minDate).endOf("M").format("YYYY-MM-DD");
      maxDate = moment(maxDate).endOf("M").format("YYYY-MM-DD");

      const reportData: ReportData = {
        isLoading: false,
        file,
        mapping,
        globalSettings,
        customers,
        segmentCustomers,
        segmentProducts,
        minDate,
        maxDate,
      };
      return reportData;
    } catch (error) {
      if ((error as ApiErrorModel).errorCode) {
        throw (error as ApiErrorModel).errorCode;
      }
      throw error;
    }
  }
);

export const getRollForwardChartImpactVolume = createAppThunk<
  RevenueByChangeCategoryVolumeImpactResponse[],
  void
>("chart/roll-forward-impact-volume", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const chartData = state.report.chartData["roll-forward-impact-volume"];
  const reportSettings = state.report.currentSettings[state.report.dashboardId];
  if (chartData.data === undefined || chartData.shouldFetch) {
    const response =
      await getReportDataRevenueBy<RevenueByChangeCategoryVolumeImpactResponse>(
        state.report.reportData.file!.id,
        reportSettings,
        ReportView.RevenueByCategoryImpactVolume
      );
    return response;
  }
  return chartData.data;
});

export const getRollForwardChart = createAppThunk<
  RevenueByChangeCategoryResponse[],
  void
>("chart/roll-forward", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const chartData = state.report.chartData["roll-forward"];
  const reportSettings = state.report.currentSettings[state.report.dashboardId];
  if (chartData.data === undefined || chartData.shouldFetch) {
    const response =
      await getReportDataRevenueBy<RevenueByChangeCategoryResponse>(
        state.report.reportData.file!.id,
        reportSettings,
        ReportView.RevenueByCategory
      );

    return lodashSortBy(response, (x) => x.Month);
  }

  return chartData.data;
});

export const getRevenueByPeriodChart = createAppThunk<
  OverviewChartData[],
  { view?: ReportView | null; segmentKey?: string | null }
>("chart/overview", async (params, thunkApi) => {
  const state = thunkApi.getState();
  const chartData = state.report.chartData.overview;
  const reportSettings = state.report.currentSettings[state.report.dashboardId];

  const view =
    !params.view || params.view === ReportView.Total
      ? ReportView.CustomerType
      : params.view;

  if (chartData.data === undefined || chartData.shouldFetch) {
    const response = await getReportDataRevenueBy<OverviewChartData>(
      state.report.reportData.file!.id,
      reportSettings,
      view,
      params.segmentKey
    );

    return lodashSortBy(response, (x) => x.Month);
  }

  return chartData.data;
});

export const getCountChangeChart = createAppThunk<
  CustomersByChangeCategoryResponse[],
  void
>("chart/count-change", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const chartData = state.report.chartData["count-change"];
  const reportSettings = state.report.currentSettings[state.report.dashboardId];

  if (chartData.data === undefined || chartData.shouldFetch) {
    const response =
      await getReportDataRevenueBy<CustomersByChangeCategoryResponse>(
        state.report.reportData.file!.id,
        reportSettings,
        ReportView.CustomersByCategory
      );

    return lodashSortBy(response, (x) => x.Month);
  }

  return chartData.data;
});

export const getContractRenewalChart = createAppThunk<
  ContractRenewalCategoryResponse[],
  void
>("chart/contract-renewal", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const chartData = state.report.chartData["contract-renewal"];
  const reportSettings = state.report.currentSettings[state.report.dashboardId];

  if (chartData.data === undefined || chartData.shouldFetch) {
    const response =
      await getReportDataRevenueBy<ContractRenewalCategoryResponse>(
        state.report.reportData.file!.id,
        reportSettings,
        ReportView.ContractRenewalDetail
      );
    return response;
  }

  return chartData.data;
});

export const getTopCustomerChart = createAppThunk<
  RevenueByCustomer[],
  { month: string; view?: ReportView | null; segmentKey?: string | null }
>("chart/top-customers", async (params, thunkApi) => {
  const state = thunkApi.getState();
  const chartData = state.report.chartData["top-customers"];
  const reportSettings = state.report.currentSettings[state.report.dashboardId];

  if (chartData.data === undefined || chartData.shouldFetch) {
    const response = await getReportDataRevenueBy<RevenueByCustomer>(
      state.report.reportData.file!.id,
      reportSettings,
      params.view ?? ReportView.TopCustomerType,
      params.segmentKey
    );

    return lodashSortBy(response, (x) => x.Month);
  }

  return chartData.data;
});

export const getCustomerCohortChart = createAppThunk<
  (ARRCohortReport | CARRCohortReport)[],
  void
>("chart/customer-cohorts", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const { data: cachedData, shouldFetch } =
    state.report.chartData["customer-cohorts"];
  const reportSettings = state.report.currentSettings[state.report.dashboardId];
  const {
    chartSettings,
    params: { customerLevel },
    measurement,
  } = reportSettings;
  const { productType, priorPeriodComparison, byValue, relativeTo } =
    chartSettings["customer-cohorts"];

  if (cachedData === undefined || shouldFetch) {
    if (customerLevel === CustomerLevel.CustomerProduct && !productType) {
      return [];
    }
    const response = await getReportDataRevenueBy<{
      [key: string]: keyof CohortReport;
    }>(
      state.report.reportData.file!.id,
      reportSettings,
      ReportView.CohortTenure,
      "",
      {
        monthlyRevenue:
          byValue !== "base" && relativeTo === "prior"
            ? priorPeriodComparison
            : undefined,
        productType:
          customerLevel === CustomerLevel.CustomerProduct
            ? productType
            : undefined,
      }
    );
    const mappedData =
      customerLevel === CustomerLevel.CustomerProduct
        ? mapCohortReportData(response, measurement)
        : (response as unknown as (ARRCohortReport | CARRCohortReport)[]);

    // Log for testing
    console.log("[Cohort Report] Mapped data:", mappedData);
    return lodashSortBy(mappedData, (x) => x.Month) as (
      | ARRCohortReport
      | CARRCohortReport
    )[];
  }

  return cachedData;
});

const categoryMapping: { [key: string]: string } = {
  "Ending/Beginning Revenue Credits": "BEGINNING/ENDING REVENUE CREDITS",
  "Customer Reactivation": "CUSTOMER REACTIVATION",
  Downsell: "DOWNSELL",
  Upsell: "UPSELL",
  "Lost Customer": "LOST CUSTOMER",
  "New Customer": "NEW CUSTOMER",
  "Cust Segment Migration": "CUSTOMER SEGMENT MIGRATION",
  "Cross-Sell": "CROSS-SELL",
  "Lost Product": "LOST-PRODUCT",
  "Product Reactivation": "PRODUCT REACTIVATION",
};

export async function getRevenueChangeDrilldown(
  fileId: string,
  month: string,
  category: string,
  pagingFilter: Paging,
  reportSettings: ReportSettings
): Promise<Collection<RevenueChangeDrilldownResponse>> {
  const filters = buildFilterPayload(undefined, undefined, reportSettings);

  const { data: response } = await reportClient.post(
    "report/chart/revenue/drill-down",
    {
      fileId,
      filters: {
        ...filters,
        monthlyRevenue: reportSettings.params.analysisType,
        threshold: reportSettings.params.reactivationThreshold,
        month: new Date(
          Date.UTC(
            Number.parseInt(month.split("-")[0]),
            Number.parseInt(month.split("-")[1]) - 1,
            1
          )
        ),
        category: categoryMapping[category],
        pagingFilter,
      },
    }
  );
  return response.data;
}

export const getReportExportProcess = createAppThunk<
  ExportResponse,
  {
    fileId: string;
    measurement: ReportMeasurement;
    analysisType: AnalysisType;
    threshold: number;
    dashboardId: string;
  }
>(
  "report/getReportExportProcess",
  async ({ fileId, measurement, analysisType, threshold }) => {
    try {
      const { data: response } = await reportClient.post("report/export", {
        fileId,
        type: measurement,
        period: analysisType,
        threshold,
      });
      return response.data;
    } catch (error) {
      if ((error as ApiErrorModel).errorMessage) {
        throw (error as ApiErrorModel).errorMessage;
      }
      throw error;
    }
  }
);

export async function getReportExportHistory(
  fileId: string,
  teamId: string,
  wsId: string
): Promise<ReportFile[]> {
  const { data: response } = await reportClient.get(
    `/teams/${teamId}/workspaces/${wsId}/reports/files/${fileId}`
  );
  return response.data;
}
