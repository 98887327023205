export const CATEGORIES_SHEET1 = [
  "Global View",
  "Global Time Frame",
  "View By",
  "Customer Level",
  "Customer ID",
  "Customer Segments",
  "Product Info",
  "Start Date",
  "End Date",
];

export const MONTH_EXCEL_DATA_FORMAT = "mmm-yy";
export const UNIT = {
  PERCENT: "percent",
  THOUSANDS: "thousands",
};
