import {
  ArrowBackOutlined,
  FileOpenOutlined,
  SyncAltOutlined,
} from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { ROUTE_PATHS } from "common/constants";
import AppBar from "components/AppBar";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

export default function ManageSourceData() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { t } = useTranslation();
  const from = useRef(location.state?.from);
  const page = useRef(location.state?.page);
  const urlWorkspaceHome = `/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}/${params.wsId}/${ROUTE_PATHS.WORKSPACES_HOME}`;
  return (
    <>
      <AppBar>
        <Stack direction="row" alignItems={"center"} justifyContent={"center"}>
          <IconButton
            onClick={() => {
              navigate(from.current || urlWorkspaceHome);
            }}
          >
            <ArrowBackOutlined />
          </IconButton>
          <Typography variant="h6" fontWeight={500}>
            Back to {page.current || "Workspace Home"}
          </Typography>
        </Stack>
      </AppBar>
      <div
        style={{
          margin: "24px 24px 0px 24px",
          display: "flex",
          alignItems: "flex-start",
          gap: "24px",
        }}
      >
        <Paper elevation={0} sx={{ p: "24px" }}>
          <Card
            elevation={0}
            sx={{
              minWidth: "280px",
              height: "fit-content",
            }}
          >
            <CardHeader
              title={
                <Typography variant="h6">
                  {t("ManageSourceData.Title")}
                </Typography>
              }
              subheader={
                <Typography variant="body1">
                  {t("ManageSourceData.SubTitle")}
                </Typography>
              }
            />
            <Divider />
            <CardContent>
              <List
                sx={{
                  width: "100%",
                  color: "text.primary",
                }}
                subheader={
                  <Typography
                    variant="body2"
                    fontWeight={700}
                    sx={{ ml: 2 }}
                    color="text.secondary"
                    lineHeight="48px"
                  >
                    {t("ManageSourceData.SourceData")}
                  </Typography>
                }
              >
                <ListItem sx={{ padding: 0 }}>
                  <NavLink
                    to={ROUTE_PATHS.MANAGE_SOURCE_DATA_FILE}
                    style={{ width: "100%" }}
                  >
                    {({ isActive }) => (
                      <ListItemButton
                        selected={isActive}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                          paddingX: 2,
                          paddingY: 1.5,
                        }}
                      >
                        <FileOpenOutlined
                          sx={{
                            color: "rgba(0, 0, 0, 0.54)",
                          }}
                        />
                        <Typography variant="body1">
                          {t("ManageSourceData.SourceFile")}
                        </Typography>
                      </ListItemButton>
                    )}
                  </NavLink>
                </ListItem>
                <ListItem sx={{ padding: 0 }}>
                  <NavLink
                    to={ROUTE_PATHS.MANAGE_SOURCE_DATA_MAPPING}
                    style={{ width: "100%" }}
                  >
                    {({ isActive }) => (
                      <ListItemButton
                        selected={isActive}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                          paddingX: 2,
                          paddingY: 1.5,
                        }}
                      >
                        <SyncAltOutlined
                          sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                        />
                        <Typography variant="body1">
                          {t("ManageSourceData.SyncData")}
                        </Typography>
                      </ListItemButton>
                    )}
                  </NavLink>
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Paper>
        <Outlet />
      </div>
    </>
  );
}
