import * as XLSX from "xlsx-js-style";
import { saveAs } from "file-saver";
import { formatCurrencyValue } from "utils/format";
import moment from "moment";

import { CATEGORIES_SHEET1, MONTH_EXCEL_DATA_FORMAT, UNIT } from "./constants";

type ExportableData<T> = T[];

const ensureCellExists = (worksheet: XLSX.WorkSheet, cellRef: string) => {
  if (!worksheet[cellRef]) {
    worksheet[cellRef] = { t: "s", v: "" };
  }
};

const applyBoldToRow = (
  worksheet: XLSX.WorkSheet,
  range: XLSX.Range,
  rowIndex: number
) => {
  for (let col = range.s.c; col <= range.e.c; col++) {
    const cellRef = XLSX.utils.encode_cell({ r: rowIndex, c: col });
    ensureCellExists(worksheet, cellRef);
    const existingStyle = worksheet[cellRef].s || {};
    worksheet[cellRef].s = {
      ...existingStyle,
      font: { bold: true },
    };
  }
};

const getLastRowWithData = (
  worksheet: XLSX.WorkSheet,
  range: XLSX.Range,
  columnIndex: number
): number | null => {
  for (let row = range.e.r; row >= 1; row--) {
    const cellRef = XLSX.utils.encode_cell({ r: row, c: columnIndex });
    if (worksheet[cellRef] && worksheet[cellRef].v) {
      return row;
    }
  }
  return null;
};

const addLeadingWhitespaceToColumnA = (
  worksheet: XLSX.WorkSheet,
  startRow: number,
  endRow: number
) => {
  for (let row = startRow; row <= endRow; row++) {
    const cellRef = XLSX.utils.encode_cell({ r: row, c: 0 }); // Column A
    ensureCellExists(worksheet, cellRef);
    worksheet[cellRef].v = `  ${worksheet[cellRef].v}`;
  }
};

const setItalicToCell = (
  worksheet: XLSX.WorkSheet,
  rowIndex: number,
  columnIndex: number
) => {
  const cellRef = XLSX.utils.encode_cell({ r: rowIndex, c: columnIndex });
  ensureCellExists(worksheet, cellRef);
  worksheet[cellRef].s = {
    font: { italic: true },
  };
};

const applyBordersToCellsWithValues = (
  worksheet: XLSX.WorkSheet,
  range: XLSX.Range
) => {
  for (let row = range.s.r; row <= range.e.r; row++) {
    for (let col = range.s.c; col <= range.e.c; col++) {
      const cellRef = XLSX.utils.encode_cell({ r: row, c: col });
      if (
        worksheet[cellRef] &&
        (worksheet[cellRef].v || worksheet[cellRef].v === 0)
      ) {
        ensureCellExists(worksheet, cellRef);

        const existingStyle = worksheet[cellRef].s || {};
        worksheet[cellRef].s = {
          ...existingStyle,
          border: {
            top: { style: "thin", color: { rgb: "000000" } },
            bottom: { style: "thin", color: { rgb: "000000" } },
            left: { style: "thin", color: { rgb: "000000" } },
            right: { style: "thin", color: { rgb: "000000" } },
          },
        };
      }
    }
  }
};
const applyBordersToAllDataCell = (
  worksheet: XLSX.WorkSheet,
  range: XLSX.Range
) => {
  for (let row = range.s.r; row <= range.e.r; row++) {
    for (let col = range.s.c; col <= range.e.c; col++) {
      const cellRef = XLSX.utils.encode_cell({ r: row, c: col });

      ensureCellExists(worksheet, cellRef);

      const existingStyle = worksheet[cellRef].s || {};
      worksheet[cellRef].s = {
        ...existingStyle,
        border: {
          top: { style: "thin", color: { rgb: "000000" } },
          bottom: { style: "thin", color: { rgb: "000000" } },
          left: { style: "thin", color: { rgb: "000000" } },
          right: { style: "thin", color: { rgb: "000000" } },
        },
      };
    }
  }
};

const formatNumberForExcel = (
  value: number,
  unit: "thousands" | "millions" | ""
): { v: string; t: string; s: object } | string => {
  if (typeof value === "number") {
    const currencyValue = unit
      ? formatCurrencyValue(value, true, true, unit)
      : value.toFixed(0);
    return {
      v: currencyValue,
      t: "s",
      s: {
        alignment: { horizontal: "right" },
      },
    };
  }
  return value;
};

const formatPercentForExcel = (
  value: number
): { v: number; t: "n"; z: string; s: object } | string => {
  if (typeof value === "number") {
    const formatString = value === 0 || value === 1 ? "0%" : "0.00%";
    return {
      v: value,
      t: "n",
      z: formatString,
      s: {
        alignment: { horizontal: "right" },
      },
    };
  }
  return value;
};

const ensureCellStyleForMultiLine = (
  worksheet: XLSX.WorkSheet,
  cellRef: string
) => {
  if (worksheet[cellRef]) {
    const existingStyle = worksheet[cellRef].s || {};
    worksheet[cellRef].s = {
      ...existingStyle,
      alignment: { wrapText: true },
    };
  }
};

const calculateColumnWidths = (data: unknown[][]): XLSX.ColInfo[] => {
  // Determine the maximum number of columns in the data array.
  const colCount = data.reduce((max, row) => Math.max(max, row.length), 0);
  const colWidths = Array.from({ length: colCount }, () => 0);

  for (const row of data) {
    for (const [colIdx, cell] of row.entries()) {
      let cellText = "";
      if (cell && typeof cell === "object" && "v" in cell) {
        cellText = cell.v?.toString() || "";
      } else {
        cellText = cell ? cell.toString() : "";
      }
      // Update maximum width for the column.
      colWidths[colIdx] = Math.max(colWidths[colIdx], cellText.length);
    }
  }

  // Return widths with some padding.
  return colWidths.map((width) => ({ wch: width + 2 }));
};

export const exportToExcel = <T>(
  sheetData: {
    sheetName: string;
    header?: (string | number)[];
    categories?: (string | number)[];
    data?: ExportableData<T>;
    subData?: Record<string, unknown>;
    option?: {
      unitData?: string; // sheet 0
      highlight?: boolean; // sheet 0
      chartFilter?: unknown[]; // sheet 1
    };
  }[],
  fileName: string
): void => {
  if (!sheetData || sheetData.length === 0) {
    console.error("No data available to export.");
    return;
  }

  const workbook = XLSX.utils.book_new();
  const unitData = sheetData[0].option?.unitData;

  for (const [
    sheetIndex,
    {
      sheetName,
      header = [],
      categories = [],
      data = [],
      subData = {},
      option = {},
    },
  ] of sheetData.entries()) {
    let transposedData: unknown[][];

    if (
      sheetIndex === 1 &&
      option.chartFilter &&
      Array.isArray(option.chartFilter)
    ) {
      const leftObj = Array.isArray(data) && data.length > 0 ? data[0] : {};
      const leftRows = Object.entries(leftObj as T[]).map(([key, value]) => [
        key,
        value,
      ]);
      // Build right table rows from chartFilter:
      const rightRows = option.chartFilter.map((item) => {
        const keys = Object.keys(item as Record<string, unknown>);
        if (keys.length === 0) return ["", ""];
        const key = keys[0];
        const value = (item as Record<string, unknown>)[key];
        return [key, value];
      });
      // Determine the maximum number of rows.
      const maxRows = Math.max(leftRows.length, rightRows.length);
      const combinedRows: unknown[][] = [];
      // Insert an empty row as row 1 (if you want row 2 to be the first row of data)
      combinedRows.push([
        "Filter summary",
        "",
        "",
        `${rightRows.length > 0 ? "Chart filter" : ""}`,
        "",
      ]);
      for (let i = 0; i < maxRows; i++) {
        const leftRow = leftRows[i] || ["", ""];
        const rightRow = rightRows[i] || ["", ""];
        // Combined row: columns A–B = leftRow, column C = empty, columns D–E = rightRow.
        combinedRows.push([...leftRow, "", ...rightRow]);
      }
      transposedData = combinedRows;
    } else {
      transposedData = [
        header.map((col) => ({
          v: col,
          t: moment(col, "YYYY-MM", true).isValid() ? "d" : "s",
          z: moment(col, "YYYY-MM", true).isValid()
            ? MONTH_EXCEL_DATA_FORMAT
            : undefined,
          s: {
            alignment: { horizontal: "center", vertical: "center" },
            font: { bold: true },
          },
        })),
        ...categories.map((field) => [
          field,
          ...data.map((row) => {
            return row[field as keyof T] !== undefined
              ? unitData === UNIT.PERCENT
                ? formatPercentForExcel(row[field as keyof T] as number)
                : formatNumberForExcel(
                    row[field as keyof T] as number,
                    unitData as "thousands" | "millions" | ""
                  )
              : 0;
          }),
        ]),
      ];

      const insertSubRows = (
        parentCategory: string,
        subItems: {
          category: string;
          [key: string]: string | number | undefined;
        }[],
        level: number
      ) => {
        const categoryIndex = transposedData.findIndex(
          (row) => row[0] === parentCategory
        );
        if (categoryIndex === -1) return;

        let insertIndex = categoryIndex + 1;

        for (const item of subItems) {
          const subRow = [
            `${" ".repeat(level * 4)}${item.category || ""}`,
            ...header.slice(1).map((date) => {
              return item[date] !== undefined
                ? unitData === UNIT.PERCENT
                  ? formatPercentForExcel(item[date] as number)
                  : formatNumberForExcel(
                      item[date] as number,
                      unitData as "thousands" | "millions" | ""
                    )
                : 0;
            }),
          ];
          transposedData.splice(insertIndex, 0, subRow);
          insertIndex++;

          if (item.subItems && Array.isArray(item.subItems)) {
            insertSubRows(item.category, item.subItems, level + 1);
          }
        }
      };

      // Insert sub-data rows if present
      if (subData) {
        for (const [category, items] of Object.entries(subData)) {
          if (Array.isArray(items) && items.length > 0) {
            insertSubRows(category, items, 1);
          }
        }
      }
    }

    const worksheet = XLSX.utils.aoa_to_sheet(transposedData);

    if (worksheet["!ref"]) {
      const range = XLSX.utils.decode_range(worksheet["!ref"]);

      if (sheetIndex === 1) {
        // Apply bold to Column A
        for (let row = range.s.r; row <= range.e.r; row++) {
          const cellRef1 = XLSX.utils.encode_cell({ r: row, c: 0 });
          const cellRef2 = XLSX.utils.encode_cell({ r: row, c: 3 });
          ensureCellExists(worksheet, cellRef1);
          ensureCellExists(worksheet, cellRef2);
          worksheet[cellRef1].s = { font: { bold: true } };
          worksheet[cellRef2].s = { font: { bold: true } };
        }

        // **Apply Multi-Line Text Wrapping for Specific Columns in Sheet 1 (`sheetIndex === 1`)**
        const multilineColumns = new Set([
          CATEGORIES_SHEET1[5],
          CATEGORIES_SHEET1[6],
        ]);
        for (const [colIndex, col] of header.entries()) {
          if (multilineColumns.has(col as string)) {
            for (let row = 1; row <= range.e.r; row++) {
              const cellRef = XLSX.utils.encode_cell({ r: row, c: colIndex });
              ensureCellStyleForMultiLine(worksheet, cellRef);
            }
          }
        }

        applyBordersToCellsWithValues(worksheet, range);
      }

      if (sheetIndex === 0) {
        setItalicToCell(worksheet, 0, 0);

        const lastDataRow = getLastRowWithData(worksheet, range, 0);

        if (lastDataRow !== null && sheetData[0].option?.highlight) {
          // Apply Bold Row A2**
          applyBoldToRow(worksheet, range, 1);

          // Apply Bold Last Data Row**
          applyBoldToRow(worksheet, range, lastDataRow);

          // Add Two Whitespaces to Column A Values (Row A2 to Last Data Row)**
          addLeadingWhitespaceToColumnA(worksheet, 2, lastDataRow - 1);
        }

        applyBordersToAllDataCell(worksheet, range);
      }
    }

    // Instead of using the header only, calculate column widths based on all data:
    worksheet["!cols"] = calculateColumnWidths(transposedData);

    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  }

  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
    cellStyles: true,
  });

  const dataBlob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  saveAs(dataBlob, `${fileName}.xlsx`);
};
