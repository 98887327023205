import { Column } from "components/Table/models";
import { OverviewChartModel } from "models/report";
import { TFunction } from "i18next";
import { formatPeriodText } from "utils/format";
import { ReportView } from "common/constants";

interface OverviewTableModel {
  segmentItem: string | number;
  periods: { [periods: string]: number };
}

export function getOverviewTableColumns(
  periods: string[],
  t: TFunction,
  reportView?: ReportView | null,
  formatValue?: (value: number) => number | string
): Column<OverviewTableModel>[] {
  const headerSegment =
    reportView === ReportView.CustomerType ||
    reportView === ReportView.ProductType
      ? t(
          `Dashboard.OverviewSection.DataTable.${
            reportView === ReportView.CustomerType
              ? "HeaderCustomerSegment"
              : "HeaderProduct"
          }`
        )
      : "";
  return [
    {
      header: headerSegment,
      render: (row) => row.segmentItem,
    },
    ...periods.map((period) => {
      const periodLabel = formatPeriodText(period);

      return {
        header: periodLabel,
        render: (row: OverviewTableModel) =>
          formatValue ? formatValue(row.periods[period]) : row.periods[period],
        sx: {
          textAlign: "left",
          whiteSpace: "nowrap",
        },
        headerSx: {
          textAlign: "center",
        },
      } as Column<OverviewTableModel>;
    }),
  ];
}

export function getOverviewTableData(
  chartData: OverviewChartModel[],
  segmentItems: (string | number)[]
): OverviewTableModel[] {
  const tableData: {
    [segmentItem: string]: { [periods: string]: number };
  } = Object.fromEntries(segmentItems.map((item) => [item, {}]));
  const orderMap = Object.fromEntries(
    segmentItems.map((item, index) => [item, index])
  );

  for (const period of chartData) {
    const xValue = period.xValue;

    for (const segmentItem of segmentItems) {
      tableData[segmentItem][xValue] = period[segmentItem];
    }
  }
  return Object.entries(tableData)
    .sort(([a], [b]) => orderMap[a] - orderMap[b])
    .map(([segmentItem, periods]) => ({
      segmentItem,
      periods,
    }));
}
