import { FilterAltOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import {
  AnalysisType,
  CustomerLevel,
  ROUTES,
  ROUTE_PATHS,
  ReportMeasurement,
  RevenueType,
} from "common/constants";
import { useDispatch, useReportSelector } from "common/store";
import { WorkspaceModel } from "models/workspace";
import { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  setChartShouldFetch,
  setReportParams,
  setReportSettings,
} from "slices/reportSlice";
import {
  isCompleteYear,
  getFiscalStartMonth,
  isCompleteQuarter,
} from "utils/report";
import { DashboardView } from "models/report";

import ContextMenu from "./ContextMenu";
import DashboardFilter from "./DashboardFilter";
import DashboardToolbarChips from "./DashboardToolbarChips";

const metrics = [
  {
    type: "Live",
    value: ReportMeasurement.ARR,
    correspondMeasure: ReportMeasurement.CARR,
    label: "Common.ARR",
  },
  {
    type: "Live",
    value: ReportMeasurement.QRR,
    correspondMeasure: ReportMeasurement.CQRR,
    label: "Common.QRR",
  },
  {
    type: "Live",
    value: ReportMeasurement.MRR,
    correspondMeasure: ReportMeasurement.CMRR,
    label: "Common.MRR",
  },
  {
    type: "Contracted",
    value: ReportMeasurement.CARR,
    correspondMeasure: ReportMeasurement.ARR,
    label: "Common.CARR",
  },
  {
    type: "Contracted",
    value: ReportMeasurement.CQRR,
    correspondMeasure: ReportMeasurement.QRR,
    label: "Common.CQRR",
  },
  {
    type: "Contracted",
    value: ReportMeasurement.CMRR,
    correspondMeasure: ReportMeasurement.MRR,
    label: "Common.CMRR",
  },
];

export default function DashboardToolbar({
  workspace,
}: {
  workspace?: WorkspaceModel;
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();

  const { reportData, reportSettings } = useReportSelector();
  const { params: reportParams, filters: reportFilters } = reportSettings;

  const [openDrawer, setOpenDrawer] = useState(false);

  const showIncompleteYearOptions = useMemo(() => {
    if (
      reportParams.revenueType !== RevenueType.Yearly ||
      reportParams.analysisType !== AnalysisType.YoY ||
      !reportFilters.maxDate
    ) {
      return false;
    }
    const fiscalStartMonth = getFiscalStartMonth(reportData.mapping);
    return !isCompleteYear(fiscalStartMonth, reportFilters.maxDate);
  }, [
    reportData.mapping,
    reportFilters.maxDate,
    reportParams.analysisType,
    reportParams.revenueType,
  ]);

  const showIncompleteQuarterOptions = useMemo(() => {
    if (
      reportParams.revenueType !== RevenueType.Quarterly ||
      reportParams.analysisType !== AnalysisType.QoQ ||
      !reportFilters.maxDate
    ) {
      return false;
    }
    const fiscalStartMonth = getFiscalStartMonth(reportData.mapping);
    return !isCompleteQuarter(fiscalStartMonth, reportFilters.maxDate);
  }, [
    reportData.mapping,
    reportFilters.maxDate,
    reportParams.analysisType,
    reportParams.revenueType,
  ]);

  function handleOnOptionSelect(optionId: string): void {
    if (optionId === "parameters")
      navigate(
        ROUTES.REPORT_PARAMETERS(params.id!, params.wsId!, params.dashboardId!),
        { state: { from: window.location.pathname, page: t("Reports.RRI") } }
      );
    if (optionId === "manage") {
      const url = `/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}/${params.wsId}/${params.dashboardId}/${ROUTE_PATHS.MANAGE_SOURCE_DATA}/${ROUTE_PATHS.MANAGE_SOURCE_DATA_FILE}`;
      navigate(url, {
        state: { from: window.location.pathname, page: t("Reports.RRI") },
      });
    }
  }

  function handleOnCustomerLevelChanged(_: unknown, value: string): void {
    const newCustomerLevel = Number.parseInt(value) as CustomerLevel;
    dispatch(
      setReportParams({
        customerLevel: newCustomerLevel,
      })
    );
    // Update filters when changing to 'Customer' level to clear segment products
    if (newCustomerLevel === CustomerLevel.Customer) {
      dispatch(
        setReportSettings({
          filters: {
            ...reportFilters,
            segmentProducts: {},
          },
        })
      );
    }
  }

  function handleOnMeasurementChanged(_: unknown, value: string): void {
    dispatch(
      setReportSettings({
        measurement: Number.parseInt(value) as ReportMeasurement,
      })
    );
  }

  function handleMetricTypeChanged(_: unknown, value: string): void {
    dispatch(
      setReportSettings({
        metricType: value as "Live" | "Contracted",
        measurement: metrics.find(
          (item) => item.value === reportSettings.measurement
        )?.correspondMeasure,
      })
    );
  }

  function handleViewByChange(_: unknown, value: string) {
    dispatch(setReportSettings({ viewBy: value as DashboardView }));
  }

  useEffect(() => {
    if (showIncompleteYearOptions) {
      const isIncompleteYearView =
        reportSettings.viewBy === "LTM" || reportSettings.viewBy === "YTD";

      dispatch(
        setReportSettings({
          viewBy: isIncompleteYearView ? reportSettings.viewBy : "LTM",
        })
      );
    } else if (showIncompleteQuarterOptions) {
      const isIncompleteQuarterView =
        reportSettings.viewBy === "L3M" || reportSettings.viewBy === "QTD";
      dispatch(
        setReportSettings({
          viewBy: isIncompleteQuarterView ? reportSettings.viewBy : "L3M",
        })
      );
    } else {
      dispatch(setReportSettings({ viewBy: undefined }));
    }
    dispatch(setChartShouldFetch(["roll-forward", "overview"]));
  }, [showIncompleteYearOptions, showIncompleteQuarterOptions]);

  return (
    <>
      <Stack
        width="100%"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
      >
        <Typography variant="h5" color="var(--text-secondary)">
          {t("Dashboard.ToolBar.Title", {
            name:
              workspace?.name ||
              t("Dashboard.ToolBar.WorkspaceNamePlaceholder"),
          })}
        </Typography>
        <Box display="flex">
          <Button
            size="medium"
            color="secondary"
            variant="outlined"
            disabled={reportData.isLoading}
            startIcon={<FilterAltOutlined />}
            onClick={() => setOpenDrawer(true)}
            sx={{ borderRadius: 120, marginRight: 2 }}
          >
            {t("Dashboard.ToolBar.Filters")}
            {reportData.isLoading && (
              <CircularProgress sx={{ marginLeft: 1 }} size={16} />
            )}
          </Button>
          <ContextMenu
            onOptionSelect={handleOnOptionSelect}
            wsRole={workspace?.role}
          />
        </Box>
      </Stack>
      <Stack
        width="100%"
        direction="row"
        justifyContent="space-between"
        alignItems="start"
      >
        <DashboardToolbarChips />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          minWidth={650}
        >
          <Stack gap={2} direction="row">
            <Stack direction="column">
              <Typography variant="body2" marginRight={1}>
                {t("Dashboard.ToolBar.ViewBy")}
              </Typography>
              {showIncompleteYearOptions &&
                reportSettings.viewBy !== undefined && (
                  <RadioGroup
                    data-testid="DashboardToolbar__incompleteYearViewToggle"
                    row
                    value={reportSettings.viewBy}
                    onChange={handleViewByChange}
                  >
                    <FormControlLabel
                      value="LTM"
                      control={<Radio size="small" />}
                      slotProps={{ typography: { variant: "body2" } }}
                      label={t("Dashboard.ToolBar.OptionLast12Months")}
                    />
                    <FormControlLabel
                      value="YTD"
                      control={<Radio size="small" />}
                      slotProps={{ typography: { variant: "body2" } }}
                      label={t("Dashboard.ToolBar.OptionYearToDate")}
                    />
                  </RadioGroup>
                )}
              {showIncompleteQuarterOptions &&
                reportSettings.viewBy !== undefined && (
                  <RadioGroup
                    data-testid="DashboardToolbar__incompleteQuarterViewToggle"
                    row
                    value={reportSettings.viewBy}
                    onChange={handleViewByChange}
                  >
                    <FormControlLabel
                      value="L3M"
                      control={<Radio size="small" />}
                      slotProps={{ typography: { variant: "body2" } }}
                      label={t("Dashboard.ToolBar.OptionLast3Months")}
                    />
                    <FormControlLabel
                      value="QTD"
                      control={<Radio size="small" />}
                      slotProps={{ typography: { variant: "body2" } }}
                      label={t("Dashboard.ToolBar.OptionQuarterToDate")}
                    />
                  </RadioGroup>
                )}
            </Stack>
            <Divider orientation="vertical" flexItem sx={{ marginRight: 2 }} />
            <Stack>
              <Typography variant="body2" marginRight={1}>
                {t("Dashboard.ToolBar.LiveOrContracted")}
              </Typography>
              <RadioGroup
                row
                value={reportSettings.metricType}
                onChange={handleMetricTypeChanged}
              >
                <FormControlLabel
                  value={"Live"}
                  control={<Radio size="small" />}
                  slotProps={{ typography: { variant: "body2" } }}
                  label={t("Dashboard.ToolBar.Live")}
                />
                <FormControlLabel
                  value={"Contracted"}
                  control={<Radio size="small" />}
                  slotProps={{ typography: { variant: "body2" } }}
                  label={t("Dashboard.ToolBar.Contracted")}
                />
              </RadioGroup>
            </Stack>
          </Stack>

          <Divider orientation="vertical" flexItem sx={{ marginRight: 2 }} />
          <Stack gap={2} direction="row">
            <Stack>
              <Typography variant="body2" marginRight={1}>
                {t("Dashboard.ToolBar.Annualization")}
              </Typography>
              <RadioGroup
                row
                value={reportSettings.measurement}
                onChange={handleOnMeasurementChanged}
              >
                {metrics
                  .filter((m) => m.type === reportSettings.metricType)
                  .map((metric) => (
                    <FormControlLabel
                      key={metric.value}
                      value={metric.value}
                      control={<Radio size="small" />}
                      slotProps={{ typography: { variant: "body2" } }}
                      label={t(metric.label)}
                    />
                  ))}
              </RadioGroup>
            </Stack>
          </Stack>
          <Divider orientation="vertical" flexItem sx={{ marginRight: 2 }} />
          <Stack>
            <Typography variant="body2" marginRight={1}>
              {t("Dashboard.ToolBar.CustomerLevel")}
            </Typography>
            <Stack>
              <RadioGroup
                row
                value={reportParams.customerLevel}
                onChange={handleOnCustomerLevelChanged}
              >
                <FormControlLabel
                  value={CustomerLevel.Customer}
                  control={<Radio size="small" />}
                  slotProps={{ typography: { variant: "body2" } }}
                  label={t("Common.Customer")}
                />
                <FormControlLabel
                  value={CustomerLevel.CustomerProduct}
                  control={<Radio size="small" />}
                  slotProps={{ typography: { variant: "body2" } }}
                  label={t("Common.CustomerProduct")}
                />
              </RadioGroup>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Divider sx={{ marginTop: 1 }} />
      <Drawer
        anchor="right"
        variant="temporary"
        ModalProps={{ keepMounted: true }}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
      >
        <DashboardFilter onClose={() => setOpenDrawer(false)} />
      </Drawer>
    </>
  );
}
