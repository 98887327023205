import Table from "components/Table";
import { Column } from "components/Table/models";
import { CategoriesAndPeriodsTableModel } from "models/report";

interface Props {
  subRows: { [categories: string]: CategoriesAndPeriodsTableModel[] };
  tableData?: CategoriesAndPeriodsTableModel[];
  columns?: Column<CategoriesAndPeriodsTableModel>[];
}

export default function RollForwardTable(props: Props) {
  const { subRows, columns, tableData } = props;

  return (
    <Table
      data={tableData ?? []}
      columns={columns ?? []}
      rowKey="category"
      bodySx={{ zIndex: 0, position: "relative" }}
      footerSx={{
        bottom: 0,
        position: "sticky",
        backgroundColor: "white",
      }}
      stickyHeader
      subRows={subRows}
      alternateRowColors={false}
      alternateColumnColors={true}
    />
  );
}
