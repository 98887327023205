import { Box } from "@mui/material";
import GradientCard from "components/Card/GradientCard";
import ContractRenewalDetailTable from "containers/Dashboard/charts/ContractRenewalDetailTable";
import {
  ContractRenewalCategoryModel,
  ContractRenewalCategoryResponse,
  GrossNetRenewalRateModel,
} from "models/report";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ChartData,
  ReportData,
  ReportSettings,
} from "slices/models/reportSliceModel";
import { exportToExcel } from "utils/exportExcelFile/exportExcel";
import moment from "moment";
import { UNIT } from "utils/exportExcelFile/constants";
import {
  ContractRenewalChartSettings,
  generateFilterSheet,
} from "utils/exportExcelFile/generateFilterSheet";
import {
  getContractRenewalGrossNetRateTableColumns,
  getContractRenewalGrossNetRateTableData,
} from "utils/chartUtils/contractRenewal";
import ContractRenewalGrossNetRateChart from "containers/Dashboard/charts/ContractRenewalGrossNetRateChart";
import { ChartDatum } from "components/Charts/model";

import { WrapperContainer } from "../CommonComponents";

interface ContractRenewalGrossNetRateProps {
  reportSettings: ReportSettings;
  reportData: ReportData;
  chartData: ChartData<ContractRenewalCategoryResponse[]>;
  displayableReportData: ContractRenewalCategoryModel[];
  chartSettings: ContractRenewalChartSettings;
}

export function ContractRenewalGrossNetRate(
  props: ContractRenewalGrossNetRateProps
) {
  const {
    reportSettings,
    reportData,
    chartData,
    displayableReportData,
    chartSettings,
  } = props;
  const { t } = useTranslation();
  const [viewToggle, setViewToggle] = useState(["chart"]);

  const columns = useMemo(
    () => getContractRenewalGrossNetRateTableColumns(displayableReportData),
    [displayableReportData]
  );

  const tableData = useMemo(
    () => getContractRenewalGrossNetRateTableData(displayableReportData),
    [displayableReportData]
  );

  const legends: ChartDatum<GrossNetRenewalRateModel>[] = useMemo(() => {
    const selectedKeys = ["Gross Renewal Rate", "Net Renewal Rate"];

    const colorMapping: Record<string, string> = {
      "Gross Renewal Rate": "rgba(0, 58, 94, 1)",
      "Net Renewal Rate": "rgba(3, 169, 244, 1)",
    };

    return selectedKeys.map((key) => ({
      key,
      color: colorMapping[key],
    }));
  }, [reportData, reportSettings.filters]);

  const displayData: GrossNetRenewalRateModel[] = useMemo(() => {
    return displayableReportData.map((item, index) => ({
      xValue: item.xValue,
      index,
      "Gross Renewal Rate": item["Gross Renewal Rate"] || 0,
      "Net Renewal Rate": item["Net Renewal Rate"] || 0,
    }));
  }, [
    chartData.isLoading,
    reportSettings.measurement,
    reportSettings.filters.minDate,
    reportSettings.filters.maxDate,
    reportSettings.params.customerLevel,
  ]);

  const handleExport = () => {
    const categories = tableData.map((item) => item.category);

    const headerData = [
      "",
      ...displayableReportData.map((row) =>
        moment(row.xValue, "YYYY-MM", true).isValid()
          ? moment(row.xValue).format("YYYY-MM")
          : row.xValue
      ),
    ];

    const sheet2 = generateFilterSheet(
      reportSettings,
      reportData,
      t,
      chartSettings
    );
    const sheet1 = {
      sheetName: "Gross & Net Renewal Rate",
      header: headerData,
      categories,
      data: displayData || [],
      subData: {},
      option: {
        unitData: UNIT.PERCENT,
      },
    };
    exportToExcel(
      [sheet1, sheet2],
      `${moment().format("YYYY-MM-DD")}_${t(
        "Dashboard.Charts.SectionGrossNetRenewalRate"
      )}`
    );
  };

  return (
    <GradientCard
      title={t("Dashboard.Charts.SectionGrossNetRenewalRate")}
      onViewToggle={(x) => setViewToggle(x)}
      onMoreClick={() => console.log("more")}
      onExport={handleExport}
      isDisabledActions={
        reportData.isLoading ||
        chartData.isLoading ||
        !!chartData.error ||
        !!reportData.error
      }
    >
      <WrapperContainer
        isLoading={reportData.isLoading || chartData.isLoading}
        error={chartData.error || reportData.error}
        isNoData={displayableReportData.length === 0}
      >
        {viewToggle.includes("chart") && (
          <ContractRenewalGrossNetRateChart
            isLoading={reportData.isLoading || chartData.isLoading}
            error={reportData.error || chartData.error}
            legends={legends}
            displayData={displayData}
          />
        )}
        {viewToggle.includes("data") && (
          <Box sx={{ marginTop: 3, width: "100%" }}>
            <ContractRenewalDetailTable
              columns={columns}
              tableData={tableData}
            />
          </Box>
        )}
      </WrapperContainer>
    </GradientCard>
  );
}
