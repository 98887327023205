import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid2";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import {
  Chart,
  CustomerLevel,
  ReportMeasurement,
  ReportView,
} from "common/constants";
import { useChartSettings, useReportSelector } from "common/store";
import GradientCard from "components/Card/GradientCard";
import { ChartDatum } from "components/Charts/model";
import { OverviewChartData, OverviewChartModel } from "models/report";
import { useMemo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getRevenueByPeriodChart } from "services/reportService";
import { setChartSettings, setChartShouldFetch } from "slices/reportSlice";
import {
  computeChurnOnlyGrossRetentionChartData,
  computeGrossRetentionChartData,
  computeGrowthRateChartData,
  computeNetRetentionChartData,
  computeRevenueChartData,
  getNextMonth,
  getPreviousMonth,
} from "utils/chartUtils/overviewCharts";
import useDidUpdateEffect from "utils/hook/useDidUpdateEffect";
import {
  generateBarLegends,
  getSourceColumnName,
  hasCustSegMapped,
  hasProdSegMapped,
  separateIncompletePeriods,
  getIncompletePeriodLabel,
  filterDataByDate,
} from "utils/report";
import lodashGroupBy from "lodash/groupBy";
import { formatCurrencyValue, formatPercentValue } from "utils/format";
import moment from "moment";
import { generateFilterSheet } from "utils/exportExcelFile/generateFilterSheet";
import { exportToExcel } from "utils/exportExcelFile/exportExcel";
import { UNIT } from "utils/exportExcelFile/constants";
import lodashOrderBy from "lodash/orderBy";

import PercentLineChart from "../charts/Overview/PercentLineChart";
import RevenueAreaChart from "../charts/Overview/RevenueAreaChart";
import OverviewDataTable from "../charts/Overview/OverviewDataTable";
import { getOverviewTableData } from "../charts/Overview/utils";

const retentionTitles: Record<string, Record<string, string>> = {
  "1": {
    true: "Dashboard.Charts.GrossRetentionByTitle",
    false: "Dashboard.Charts.GrossRetentionTitle",
  },
  "2": {
    true: "Dashboard.Charts.ChurnOnlyGrossRetentionByTitle",
    false: "Dashboard.Charts.ChurnOnlyGrossRetentionTitle",
  },
};

export default function SectionOverview() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { reportData, reportSettings } = useReportSelector();
  const { filters: reportFilters, params: reportParams } = reportSettings;
  const chartSettings = useChartSettings("overview");
  const chartData = useReportSelector().chartData.overview;
  const [selectedRetention, setSelectedRetention] = useState("1");
  const [revenueViews, setRevenueViews] = useState(["chart"]);
  const [growthRateViews, setGrowthRateViews] = useState(["chart"]);
  const [grossRetentionViews, setGrossRetentionViews] = useState(["chart"]);
  const [netRetentionViews, setNetRetentionViews] = useState(["chart"]);

  const isMappedCustSeg = useMemo(
    () => hasCustSegMapped(reportData.mapping),
    [reportData.mapping]
  );

  const isMappedProdSeg = useMemo(
    () => hasProdSegMapped(reportData.mapping),
    [reportData.mapping]
  );

  const isCustomerLevel = useMemo(
    () => reportParams.customerLevel === CustomerLevel.Customer,
    [reportParams.customerLevel]
  );

  const segmentSelects = useMemo(() => {
    if (!reportData.mapping) return [];

    let segmentFields: string[] = [];
    if (chartSettings.segView === ReportView.CustomerType) {
      segmentFields = Object.keys(reportData.mapping).filter((x) =>
        x.startsWith("customerseg")
      );
    }
    if (chartSettings.segView === ReportView.ProductType) {
      segmentFields = Object.keys(reportData.mapping).filter((x) =>
        x.startsWith("product")
      );
    }
    const result = segmentFields
      .map((x) => ({
        key: x,
        value: reportData.mapping![x],
      }))
      .filter((x) => x.value);

    return result;
  }, [chartSettings.segView, reportData.mapping]);

  const selectedSegment = useMemo(() => {
    return getSourceColumnName(reportData.mapping, chartSettings.segKey || "");
  }, [reportData.mapping, chartSettings.segKey]);

  const legends: ChartDatum<OverviewChartModel>[] = useMemo(() => {
    let segViewName: "customer" | "product" | null = null;
    if (chartSettings.segView === ReportView.CustomerType)
      segViewName = "customer";
    if (chartSettings.segView === ReportView.ProductType)
      segViewName = "product";
    return generateBarLegends(
      segViewName,
      chartSettings.segKey,
      reportData,
      reportFilters
    );
  }, [chartSettings.segView, chartSettings.segKey, reportData, reportFilters]);

  const segmentItems = useMemo(() => {
    return legends.map((item) => item.key);
  }, [legends]);

  const displayData = useMemo(() => {
    const filteredByDateRange = filterDataByDate(
      chartData.data,
      reportFilters.minDate,
      reportFilters.maxDate
    );

    if (!filteredByDateRange) {
      return {
        [Chart.OverviewRevenue]: [],
        [Chart.OverviewGrowthRate]: [],
        [Chart.OverviewGrossRet]: [],
        [Chart.OverviewNetRet]: [],
        [Chart.OverviewChurnOnlyGrossRet]: [],
      };
    }
    const { complete, incomplete } = separateIncompletePeriods(
      filteredByDateRange,
      reportSettings.viewBy
    );
    const completePeriodMonths = lodashGroupBy(complete, (item) => item.Month);
    let incompletePeriodMonths: OverviewChartData[] = [];

    if (reportSettings.viewBy) {
      const periodKey =
        reportSettings.viewBy === "LTM" || reportSettings.viewBy === "YTD"
          ? "FISCALYR"
          : "DISPLAYQTR";
      incompletePeriodMonths = incomplete.map(
        (p) =>
          ({
            ...p,
            [periodKey]: getIncompletePeriodLabel(p, reportSettings.viewBy!),
          } as OverviewChartData)
      );
    }

    if (
      Object.keys(completePeriodMonths).length === 1 &&
      incompletePeriodMonths.length === 0
    ) {
      const month = Object.keys(completePeriodMonths)[0];
      const previousMonth = getPreviousMonth(month);
      const nextMonth = getNextMonth(month);

      if (!completePeriodMonths[previousMonth]) {
        completePeriodMonths[previousMonth] = [];
      }
      if (!completePeriodMonths[nextMonth]) {
        completePeriodMonths[nextMonth] = [];
      }

      for (const segment of Object.values(completePeriodMonths[month])) {
        completePeriodMonths[previousMonth].push({
          Month: previousMonth,
          SelectedSegmentation: segment.SelectedSegmentation,
          ArrEndingBalance: 0,
          ArrGrossRetention: 0,
          ArrNetRetention: 0,
          ArrGrowthRate: 0,
          ArrChurnOnlyGrossRetention: 0,
          CArrEndingBalance: 0,
          CArrGrossRetention: 0,
          CArrNetRetention: 0,
          CArrGrowthRate: 0,
          CArrChurnOnlyGrossRetention: 0,
          DISPLAYQTR: "", // TODO: How to calculate prev fiscal quarter?
          FISCALYR: "", // TODO: How to calculate prev fiscal year?
        });
        completePeriodMonths[nextMonth].push({
          Month: nextMonth,
          SelectedSegmentation: segment.SelectedSegmentation,
          ArrEndingBalance: 0,
          ArrGrossRetention: 0,
          ArrNetRetention: 0,
          ArrGrowthRate: 0,
          ArrChurnOnlyGrossRetention: 0,
          CArrEndingBalance: 0,
          CArrGrossRetention: 0,
          CArrNetRetention: 0,
          CArrGrowthRate: 0,
          CArrChurnOnlyGrossRetention: 0,
          DISPLAYQTR: "", // TODO: How to calculate next fiscal quarter?
          FISCALYR: "", // TODO: How to calculate next fiscal year?
        });
      }
    }
    let monthEntries = lodashOrderBy(
      Object.entries(completePeriodMonths),
      (m) => m[0]
    );

    if (incompletePeriodMonths.length > 0) {
      const groupedByMonth = lodashGroupBy(
        incompletePeriodMonths,
        (item) => item.Month
      );
      monthEntries = [
        ...monthEntries,
        ...lodashOrderBy(Object.entries(groupedByMonth), (m) => m[0]),
      ];
    }

    return {
      [Chart.OverviewRevenue]: computeRevenueChartData(
        monthEntries,
        reportSettings,
        chartSettings.segView
      ),
      [Chart.OverviewGrowthRate]: computeGrowthRateChartData(
        monthEntries,
        reportSettings,
        chartSettings.segView
      ),
      [Chart.OverviewGrossRet]: computeGrossRetentionChartData(
        monthEntries,
        reportSettings,
        chartSettings.segView
      ),
      [Chart.OverviewNetRet]: computeNetRetentionChartData(
        monthEntries,
        reportSettings,
        chartSettings.segView
      ),
      [Chart.OverviewChurnOnlyGrossRet]:
        computeChurnOnlyGrossRetentionChartData(
          monthEntries,
          reportSettings,
          chartSettings.segView
        ),
    };
  }, [
    chartData.data,
    reportSettings.measurement,
    reportFilters.minDate,
    reportFilters.maxDate,
    reportParams.revenueType,
    reportSettings.viewBy,
  ]);

  function handleOnViewToggleChanged(value: ReportView): void {
    if (value !== chartSettings.segView) {
      dispatch(setChartSettings({ overview: { segView: value } }));
    }
  }

  function handleOnSegmentKeyChanged(value: string | null): void {
    if (value !== chartSettings.segKey) {
      dispatch(
        setChartSettings({
          overview: { segView: chartSettings.segView, segKey: value },
        })
      );
    }
  }

  useDidUpdateEffect(() => {
    if (chartSettings.segView === undefined) return;

    const value = segmentSelects.find((x) => x.key === chartSettings.segKey);
    if (value?.key) {
      handleOnSegmentKeyChanged(value.key);
      return;
    }

    handleOnSegmentKeyChanged(
      segmentSelects.length > 0 ? segmentSelects[0].key : null
    );
  }, [segmentSelects]);

  useEffect(() => {
    if (
      !reportData.isLoading &&
      chartData.shouldFetch &&
      chartSettings.segKey !== undefined
    ) {
      dispatch(
        getRevenueByPeriodChart({
          view: chartSettings.segView,
          segmentKey: chartSettings.segKey,
        })
      );
    }
  }, [reportData.isLoading, chartData.shouldFetch, chartSettings.segKey]);

  useDidUpdateEffect(() => {
    if (reportParams.customerLevel === CustomerLevel.Customer) {
      dispatch(
        setChartSettings({
          overview: {
            segView: ReportView.Total,
          },
        })
      );
    }
  }, [reportParams.customerLevel]);

  useDidUpdateEffect(() => {
    dispatch(setChartShouldFetch(["overview"]));
  }, [
    chartSettings.segKey,
    reportFilters.customers,
    reportFilters.segmentCustomers,
    reportFilters.segmentProducts,
    reportParams.customerLevel,
  ]);

  const titleChartRet =
    retentionTitles[selectedRetention]?.[String(!!selectedSegment)] ||
    "Dashboard.Charts.GrossRetentionTitle";

  const retentionSelectOptions = [
    { label: t("Dashboard.Charts.GrossRetentionTitle"), value: "1" },
    {
      label: t("Dashboard.Charts.ChurnOnlyGrossRetentionTitle"),
      value: "2",
    },
  ];
  const selectedChartRet =
    selectedRetention === "1"
      ? Chart.OverviewGrossRet
      : Chart.OverviewChurnOnlyGrossRet;

  const handleExport = (
    chartNumber:
      | Chart.OverviewRevenue
      | Chart.OverviewGrowthRate
      | Chart.OverviewGrossRet
      | Chart.OverviewNetRet
      | Chart.OverviewChurnOnlyGrossRet,
    title: string
  ) => {
    const tableData = getOverviewTableData(
      displayData[chartNumber],
      segmentItems
    );

    const categories = tableData.map((item) => item.segmentItem);
    const headerData = [
      "",
      ...displayData[chartNumber].map((row) =>
        moment(row.xValue, "YYYY-MM", true).isValid()
          ? moment(row.xValue).format("YYYY-MM")
          : row.xValue
      ),
    ];
    const sheet2 = generateFilterSheet(
      reportSettings,
      reportData,
      t,
      chartSettings
    );
    const sheet1 = {
      sheetName: title.replaceAll(" ", "").slice(0, 31), // Limit sheetname 31 characters
      header: headerData,
      categories,
      data: displayData[chartNumber] || [],
      subData: {},
      option: {
        unitData:
          chartNumber !== Chart.OverviewRevenue ? UNIT.PERCENT : UNIT.THOUSANDS,
      },
    };

    exportToExcel(
      [sheet1, sheet2],
      `${moment().format("YYYY-MM-DD")}_${title}`
    );
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="flex-start"
        spacing={2}
        marginBottom={2}
      >
        <Typography variant="h6" marginBottom={2} color="var(--text-secondary)">
          {t("Dashboard.Charts.SectionGrowthRate", {
            view: ReportMeasurement[reportSettings.measurement],
          })}
        </Typography>
        <ToggleButtonGroup
          exclusive
          size="small"
          value={chartSettings.segView}
          onChange={(_, v) => v !== null && handleOnViewToggleChanged(v)}
        >
          <ToggleButton
            size="small"
            value={ReportView.CustomerType}
            disabled={!isMappedCustSeg}
          >
            {t("Common.CustSeg")}
          </ToggleButton>
          <ToggleButton
            size="small"
            value={ReportView.ProductType}
            disabled={!isMappedProdSeg || isCustomerLevel}
          >
            {t("Common.ProdSeg")}
          </ToggleButton>
          <ToggleButton size="small" value={ReportView.Extras} disabled>
            {t("Common.Extras")}
          </ToggleButton>
          <ToggleButton size="small" value={ReportView.Total}>
            {t("Common.Total")}
          </ToggleButton>
        </ToggleButtonGroup>
        {(isMappedCustSeg || isMappedProdSeg) &&
          chartSettings.segView !== ReportView.Total && (
            <FormControl size="small" sx={{ width: "220px" }}>
              <InputLabel>
                {chartSettings.segView === ReportView.CustomerType
                  ? t("Common.CustSeg")
                  : t("Common.ProdSeg")}
              </InputLabel>
              <Select
                MenuProps={{ disableScrollLock: true }}
                label={
                  chartSettings.segView === ReportView.CustomerType
                    ? t("Common.CustSeg")
                    : t("Common.ProdSeg")
                }
                value={chartSettings.segKey ?? ""}
                onChange={(e) => handleOnSegmentKeyChanged(e.target.value)}
              >
                {segmentSelects.map((x) => (
                  <MenuItem key={x.key} value={x.key}>
                    {x.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
      </Stack>
      <Grid container spacing={3} alignItems="stretch">
        <Grid size={6}>
          <GradientCard
            title={t(
              selectedSegment
                ? "Dashboard.Charts.RevenueByTitle"
                : "Dashboard.Charts.RevenueTitle",
              {
                view: ReportMeasurement[reportSettings.measurement],
                type: selectedSegment,
              }
            )}
            subheader={
              <Typography variant="body2" fontStyle="italic">
                {t("Dashboard.DollarsInThounsands")}
              </Typography>
            }
            onViewToggle={(x) => setRevenueViews(x)}
            sx={
              revenueViews.length === growthRateViews.length
                ? undefined
                : { height: "unset" }
            }
            onExport={() =>
              handleExport(
                Chart.OverviewRevenue,
                t(
                  selectedSegment
                    ? "Dashboard.Charts.RevenueByTitle"
                    : "Dashboard.Charts.RevenueTitle",
                  {
                    view: ReportMeasurement[reportSettings.measurement],
                    type: selectedSegment,
                  }
                )
              )
            }
            isDisabledActions={
              reportData.isLoading ||
              chartData.isLoading ||
              !!chartData.error ||
              !!reportData.error
            }
          >
            <RevenueAreaChart
              isLoading={reportData.isLoading || chartData.isLoading}
              error={reportData.error || chartData.error}
              legends={legends}
              displayData={displayData[Chart.OverviewRevenue]}
            />
            {revenueViews.includes("data") && (
              <OverviewDataTable
                chartData={displayData[Chart.OverviewRevenue]}
                segmentItems={segmentItems}
                segmentationView={chartSettings.segView}
                isLoading={reportData.isLoading || chartData.isLoading}
                formatValue={(value) => formatCurrencyValue(value || 0)}
              />
            )}
          </GradientCard>
        </Grid>
        <Grid size={6}>
          <GradientCard
            title={t(
              selectedSegment
                ? "Dashboard.Charts.GrowthRateByTitle"
                : "Dashboard.Charts.GrowthRateTitle",
              {
                type: selectedSegment,
              }
            )}
            onViewToggle={(x) => setGrowthRateViews(x)}
            sx={
              revenueViews.length === growthRateViews.length
                ? undefined
                : { height: "unset" }
            }
            // Add a hidden subheader so that it's align with the adjacent card
            subheader={
              <Typography variant="body2" visibility="hidden">
                Subheader
              </Typography>
            }
            onExport={() =>
              handleExport(
                Chart.OverviewGrowthRate,
                t(
                  selectedSegment
                    ? "Dashboard.Charts.GrowthRateByTitle"
                    : "Dashboard.Charts.GrowthRateTitle",
                  {
                    type: selectedSegment,
                  }
                )
              )
            }
            isDisabledActions={
              reportData.isLoading ||
              chartData.isLoading ||
              !!chartData.error ||
              !!reportData.error
            }
          >
            <PercentLineChart
              isLoading={reportData.isLoading || chartData.isLoading}
              error={reportData.error || chartData.error}
              legends={legends}
              displayData={displayData[Chart.OverviewGrowthRate]}
            />
            {growthRateViews.includes("data") && (
              <OverviewDataTable
                chartData={displayData[Chart.OverviewGrowthRate]}
                segmentItems={segmentItems}
                segmentationView={chartSettings.segView}
                isLoading={reportData.isLoading || chartData.isLoading}
                formatValue={formatPercentValue}
              />
            )}
          </GradientCard>
        </Grid>

        <Grid size={6}>
          <GradientCard
            title={t(titleChartRet, { type: selectedSegment })}
            selectProps={{
              onSelect: setSelectedRetention,
              selectValue: selectedRetention,
              selectOptions: retentionSelectOptions,
            }}
            onViewToggle={(x) => setGrossRetentionViews(x)}
            sx={
              grossRetentionViews.length === netRetentionViews.length
                ? undefined
                : { height: "unset" }
            }
            onExport={() =>
              handleExport(
                selectedChartRet,
                t(titleChartRet, { type: selectedSegment })
              )
            }
            isDisabledActions={
              reportData.isLoading ||
              chartData.isLoading ||
              !!chartData.error ||
              !!reportData.error
            }
          >
            <PercentLineChart
              isLoading={reportData.isLoading || chartData.isLoading}
              error={reportData.error || chartData.error}
              legends={legends}
              displayData={displayData[selectedChartRet]}
            />
            {grossRetentionViews.includes("data") && (
              <OverviewDataTable
                chartData={displayData[selectedChartRet]}
                segmentItems={segmentItems}
                segmentationView={chartSettings.segView}
                isLoading={reportData.isLoading || chartData.isLoading}
                formatValue={formatPercentValue}
              />
            )}
          </GradientCard>
        </Grid>
        <Grid size={6}>
          <GradientCard
            title={t(
              selectedSegment
                ? "Dashboard.Charts.NetRetentionByTitle"
                : "Dashboard.Charts.NetRetentionTitle",
              {
                type: selectedSegment,
              }
            )}
            onViewToggle={(x) => setNetRetentionViews(x)}
            sx={
              grossRetentionViews.length === netRetentionViews.length
                ? undefined
                : { height: "unset" }
            }
            onExport={() =>
              handleExport(
                Chart.OverviewNetRet,
                t(
                  selectedSegment
                    ? "Dashboard.Charts.NetRetentionByTitle"
                    : "Dashboard.Charts.NetRetentionTitle",
                  {
                    type: selectedSegment,
                  }
                )
              )
            }
            isDisabledActions={
              reportData.isLoading ||
              chartData.isLoading ||
              !!chartData.error ||
              !!reportData.error
            }
          >
            <PercentLineChart
              isLoading={reportData.isLoading || chartData.isLoading}
              error={reportData.error || chartData.error}
              legends={legends}
              displayData={displayData[Chart.OverviewNetRet]}
            />
            {netRetentionViews.includes("data") && (
              <OverviewDataTable
                chartData={displayData[Chart.OverviewNetRet]}
                segmentItems={segmentItems}
                segmentationView={chartSettings.segView}
                isLoading={reportData.isLoading || chartData.isLoading}
                formatValue={formatPercentValue}
              />
            )}
          </GradientCard>
        </Grid>
      </Grid>
    </>
  );
}
