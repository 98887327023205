import { Outlet } from "react-router-dom";

import { DataMappingContextProvider } from "./DataMapping/DataMappingContext";

export default function WorkspaceContextProvider() {
  return (
    <DataMappingContextProvider>
      <Outlet />
    </DataMappingContextProvider>
  );
}
