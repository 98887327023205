import { Box, Grid2 } from "@mui/material";
import moment from "moment";
import { useDispatch, useReportSelector } from "common/store";
import DatePicker from "components/Inputs/DatePicker";
import { useTranslation } from "react-i18next";
import {
  getDatePickerLabel,
  getDatePickerType,
  getFiscalMonth,
} from "utils/report";
import { useFetcher } from "react-router-dom";
import { useEffect, useState } from "react";
import { setReportSettings } from "slices/reportSlice";
import { getLastDatePeriod } from "utils/globalSettingsUtils";

export type GlobalTimeRangeProps = {
  currentMinDate?: string;
  currentMaxDate?: string;
  defaultMinDate?: string;
  defaultMaxDate?: string;
};
export default function GlobalTimeRange({
  currentMaxDate,
  currentMinDate,
  defaultMaxDate,
  defaultMinDate,
}: GlobalTimeRangeProps) {
  const fetcher = useFetcher();
  const dispatch = useDispatch();
  const [selectedFilter, setSelectedFilter] = useState({
    minDate: currentMinDate || "",
    maxDate: currentMaxDate || "",
  });
  const { reportData, reportSettings } = useReportSelector();
  const { t } = useTranslation();

  function resetAllChartsBaseOnDateRangeSetting(): void {
    const isDateRangeCollapsed =
      currentMinDate &&
      currentMaxDate &&
      selectedFilter.minDate >= currentMinDate &&
      selectedFilter.maxDate <= currentMaxDate;

    dispatch(
      setReportSettings({
        isResetDataAllCharts: !isDateRangeCollapsed,
      })
    );
  }

  useEffect(() => {
    resetAllChartsBaseOnDateRangeSetting();
  }, [selectedFilter.minDate, selectedFilter.maxDate]);

  function handleOnStartDateChanged(date: moment.Moment | null): void {
    if (!date) return;
    const lastDate = getLastDatePeriod(
      date,
      reportSettings.params.revenueType,
      defaultMinDate,
      defaultMaxDate
    );

    const lastDateStr = lastDate?.format("YYYY-MM-DD");
    if (!lastDateStr) return;
    const newMinDate =
      lastDateStr > selectedFilter.maxDate!
        ? selectedFilter.maxDate || ""
        : lastDateStr;

    setSelectedFilter((y) => ({
      ...y,
      minDate: newMinDate,
    }));
    fetcher.submit(
      {
        name: "TimeRangeMin",
        value: newMinDate,
      },
      {
        method: "put",
      }
    );
    dispatch(
      setReportSettings({
        filters: {
          ...reportSettings.filters,
          minDate: newMinDate,
        },
      })
    );
  }

  function handleOnEndDateChanged(date: moment.Moment | null): void {
    if (!date) return;
    const lastDate = getLastDatePeriod(
      date,
      reportSettings.params.revenueType,
      defaultMinDate,
      defaultMaxDate
    );
    const lastDateStr = lastDate?.format("YYYY-MM-DD");
    if (!lastDateStr) return;

    const newMaxDate =
      lastDateStr < selectedFilter.minDate!
        ? selectedFilter.minDate || ""
        : lastDateStr;

    setSelectedFilter((y) => ({
      ...y,
      maxDate: newMaxDate,
    }));
    fetcher.submit(
      {
        name: "TimeRangeMax",
        value: newMaxDate,
      },
      {
        method: "put",
      }
    );
    dispatch(
      setReportSettings({
        filters: {
          ...reportSettings.filters,
          maxDate: newMaxDate,
        },
      })
    );
  }

  return (
    <Grid2 container spacing={3}>
      <Box display="flex" alignItems="center">
        <DatePicker
          type={getDatePickerType(reportSettings.params.revenueType)}
          label={t("Dashboard.Filters.StartDate", {
            date: getDatePickerLabel(reportSettings.params.revenueType),
          })}
          value={moment(selectedFilter.minDate)}
          fiscalMonth={getFiscalMonth(
            reportData.mapping,
            reportSettings.params.revenueType
          )}
          onChange={handleOnStartDateChanged}
          minDate={moment(defaultMinDate)}
          maxDate={moment(selectedFilter.maxDate)}
        />
        <Box paddingX={2}>—</Box>
        <DatePicker
          type={getDatePickerType(reportSettings.params.revenueType)}
          label={t("Dashboard.Filters.EndDate", {
            date: getDatePickerLabel(reportSettings.params.revenueType),
          })}
          value={moment(selectedFilter.maxDate)}
          fiscalMonth={getFiscalMonth(
            reportData.mapping,
            reportSettings.params.revenueType
          )}
          onChange={handleOnEndDateChanged}
          minDate={moment(selectedFilter.minDate)}
          maxDate={moment(defaultMaxDate)}
        />
      </Box>
    </Grid2>
  );
}
