import { ExpandLessOutlined, ExpandMoreOutlined } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useReportSelector } from "common/store";
import { KeyValue } from "models/common";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getSourceColumnName } from "utils/report";

interface ChipItem {
  type: "customers" | "segmentProducts" | "segmentCustomers";
  segment?: string | undefined;
  key: string;
  label: string;
  item: KeyValue;
}

function toChipItemsFromCustomers(
  mapping: Record<string, string | null> | null | undefined,
  data: KeyValue[]
): ChipItem[] {
  return data.map((x) => ({
    type: "customers",
    item: x,
    key: `customer-${x.key}`,
    label: `${getSourceColumnName(mapping, "customer")}: ${x.value}`,
  }));
}

function toChipItemsFromSegment(
  type: "customers" | "segmentProducts" | "segmentCustomers",
  mapping: Record<string, string | null> | null | undefined,
  data: { [segment: string]: KeyValue[] }
): ChipItem[] {
  return Object.keys(data).flatMap((x) =>
    data[x].map((y) => ({
      type,
      item: y,
      segment: x,
      key: `${x}-${y.key}`,
      label: `${getSourceColumnName(mapping, x)}: ${y.value}`,
    }))
  );
}

const maxMoreChips = 10;

export default function DashboardToolbarChips() {
  const { t } = useTranslation();

  const [shown, setShown] = useState(false);
  const [moreChips, setMoreChips] = useState(0);

  const boxRef = useRef<HTMLDivElement | null>(null);
  // eslint-disable-next-line unicorn/new-for-builtins
  const chipRefs = useRef<Array<HTMLDivElement | null>>(Array(10));

  const { reportData, reportSettings } = useReportSelector();

  const chips = useMemo(() => {
    if (reportData.isLoading) {
      return [];
    }
    const mapping = reportData.mapping;
    const { customers, segmentProducts, segmentCustomers } =
      reportSettings.filters;
    return [
      ...toChipItemsFromCustomers(mapping, customers),
      ...toChipItemsFromSegment("segmentCustomers", mapping, segmentCustomers),
      ...toChipItemsFromSegment("segmentProducts", mapping, segmentProducts),
    ];
  }, [
    reportData.isLoading,
    reportData.mapping,
    reportSettings.filters.customers,
    reportSettings.filters.segmentCustomers,
    reportSettings.filters.segmentProducts,
  ]);

  useEffect(() => {
    let totalWidth = 0;
    for (let index = 0; index < chipRefs.current.length; index++) {
      const element = chipRefs.current[index];
      totalWidth += (element?.offsetWidth ?? 0) + 8; // 8 is the margin between chips
      if (totalWidth > (boxRef.current?.offsetWidth ?? 0)) {
        setMoreChips(chips.length - index);
        return;
      }
      setMoreChips(0);
    }
  }, [chipRefs, boxRef, chips]);

  return (
    <Stack direction="row" alignItems="start" flex={1}>
      <Stack gap={2} direction="row">
        <Typography variant="body2" marginRight={1}>
          {t("Dashboard.ToolBar.AppliedFilters")}
        </Typography>
      </Stack>
      {chips.length > 0 && (
        <>
          <Divider orientation="vertical" flexItem sx={{ margin: 1 }} />
          <Box flex={1}>
            <Stack direction="row" alignItems="center">
              <Box ref={boxRef} flex={1} overflow="hidden" height={26}>
                {chips.slice(0, 10).map((x, i) => (
                  <Chip
                    key={x.key}
                    label={x.label}
                    ref={(el) => (chipRefs.current[i] = el)}
                    size="small"
                    variant="outlined"
                    sx={{ marginRight: 1 }}
                  />
                ))}
              </Box>
              <Button
                sx={{
                  visibility: moreChips <= 0 ? "hidden" : "visible",
                  width: "140px",
                }}
                size="small"
                variant="text"
                color="primary"
                onClick={() => setShown((x) => !x)}
                endIcon={
                  shown ? <ExpandLessOutlined /> : <ExpandMoreOutlined />
                }
              >
                {shown ? "Show Less" : "Show More"}
              </Button>
            </Stack>
            <Collapse in={shown}>
              {chips
                .slice(
                  chips.length - moreChips,
                  chips.length - moreChips + maxMoreChips
                )
                .map((x) => (
                  <Chip
                    key={x.key}
                    label={x.label}
                    size="small"
                    variant="outlined"
                    sx={{ marginRight: 1, marginBottom: 1 }}
                  />
                ))}
              {moreChips - maxMoreChips > 0 && (
                <Tooltip
                  title={chips
                    .slice(chips.length - moreChips + 10)
                    .map((x) => x.label)
                    .join(", ")}
                >
                  <Chip
                    size="small"
                    label={`+${moreChips - maxMoreChips}`}
                    variant="outlined"
                    sx={{ marginRight: 1, marginBottom: 1 }}
                  />
                </Tooltip>
              )}
            </Collapse>
          </Box>
        </>
      )}
    </Stack>
  );
}
