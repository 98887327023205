import {
  Chart,
  ReportMeasurement,
  ReportView,
  REVENUE_TYPE_PROPERTY_MAPPING,
} from "common/constants";
import lodash from "lodash";
import { OverviewChartData, OverviewChartModel } from "models/report";
import { ReportSettings } from "slices/models/reportSliceModel";

const MEASUREMENT_PROPERTIES: {
  [key: string]: keyof OverviewChartData;
} = {
  [`${ReportMeasurement.ARR}${Chart.OverviewRevenue}`]: "ArrEndingBalance",
  [`${ReportMeasurement.ARR}${Chart.OverviewGrowthRate}`]: "ArrGrowthRate",
  [`${ReportMeasurement.ARR}${Chart.OverviewGrossRet}`]: "ArrGrossRetention",
  [`${ReportMeasurement.ARR}${Chart.OverviewNetRet}`]: "ArrNetRetention",
  [`${ReportMeasurement.ARR}${Chart.OverviewChurnOnlyGrossRet}`]:
    "ArrChurnOnlyGrossRetention",
  [`${ReportMeasurement.CARR}${Chart.OverviewRevenue}`]: "CArrEndingBalance",
  [`${ReportMeasurement.CARR}${Chart.OverviewGrowthRate}`]: "CArrGrowthRate",
  [`${ReportMeasurement.CARR}${Chart.OverviewGrossRet}`]: "CArrGrossRetention",
  [`${ReportMeasurement.CARR}${Chart.OverviewNetRet}`]: "CArrNetRetention",
  [`${ReportMeasurement.CARR}${Chart.OverviewChurnOnlyGrossRet}`]:
    "CArrChurnOnlyGrossRetention",
  [`${ReportMeasurement.MRR}${Chart.OverviewRevenue}`]: "ArrEndingBalance",
  [`${ReportMeasurement.MRR}${Chart.OverviewGrowthRate}`]: "ArrGrowthRate",
  [`${ReportMeasurement.MRR}${Chart.OverviewGrossRet}`]: "ArrGrossRetention",
  [`${ReportMeasurement.MRR}${Chart.OverviewNetRet}`]: "ArrNetRetention",
  [`${ReportMeasurement.MRR}${Chart.OverviewChurnOnlyGrossRet}`]:
    "ArrChurnOnlyGrossRetention",
  [`${ReportMeasurement.CMRR}${Chart.OverviewRevenue}`]: "CArrEndingBalance",
  [`${ReportMeasurement.CMRR}${Chart.OverviewGrowthRate}`]: "CArrGrowthRate",
  [`${ReportMeasurement.CMRR}${Chart.OverviewGrossRet}`]: "CArrGrossRetention",
  [`${ReportMeasurement.CMRR}${Chart.OverviewNetRet}`]: "CArrNetRetention",
  [`${ReportMeasurement.CMRR}${Chart.OverviewChurnOnlyGrossRet}`]:
    "CArrChurnOnlyGrossRetention",
  [`${ReportMeasurement.QRR}${Chart.OverviewRevenue}`]: "ArrEndingBalance",
  [`${ReportMeasurement.QRR}${Chart.OverviewGrowthRate}`]: "ArrGrowthRate",
  [`${ReportMeasurement.QRR}${Chart.OverviewGrossRet}`]: "ArrGrossRetention",
  [`${ReportMeasurement.QRR}${Chart.OverviewNetRet}`]: "ArrNetRetention",
  [`${ReportMeasurement.QRR}${Chart.OverviewChurnOnlyGrossRet}`]:
    "ArrChurnOnlyGrossRetention",
  [`${ReportMeasurement.CQRR}${Chart.OverviewRevenue}`]: "CArrEndingBalance",
  [`${ReportMeasurement.CQRR}${Chart.OverviewGrowthRate}`]: "CArrGrowthRate",
  [`${ReportMeasurement.CQRR}${Chart.OverviewGrossRet}`]: "CArrGrossRetention",
  [`${ReportMeasurement.CQRR}${Chart.OverviewNetRet}`]: "CArrNetRetention",
  [`${ReportMeasurement.CQRR}${Chart.OverviewChurnOnlyGrossRet}`]:
    "CArrChurnOnlyGrossRetention",
};

export function getPreviousMonth(period: string) {
  const [year, month] = period.split("-");
  if (month === "01") {
    return `${Number.parseInt(year) - 1}-12`;
  }
  return `${year}-${(Number.parseInt(month) - 1).toString().padStart(2, "0")}`;
}

export function getNextMonth(period: string) {
  const [year, month] = period.split("-");
  if (month === "12") {
    return `${Number.parseInt(year) + 1}-01`;
  }
  return `${year}-${(Number.parseInt(month) + 1).toString().padStart(2, "0")}`;
}

function getValueByMeasurement(
  data: OverviewChartData,
  measurement: ReportMeasurement,
  chart: Chart
): number {
  const propertyKey = MEASUREMENT_PROPERTIES[`${measurement}${chart}`];

  if (!propertyKey) return 0;

  let value = data[propertyKey];

  if (typeof value === "string") {
    const parsed = Number(value);
    if (!Number.isNaN(parsed)) {
      value = parsed;
    } else {
      return 0;
    }
  }
  if (typeof value !== "number") {
    return 0;
  }
  let devisors = 1;
  if (chart === Chart.OverviewRevenue) {
    if (
      measurement === ReportMeasurement.MRR ||
      measurement === ReportMeasurement.CMRR
    ) {
      devisors = 12;
    }
    if (
      measurement === ReportMeasurement.QRR ||
      measurement === ReportMeasurement.CQRR
    ) {
      devisors = 4;
    }
    return value / devisors;
  }

  return value;
}

export function filterDateRange(
  data: OverviewChartData[],
  reportSettings: ReportSettings
) {
  const minDate = reportSettings.filters.minDate!.slice(0, -3);
  const maxDate = reportSettings.filters.maxDate!.slice(0, -3);

  const startIndex = lodash.findIndex(data, (x) => x.Month >= minDate);
  const endIndex = lodash.findLastIndex(data, (x) => x.Month <= maxDate);

  if (startIndex === -1 || endIndex === -1) return [];

  return data.slice(startIndex, endIndex + 1);
}

function buildChartModels(
  months: [string, OverviewChartData[]][],
  reportSettings: ReportSettings,
  chartType: Chart,
  segView?: ReportView | null
): OverviewChartModel[] {
  if (segView === ReportView.Total) segView = null;

  return months.map(([month, values], index) => {
    const xKey = REVENUE_TYPE_PROPERTY_MAPPING[
      reportSettings.params.revenueType
    ] as keyof OverviewChartData;
    const xValue = values[0][xKey];

    const model: OverviewChartModel = {
      month,
      index,
      xValue,
    } as OverviewChartModel;

    for (const value of values) {
      const name = segView === null ? "Total" : value.SelectedSegmentation!;
      model[name] = getValueByMeasurement(
        value,
        reportSettings.measurement,
        chartType
      );
    }
    return model;
  });
}

export function computeRevenueChartData(
  months: [string, OverviewChartData[]][],
  reportSettings: ReportSettings,
  segView?: ReportView | null
): OverviewChartModel[] {
  return buildChartModels(
    months,
    reportSettings,
    Chart.OverviewRevenue,
    segView
  );
}

export function computeGrowthRateChartData(
  months: [string, OverviewChartData[]][],
  reportSettings: ReportSettings,
  segView?: ReportView | null
): OverviewChartModel[] {
  return buildChartModels(
    months,
    reportSettings,
    Chart.OverviewGrowthRate,
    segView
  );
}

export function computeGrossRetentionChartData(
  months: [string, OverviewChartData[]][],
  reportSettings: ReportSettings,
  segView?: ReportView | null
): OverviewChartModel[] {
  return buildChartModels(
    months,
    reportSettings,
    Chart.OverviewGrossRet,
    segView
  );
}

export function computeChurnOnlyGrossRetentionChartData(
  months: [string, OverviewChartData[]][],
  reportSettings: ReportSettings,
  segView?: ReportView | null
): OverviewChartModel[] {
  return buildChartModels(
    months,
    reportSettings,
    Chart.OverviewChurnOnlyGrossRet,
    segView
  );
}

export function computeNetRetentionChartData(
  months: [string, OverviewChartData[]][],
  reportSettings: ReportSettings,
  segView?: ReportView | null
): OverviewChartModel[] {
  return buildChartModels(
    months,
    reportSettings,
    Chart.OverviewNetRet,
    segView
  );
}
