import {
  AnalysisType,
  CustomerLevel,
  ReportMeasurement,
  ReportView,
  RevenueType,
  renewalWindows,
} from "common/constants";
import { KeyValue } from "models/common";
import { FileModel } from "models/fileModel";
import {
  Axis,
  ByValue,
  Show,
  RelativeTo,
  RevenueByChangeCategoryResponse,
  RevenueByChangeCategoryVolumeImpactResponse,
  OverviewChartData,
  CustomersByChangeCategoryResponse,
  RevenueByCustomer,
  ARRCohortReport,
  CARRCohortReport,
  ContractRenewalCategoryResponse,
  DashboardView,
} from "models/report";
import { GlobalSettings } from "models/globalSettings";

export interface GroupedSegmentData {
  segment: string;
  data: KeyValue[];
}

export interface ReportData {
  isLoading: boolean;
  error?: string;
  file?: FileModel;
  mapping?: Record<string, string | null>;
  customers?: KeyValue[];
  segmentCustomers?: GroupedSegmentData[];
  segmentProducts?: GroupedSegmentData[];
  minDate?: string;
  maxDate?: string;
  globalSettings?: GlobalSettings;
}

export interface ReportFilters {
  minDate?: string;
  maxDate?: string;
  defaultMinDate?: string;
  defaultMaxDate?: string;
  customers: KeyValue[];
  segmentCustomers: {
    [segment: string]: KeyValue[];
  };
  segmentProducts: {
    [segment: string]: KeyValue[];
  };
}

export interface ReportParams {
  customerLevel: CustomerLevel;
  reactivationThreshold: number;
  analysisType: AnalysisType;
  revenueType: RevenueType;
}

export interface ChartSettings {
  overview: {
    segView?: ReportView | null;
    segKey?: string | null;
  };
  "top-customers": {
    segView?: ReportView | null;
    segKey?: string | null;
    month?: string;
    topCustomers?: number;
  };
  "customer-cohorts": {
    period?: RevenueType;
    axis?: Axis;
    show?: Show;
    byValue?: ByValue;
    relativeTo?: RelativeTo;
    priorPeriodComparison?: AnalysisType;
    productType?: string;
  };
  "contract-renewal": {
    renewalWindow?: number;
  };
}

export interface ReportSettings {
  measurement: ReportMeasurement;
  metricType: "Live" | "Contracted";
  chartSettings: ChartSettings;
  filters: ReportFilters;
  params: ReportParams;
  isResetDataAllCharts?: boolean;
  viewBy?: DashboardView;
}

export interface ChartData<Data> {
  shouldFetch: boolean;
  isLoading: boolean;
  error?: string;
  data?: Data;
  currentRequestId?: string;
}

export interface ChartCacheData {
  "roll-forward": ChartData<RevenueByChangeCategoryResponse[]>;
  "roll-forward-impact-volume": ChartData<
    RevenueByChangeCategoryVolumeImpactResponse[]
  >;
  overview: ChartData<OverviewChartData[]>;
  "count-change": ChartData<CustomersByChangeCategoryResponse[]>;
  "contract-renewal": ChartData<ContractRenewalCategoryResponse[]>;
  "top-customers": ChartData<RevenueByCustomer[]>;
  "customer-cohorts": ChartData<(ARRCohortReport | CARRCohortReport)[]>;
}

export interface ReportState {
  /**
   * Current dashboard id is viewing
   */
  dashboardId: string;
  /**
   * The metadata of current viewing report
   */
  reportData: ReportData;
  /**
   * Cached computed data to show on chart
   */
  chartData: ChartCacheData;
  /**
   * Original report global settings per dashboard
   */
  originalSettings?: ReportSettings;
  /**
   * Current report global settings per dashboard
   */
  currentSettings: {
    [dashboardId: string]: ReportSettings;
  };
}

export const defaultReportData: ReportData = {
  isLoading: true,
};

export const defaultReportFilters: ReportFilters = {
  customers: [],
  segmentCustomers: {},
  segmentProducts: {},
};

export const defaultReportParams: ReportParams = {
  analysisType: AnalysisType.YoY,
  customerLevel: CustomerLevel.Customer,
  revenueType: RevenueType.Monthly,
  reactivationThreshold: 2,
};

export const defaultReportSettings: ReportSettings = {
  measurement: ReportMeasurement.ARR,
  metricType: "Live",
  chartSettings: {
    overview: { segView: ReportView.Total },
    "top-customers": { topCustomers: 25 },
    "customer-cohorts": {
      period: RevenueType.Monthly,
      axis: "cohort-tenure",
      show: "arr",
      byValue: "base",
      relativeTo: "initial",
      priorPeriodComparison: AnalysisType.YoY,
    },
    "contract-renewal": { renewalWindow: renewalWindows[0].key },
  },
  filters: defaultReportFilters,
  params: defaultReportParams,
  isResetDataAllCharts: false,
};

export const initialState: ReportState = {
  dashboardId: "",
  reportData: defaultReportData,
  chartData: {
    "roll-forward": { isLoading: true, shouldFetch: true },
    "roll-forward-impact-volume": { isLoading: true, shouldFetch: true },
    overview: { isLoading: true, shouldFetch: true },
    "count-change": { isLoading: true, shouldFetch: true },
    "contract-renewal": { isLoading: true, shouldFetch: true },
    "top-customers": { isLoading: true, shouldFetch: true },
    "customer-cohorts": { isLoading: true, shouldFetch: true },
  },
  originalSettings: undefined,
  currentSettings: {},
};
