import { Stepper, Step, StepLabel, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { ROUTE_PATHS } from "common/constants";

import { WORKSPACE_ROUTES, workspaceConfigs } from "../../config";

const stepperStyles = {
  display: "flex",
  width: "984px",
  padding: "0px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  "& .MuiStepIcon-text": {
    fill: "#FFF",
  },
  "& .MuiStepLabel-root": {
    maxWidth: "100px",
  },
  "& .MuiStepLabel-root .Mui-completed": {
    color: "secondary.main",
  },
  "& .MuiStepLabel-root .Mui-active": {
    color: "secondary.dark",
  },
};

const steps = [
  "workspace.dataMapping.step1",
  "workspace.dataMapping.step2",
  "workspace.dataMapping.step3",
  "workspace.dataMapping.step4",
];

export default function MappingSteps() {
  const { t } = useTranslation();
  const { id, wsId, dashboardId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  useEffect(
    function () {
      const path =
        location.pathname.split("/").at(-1) || WORKSPACE_ROUTES.CUSTOMER_DATA;
      setActiveStep(
        (workspaceConfigs as unknown as { [key: string]: { step: number } })[
          path
        ]?.step || 0
      );
    },
    [location.pathname]
  );

  const handleStepClick = (index: number) => {
    if (index === activeStep) return;

    setActiveStep(index);

    const routes = [
      WORKSPACE_ROUTES.CUSTOMER_DATA,
      WORKSPACE_ROUTES.CUSTOMER_DATA_VALUES_DATES,
      WORKSPACE_ROUTES.CUSTOMER_DATA_PRODUCTS_CONFIRM,
      WORKSPACE_ROUTES.CUSTOMER_DATA_ADDITIONAL,
    ];

    const targetRoute = routes[index];

    if (targetRoute) {
      navigate(
        `/${ROUTE_PATHS.TEAMS}/${id}/${ROUTE_PATHS.WORKSPACES}/${wsId}/${dashboardId}/${targetRoute}`
      );
    }
  };

  return (
    <Stepper sx={stepperStyles} activeStep={activeStep}>
      {steps.map((labelStep, index) => (
        <Step
          key={index + 1}
          onClick={() => handleStepClick(index)}
          disabled={false}
          sx={{
            cursor: "pointer",
            "&:hover": { opacity: 0.8, cursor: "pointer" },
          }}
        >
          <StepLabel>
            {index === 1 ? (
              <Typography variant="subtitle2">
                <Trans i18nKey={labelStep} components={{ p: <p /> }}>
                  Values <p>& Dates</p>
                </Trans>
              </Typography>
            ) : (
              <Typography variant="subtitle2">{t(labelStep)}</Typography>
            )}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
