import { Alert, Button, Form, Input } from "antd";
import FormContainer from "components/FormContainer";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuthUser } from "utils/hook/useAuthUser";
import { ROUTE_PATHS } from "common/constants";
import { useNavigate } from "react-router-dom";

import AuthenticationLayout from "./AuthenticationLayout";
import { emailRules, passwordRules } from "./validation";

const LoginForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loginBasic, getToken } = useAuthUser();

  const [errorCode, setErrorCode] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const token = getToken();
    if (token) {
      navigate(`/${ROUTE_PATHS.LOGIN_SUCCESS}`, { replace: true });
    }
  }, [navigate, getToken]);

  const onFinish = (values: { username: string; password: string }) => {
    setIsLoading(true);
    loginBasic(values.username, values.password)
      .then(() => (window.location.href = `/${ROUTE_PATHS.LOGIN_SUCCESS}`))
      .catch((error) => setErrorCode(error.errorCode))
      .finally(() => setIsLoading(false));
  };

  return (
    <AuthenticationLayout>
      <FormContainer>
        <div className="text-center pb-4 font-bold text-2xl">Login</div>
        {errorCode && <Alert message={t(errorCode)} type="error" />}
        <Form name="login" onFinish={onFinish} layout="vertical">
          <Form.Item name="username" label="Email" rules={emailRules}>
            <Input />
          </Form.Item>
          <Form.Item name="password" label="Password" rules={passwordRules}>
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              className="w-full"
            >
              Log in
            </Button>
          </Form.Item>
          <Button type="link" className="float-right">
            Reset Password
          </Button>
        </Form>
      </FormContainer>
    </AuthenticationLayout>
  );
};

export default LoginForm;
