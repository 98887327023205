import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import { CustomerLevel, ReportMeasurement, ReportView } from "common/constants";
import { useChartSettings, useDispatch, useReportSelector } from "common/store";
import GradientCard from "components/Card/GradientCard";
import { SingleValueTooltip } from "components/ChartTooltip";
import StackBarChart from "components/Charts/StackBarChart";
import { ChartDatum } from "components/Charts/model";
import DatePicker from "components/Inputs/DatePicker";
import { RevenueByCustomer, RevenueByCustomerChartModel } from "models/report";
import moment, { Moment } from "moment";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getTopCustomerChart } from "services/reportService";
import { setChartSettings, setChartShouldFetch } from "slices/reportSlice";
import { computeReportData } from "utils/chartUtils/revenueByCustomer";
import { formatCurrencyValue } from "utils/format";
import useDidUpdateEffect from "utils/hook/useDidUpdateEffect";
import {
  generateBarLegends,
  getDatePickerLabel,
  getDatePickerType,
  getFiscalMonth,
  hasCustSegMapped,
  hasProdSegMapped,
} from "utils/report";
import { getLastDatePeriod } from "utils/globalSettingsUtils";
import { exportToExcel } from "utils/exportExcelFile/exportExcel";
import { generateFilterSheet } from "utils/exportExcelFile/generateFilterSheet";
import { UNIT } from "utils/exportExcelFile/constants";

import {
  WrapperContainer,
  chartMinHeight,
} from "../components/CommonComponents";

const maxCustomers = 200;
const nameLimit = 7;

export default function TopCustomersCard() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { reportData, reportSettings } = useReportSelector();
  const chartSettings = useChartSettings("top-customers");
  const chartData = useReportSelector().chartData["top-customers"];

  const [cMeasure, setCMeasure] = useState<boolean>(false);

  const isMappedCustSeg = useMemo(
    () => hasCustSegMapped(reportData.mapping),
    [reportData.mapping]
  );
  const isMappedProdSeg = useMemo(
    () => hasProdSegMapped(reportData.mapping),
    [reportData.mapping]
  );

  const isCustomerLevel = useMemo(
    () => reportSettings.params.customerLevel === CustomerLevel.Customer,
    [reportSettings.params.customerLevel]
  );

  const segmentSelects = useMemo(() => {
    if (!reportData.mapping) return [];

    let segmentFields: string[] = [];
    if (chartSettings.segView === ReportView.TopCustomerType) {
      segmentFields = Object.keys(reportData.mapping).filter((x) =>
        x.startsWith("customerseg")
      );
    }
    if (chartSettings.segView === ReportView.TopProductType) {
      segmentFields = Object.keys(reportData.mapping).filter((x) =>
        x.startsWith("product")
      );
    }
    const result = segmentFields
      .map((x) => ({
        key: x,
        value: reportData.mapping![x],
      }))
      .filter((x) => x.value);

    return result;
  }, [chartSettings.segView, reportData.mapping]);

  // Report data with filters applied, ready to put into chart
  const displayableReportData = useMemo(() => {
    if (chartData.data === undefined) return [];

    const computedData = computeReportData(
      chartData.data as RevenueByCustomer[],
      reportSettings,
      chartSettings.segView
    );

    const monthFormat = chartSettings.month?.slice(0, -3) ?? "";
    return (computedData[monthFormat] ?? []).slice(
      0,
      chartSettings.topCustomers
    );
  }, [
    chartData.isLoading,
    reportSettings.measurement,
    chartSettings.month,
    chartSettings.topCustomers,
  ]);

  // Report legends bars, showing all dataset values for the legends
  const bars: ChartDatum<RevenueByCustomerChartModel>[] = useMemo(() => {
    let segViewName: "customer" | "product" | null = null;
    if (chartSettings.segView === ReportView.TopCustomerType)
      segViewName = "customer";
    if (chartSettings.segView === ReportView.TopProductType)
      segViewName = "product";
    return generateBarLegends(
      segViewName,
      chartSettings.segKey,
      reportData,
      reportSettings.filters
    );
  }, [
    chartSettings.segView,
    chartSettings.segKey,
    reportData,
    reportSettings.filters,
  ]);

  function handleOnViewToggleChanged(value: ReportView | null): void {
    if (value !== chartSettings.segView) {
      dispatch(setChartSettings({ "top-customers": { segView: value } }));
    }
  }

  function handleOnSegmentKeyChanged(value: string | null): void {
    if (value !== chartSettings.segKey) {
      dispatch(setChartSettings({ "top-customers": { segKey: value } }));
    }
  }

  function handleOnCustomersChanged(value: string): void {
    let newThreshold = Number.parseInt(value);
    if (newThreshold < 1) newThreshold = 1;
    if (newThreshold > maxCustomers) newThreshold = maxCustomers;
    dispatch(
      setChartSettings({ "top-customers": { topCustomers: newThreshold } })
    );
  }

  function handleOnPeriodChanged(date: Moment | null): void {
    if (!date) return;
    const lastDate = getLastDatePeriod(
      date,
      reportSettings.params.revenueType,
      reportData.globalSettings?.defaultMinDate,
      reportData.globalSettings?.defaultMaxDate
    );
    lastDate &&
      dispatch(
        setChartSettings({
          "top-customers": { month: lastDate.format("YYYY-MM-DD") },
        })
      );
  }

  useDidUpdateEffect(() => {
    if (reportData.isLoading || !chartData.shouldFetch) return;

    if (
      chartSettings.segKey !== undefined &&
      chartSettings.month !== undefined
    ) {
      dispatch(
        getTopCustomerChart({
          month: chartSettings.month,
          view: chartSettings.segView,
          segmentKey: chartSettings.segKey,
        })
      );
    }

    if (reportData.mapping && chartSettings.segView === undefined) {
      let newSegView = null;
      if (isMappedProdSeg) newSegView = ReportView.TopProductType;
      if (isMappedCustSeg) newSegView = ReportView.TopCustomerType;
      handleOnViewToggleChanged(newSegView);
    }
  }, [
    reportData.isLoading,
    chartData.shouldFetch,
    chartSettings.segKey,
    chartSettings.month,
  ]);

  // Set seleted month at initial render (right after change global settings max date)
  useEffect(() => {
    if (!reportSettings.filters.maxDate) return;

    handleOnPeriodChanged(moment(reportSettings.filters.maxDate));
  }, []);

  // Set seleted month from the second render
  useDidUpdateEffect(() => {
    if (!reportSettings.filters.minDate || !reportSettings.filters.maxDate)
      return;

    if (
      chartSettings.month === undefined ||
      chartSettings.month < reportSettings.filters.minDate ||
      chartSettings.month > reportSettings.filters.maxDate
    )
      handleOnPeriodChanged(moment(reportSettings.filters.maxDate));
  }, [reportSettings.filters.minDate, reportSettings.filters.maxDate]);

  useDidUpdateEffect(() => {
    if (chartSettings.segView === undefined) return;

    const value = segmentSelects.find((x) => x.key === chartSettings.segKey);
    if (value?.key) {
      handleOnSegmentKeyChanged(value.key);
      return;
    }

    handleOnSegmentKeyChanged(
      segmentSelects.length > 0 ? segmentSelects[0].key : null
    );
  }, [segmentSelects]);

  useDidUpdateEffect(() => {
    const hasCMeasure =
      reportSettings.measurement === ReportMeasurement.CARR ||
      reportSettings.measurement === ReportMeasurement.CMRR;
    setCMeasure(hasCMeasure);
  }, [reportSettings.measurement]);

  useDidUpdateEffect(() => {
    dispatch(setChartShouldFetch(["top-customers"]));
  }, [
    cMeasure,
    chartSettings.segKey,
    chartSettings.month,
    reportSettings.filters.customers,
    reportSettings.filters.segmentCustomers,
    reportSettings.filters.segmentProducts,
  ]);

  const handleExport = () => {
    const headerData = [
      t("Dashboard.DollarsInThounsands"),
      ...displayableReportData.map((row) => row.customer),
    ];
    const categories = bars.map((item) => item.key);
    const sheet2 = generateFilterSheet(
      reportSettings,
      reportData,
      t,
      chartSettings
    );
    const sheet1 = {
      sheetName: "Top Customer",
      header: headerData,
      categories,
      data: displayableReportData || [],
      subData: {},
      option: {
        unitData: UNIT.THOUSANDS,
      },
    };

    exportToExcel(
      [sheet1, sheet2],
      `${moment().format("YYYY-MM-DD")}_${t(
        "Dashboard.Charts.SectionCountChanges"
      )}`
    );
  };

  return (
    <GradientCard
      title={
        <Stack
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          marginBottom={2}
          spacing={2}
          rowGap={2}
        >
          <Typography variant="h5">
            {t("Dashboard.Charts.TopCustomersTitle")}
          </Typography>
          <ToggleButtonGroup
            exclusive
            size="small"
            value={chartSettings.segView}
            onChange={(_, v) => v !== null && handleOnViewToggleChanged(v)}
          >
            <ToggleButton
              size="small"
              value={ReportView.TopCustomerType}
              disabled={!hasCustSegMapped(reportData.mapping)}
            >
              {t("Common.CustSeg")}
            </ToggleButton>
            <ToggleButton
              size="small"
              value={ReportView.TopProductType}
              disabled={
                !hasProdSegMapped(reportData.mapping) || isCustomerLevel
              }
            >
              {t("Common.ProdSeg")}
            </ToggleButton>
            <ToggleButton size="small" value={999} disabled>
              {t("Common.Other")}
            </ToggleButton>
          </ToggleButtonGroup>
          {(isMappedCustSeg || isMappedProdSeg) && (
            <FormControl size="small" sx={{ width: "220px" }}>
              <InputLabel>
                {chartSettings.segView === ReportView.TopCustomerType
                  ? t("Common.CustSeg")
                  : t("Common.ProdSeg")}
              </InputLabel>
              <Select
                MenuProps={{ disableScrollLock: true }}
                label={
                  chartSettings.segView === ReportView.TopCustomerType
                    ? t("Common.CustSeg")
                    : t("Common.ProdSeg")
                }
                value={chartSettings.segKey ?? ""}
                onChange={(e) => handleOnSegmentKeyChanged(e.target.value)}
              >
                {segmentSelects.map((x) => (
                  <MenuItem key={x.key} value={x.key}>
                    {x.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <FormControl>
            <InputLabel>{t("Dashboard.Charts.MaxCustomers")}</InputLabel>
            <OutlinedInput
              label={t("Dashboard.Charts.MaxCustomers")}
              type="number"
              id="customers-input"
              size="small"
              value={chartSettings.topCustomers}
              onChange={(e) => handleOnCustomersChanged(e.target.value)}
            />
          </FormControl>
          <DatePicker
            size="small"
            type={getDatePickerType(reportSettings.params.revenueType)}
            label={getDatePickerLabel(reportSettings.params.revenueType)}
            value={moment(chartSettings.month)}
            fiscalMonth={getFiscalMonth(
              reportData.mapping,
              reportSettings.params.revenueType
            )}
            onChange={handleOnPeriodChanged}
            minDate={moment(reportSettings.filters.minDate)}
            maxDate={moment(reportSettings.filters.maxDate)}
          />
        </Stack>
      }
      subheader={
        <Typography variant="body2" fontStyle="italic">
          {t("Dashboard.DollarsInThounsands")}
        </Typography>
      }
      onMoreClick={() => console.log("more")}
      onExport={handleExport}
      isDisabledActions={
        reportData.isLoading ||
        chartData.isLoading ||
        !!chartData.error ||
        !!reportData.error
      }
    >
      <WrapperContainer
        isLoading={reportData.isLoading || chartData.isLoading}
        error={reportData.error || chartData.error}
        isNoData={displayableReportData.length === 0}
      >
        <StackBarChart
          layout="vertical"
          xAxisKey="customer"
          bars={bars}
          data={displayableReportData}
          width="100%"
          height={chartMinHeight}
          xAxisProps={{
            tickFormatter: (value) => formatCurrencyValue(value),
          }}
          yAxisProps={{
            tickFormatter: (value) => {
              if (value.length > nameLimit) {
                return `${value.slice(0, Math.max(0, nameLimit))}...`;
              }
              return value;
            },
          }}
          renderTooltip={(payload) =>
            SingleValueTooltip("customer", payload, (value) =>
              formatCurrencyValue((value as number) || 0, true)
            )
          }
          margin={{ top: 0, left: 16, bottom: 0, right: 16 }}
          hideLegend={chartSettings.segView == null}
        />
      </WrapperContainer>
    </GradientCard>
  );
}
