import React, { useState, MouseEvent } from "react";
import {
  BarChartOutlined,
  DownloadOutlined,
  MoreHorizOutlined,
  TableViewOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@mui/icons-material";
import {
  Button,
  SxProps,
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Select,
  MenuItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";

interface SelectProps {
  onSelect: (value: string) => void;
  selectValue: string;
  selectOptions: { label: string; value: string }[];
}

interface ActionProps {
  viewToggleValue?: ("chart" | "data")[];
  onViewToggle?: (value: ("chart" | "data")[]) => void;
  onZoomClick?: (value: "in" | "out") => void;
  onMoreClick?: () => void;
  onExport?: () => void;
  selectProps?: SelectProps;
  isDisabled?: boolean;
}

const ActionButtons: React.FC<ActionProps> = ({
  viewToggleValue,
  onViewToggle,
  onZoomClick,
  onMoreClick,
  onExport,
  selectProps,
  isDisabled,
}) => {
  const [view, setView] = useState<("chart" | "data")[]>(["chart"]);
  const { t } = useTranslation();

  const handleViewChange = (
    _: MouseEvent<HTMLElement>,
    values: ("chart" | "data")[]
  ) => {
    setView(values);
    onViewToggle?.(values);
  };

  return (
    <Stack direction="row" spacing={2}>
      {selectProps && (
        <Select
          size="small"
          value={selectProps.selectValue}
          onChange={(e) => selectProps.onSelect(e.target.value)}
        >
          {selectProps.selectOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      )}
      {onExport && (
        <Button
          startIcon={<DownloadOutlined />}
          onClick={onExport}
          sx={{ color: "text.primary" }}
          disabled={isDisabled}
        >
          {t("GradientCard.ExportBtn")}
        </Button>
      )}
      {onViewToggle && (
        <ToggleButtonGroup
          size="small"
          value={viewToggleValue || view}
          onChange={handleViewChange}
        >
          <ToggleButton value="chart" sx={{ pointerEvents: "none" }}>
            <BarChartOutlined />
          </ToggleButton>
          <ToggleButton value="data">
            <TableViewOutlined />
          </ToggleButton>
        </ToggleButtonGroup>
      )}
      {onZoomClick && (
        <ToggleButtonGroup size="small">
          <ToggleButton value="in" onClick={() => onZoomClick("in")}>
            <ZoomInOutlined />
          </ToggleButton>
          <ToggleButton value="out" onClick={() => onZoomClick("out")}>
            <ZoomOutOutlined />
          </ToggleButton>
        </ToggleButtonGroup>
      )}
      {onMoreClick && (
        <IconButton onClick={onMoreClick}>
          <MoreHorizOutlined />
        </IconButton>
      )}
    </Stack>
  );
};

interface GradientCardProps {
  title?: string | React.ReactNode;
  subheader?: string | React.ReactNode;
  viewToggleValue?: ("chart" | "data")[];
  sx?: SxProps;
  contentSx?: SxProps;
  onViewToggle?: (value: ("chart" | "data")[]) => void;
  onZoomClick?: (value: "in" | "out") => void;
  onMoreClick?: () => void;
  onExport?: () => void;
  selectProps?: SelectProps;
  children?: React.ReactNode;
  isDisabledActions?: boolean;
}

const GradientCard: React.FC<GradientCardProps> = ({
  title,
  subheader,
  viewToggleValue,
  sx,
  contentSx,
  onViewToggle,
  onZoomClick,
  onMoreClick,
  onExport,
  selectProps,
  children,
  isDisabledActions,
}) => {
  return (
    <Card sx={{ height: "100%", ...sx }}>
      <Box
        sx={{
          background: "linear-gradient(90deg, #293252, #0097A7)",
          height: 16,
        }}
      />
      {(title || subheader) && (
        <CardHeader
          title={title}
          subheader={subheader}
          action={
            <ActionButtons
              onViewToggle={onViewToggle}
              onZoomClick={onZoomClick}
              onMoreClick={onMoreClick}
              onExport={onExport}
              selectProps={selectProps}
              viewToggleValue={viewToggleValue}
              isDisabled={isDisabledActions}
            />
          }
          sx={{ flexWrap: "wrap", gap: 2 }}
        />
      )}
      <CardContent
        sx={{ padding: 2, "&:last-child": { paddingBottom: 2 }, ...contentSx }}
      >
        {children}
      </CardContent>
    </Card>
  );
};

export default GradientCard;
