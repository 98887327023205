import { useEffect, useState } from "react";
import MappingTable from "components/MappingTable";
import MappingSelect from "components/MappingTable/SimpleMappingSelect";
import { Close } from "@mui/icons-material";
import {
  Modal,
  Card,
  Typography,
  Button,
  Backdrop,
  Fade,
  Paper,
  IconButton,
  CardHeader,
  CardContent,
  CardActions,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { getRowFromAttrHelper } from "../../config";
import { useDataMappingCtx } from "../DataMappingContext";

export function RemapModal({
  open,
  setOpenModal,
}: {
  open: boolean;
  setOpenModal: (open: boolean) => void;
}) {
  const { fields, remappingInfo, doMapField, closeSelectFunc } =
    useDataMappingCtx();

  const rows = [
    {
      ...remappingInfo.row,
      isRequiredIndex: !!remappingInfo.row.isRequired,
      isOptionalIndex: !remappingInfo.row.isRequired,
    },
  ];
  const row2 = getRowFromAttrHelper(remappingInfo.currentMappedTo);
  const [tempMappingResult, setTempMappingResult] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    setTempMappingResult((prev) => {
      return {
        ...prev,
        [remappingInfo.row.attribute]: remappingInfo.fieldFromFile,
      };
    });
    return () => {
      setTempMappingResult({});
    };
  }, [remappingInfo]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => setOpenModal(false)}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Paper
          elevation={4}
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: "16px",
            p: "24px",
            minWidth: "550px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton onClick={() => setOpenModal(false)}>
              <Close
                sx={{
                  width: "24px",
                  height: "24px",
                }}
              />
            </IconButton>
          </div>
          <Card
            elevation={0}
            sx={{
              border: "none",
            }}
          >
            <CardHeader
              title={
                <Typography variant="h5">
                  {t("MappingReview.RemapModal.Header")}
                </Typography>
              }
            />
            <CardContent>
              <Typography variant="h6">
                {t("MappingReview.RemapModal.Field1Description")}
              </Typography>
              <MappingTable
                rows={rows}
                sourceFields={fields}
                mappingValues={tempMappingResult}
                selectComponent={MappingSelect}
                onChange={(attr: string, value: string) => {
                  setTempMappingResult((prev) => ({ ...prev, [attr]: value }));
                }}
              />
              <Typography variant="h6">
                {t("MappingReview.RemapModal.Field2Description")}
              </Typography>
              {row2 && (
                <MappingTable
                  rows={[row2]}
                  sourceFields={fields}
                  mappingValues={tempMappingResult}
                  selectComponent={MappingSelect}
                  onChange={(attr: string, value: string) => {
                    setTempMappingResult((prev) => ({
                      ...prev,
                      [attr]: value,
                    }));
                  }}
                />
              )}
            </CardContent>
          </Card>
          <CardActions>
            <Button
              variant="contained"
              sx={{ borderRadius: "120px" }}
              onClick={() => {
                for (const [key, value] of Object.entries(tempMappingResult)) {
                  doMapField(key, value as string);
                }
                setOpenModal(false);
                closeSelectFunc();
              }}
            >
              {t("MappingReview.RemapModal.SaveMappingBtn")}
            </Button>
            <Button
              sx={{ borderRadius: "120px" }}
              onClick={() => setOpenModal(false)}
            >
              {t("Cancel")}
            </Button>
          </CardActions>
        </Paper>
      </Fade>
    </Modal>
  );
}
