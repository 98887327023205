import moment from "moment";
import { GlobalSettingItem, GlobalSettings } from "models/globalSettings";
import { MIN_DATE, RevenueType } from "common/constants";

export function extractGlobalSettings(
  settings: GlobalSettingItem[]
): GlobalSettings {
  const analysisType = settings.find((x) => x.name === "AnalysisType")?.value;
  const customerLevel = settings.find((x) => x.name === "CustomerLevel")?.value;
  const revenueType = settings.find((x) => x.name === "TimeType")?.value;
  const threshold = settings.find((x) => x.name === "Threshold")?.value;
  let minDate = settings.find((x) => x.name === "TimeRangeMin")?.value;
  let maxDate = settings.find((x) => x.name === "TimeRangeMax")?.value;
  const current = moment(new Date()).format("YYYY-MM-01");
  const minimumDate = moment(MIN_DATE).format("YYYY-MM-01");

  if (!minDate) {
    const minDateVal =
      settings.find((x) => x.name === "MinStartDate")?.value ?? minimumDate;
    minDate = moment(minDateVal).endOf("M").format("YYYY-MM-DD");
  }
  if (!maxDate) {
    const maxDateVal =
      settings.find((x) => x.name === "MaxEndDate")?.value ?? current;
    maxDate = moment(maxDateVal).endOf("M").format("YYYY-MM-DD");
  }

  const defaultMinDate =
    settings.find((x) => x.name === "MinStartDate")?.value ?? minimumDate;
  const defaultMaxDate =
    settings.find((x) => x.name === "MaxEndDate")?.value ?? current;

  return {
    minDate,
    maxDate,
    defaultMinDate,
    defaultMaxDate,
    analysisType:
      typeof analysisType === "string" ? Number.parseInt(analysisType) : null,
    customerLevel:
      typeof customerLevel === "string" ? Number.parseInt(customerLevel) : null,
    revenueType:
      typeof revenueType === "string" ? Number.parseInt(revenueType) : null,
    reactivationThreshold:
      typeof threshold === "string" ? Number.parseInt(threshold) : null,
  };
}

export function getLastDatePeriod(
  date: moment.Moment,
  revenueType: RevenueType,
  defaultMinDate: string | undefined,
  defaultMaxDate: string | undefined
) {
  let lastDate = date.endOf("M");
  const minDate = moment(defaultMinDate);
  const maxDate = moment(defaultMaxDate);

  if (revenueType === RevenueType.Yearly) {
    lastDate = lastDate.endOf("y");
  } else if (revenueType === RevenueType.Quarterly) {
    lastDate = date.endOf("Q").endOf("M");
  }
  if (lastDate.isBefore(minDate)) {
    lastDate = minDate;
  } else if (lastDate.isAfter(maxDate)) {
    lastDate = maxDate;
  }
  return lastDate;
}

export function getFirstDatePeriod(
  date: moment.Moment,
  revenueType: RevenueType,
  defaultMinDate: string | undefined,
  defaultMaxDate: string | undefined
) {
  let firstDate = date.startOf("M");
  const minDate = moment(defaultMinDate);
  const maxDate = moment(defaultMaxDate);

  if (revenueType === RevenueType.Yearly) {
    firstDate = firstDate.startOf("y");
  } else if (revenueType === RevenueType.Quarterly) {
    firstDate = firstDate.startOf("M");
  }
  if (firstDate.isBefore(minDate)) {
    firstDate = minDate;
  } else if (firstDate.isAfter(maxDate)) {
    firstDate = maxDate;
  }
  return firstDate;
}
