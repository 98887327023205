import {
  DISTINCT_COLORS,
  MEASUREMENT_PROPERTIES,
  PREDEFINED_COLORS,
  ReportMeasurement,
  ReportSegmentation,
  ReportView,
  RevenueType,
} from "common/constants";
import { t } from "i18next";
import lodashGroupBy from "lodash/groupBy";
import lodashSortBy from "lodash/sortBy";
import lodashFindIndex from "lodash/findIndex";
import lodashFindLastIndex from "lodash/findLastIndex";
import { KeyValue } from "models/common";
import { FileMappingObject } from "models/fileModel";
import {
  CohortReport,
  RevenueByCustomer,
  RevenueByCustomerChartModel,
  SegCustResponse,
  SegProdResponse,
  DashboardView,
  FiscalMonth,
} from "models/report";
import { Moment } from "moment";
import { ReportData, ReportFilters } from "slices/models/reportSliceModel";

import { generateColors } from "./generateColors";

export function getSourceColumnName(
  mapping: Record<string, string | null> | null | undefined,
  displayKeyName: string
) {
  if (mapping == null) return "";
  return mapping[displayKeyName?.toLocaleLowerCase().replaceAll(" ", "")];
}

// Get fiscal start month for date picker
export function getFiscalMonth(
  mapping: Record<string, string | null> | null | undefined,
  revenueType: RevenueType = RevenueType.Monthly
): number | undefined {
  return revenueType === RevenueType.Monthly ||
    mapping == null ||
    !mapping.fiscalstartmonth ||
    mapping.fiscalstartmonth === "1"
    ? undefined
    : Number(mapping.fiscalstartmonth);
}

export function getCalendarStartDate(
  date: Moment,
  fiscalMonth: number = 1,
  revenueType: RevenueType = RevenueType.Monthly
) {
  const cloned = date.clone();
  const fiscalDate = cloned.add(fiscalMonth - 13, "month");
  if (revenueType === RevenueType.Monthly) {
    return fiscalDate.startOf("month");
  }
  if (revenueType === RevenueType.Quarterly) {
    return fiscalDate.startOf("quarter");
  }
  if (revenueType === RevenueType.Yearly) {
    return fiscalDate.startOf("year");
  }
  throw new Error("Function not implemented.");
}

export function getSegmentations(
  segmentationData: (SegCustResponse | SegProdResponse)[],
  mapping?: Record<string, string | null>
) {
  const groups = lodashGroupBy(segmentationData, (item) => item.TYPE);

  const segmentations = Object.entries(groups).map(([segmentName, data]) => ({
    segmentName,
    data,
  }));

  if (mapping) {
    return segmentations.sort((a, b) => {
      if (
        getSourceColumnName(mapping, a.segmentName)! <
        getSourceColumnName(mapping, b.segmentName)!
      ) {
        return -1;
      }
      return 1;
    });
  }

  return segmentations;
}

export function computeRevenueByCustomerReport(
  data: RevenueByCustomer[],
  measurement: ReportMeasurement,
  segmentationType: ReportSegmentation
): RevenueByCustomerChartModel[] {
  const customers = lodashGroupBy(data, (item) => item.Customer);
  const models = Object.entries(customers).map(([key, values]) => {
    const model: RevenueByCustomerChartModel = {
      customer: key,
    } as RevenueByCustomerChartModel;
    const nameKey =
      segmentationType === ReportSegmentation.Customer
        ? "CustomerValue"
        : "ProductValue";

    const valueKey = MEASUREMENT_PROPERTIES[measurement];

    for (const value of values) {
      model[value[nameKey]!] = (value[valueKey] as number) || 0;
    }
    return model;
  });

  return models;
}

export function computeRevenueByCustomerReportV2(
  data: RevenueByCustomer[],
  measurement: ReportMeasurement,
  segView: ReportView
): { [month: string]: RevenueByCustomerChartModel[] } {
  const nameKey =
    segView === ReportView.TopCustomerType ? "CustomerValue" : "ProductValue";

  const valueKey = MEASUREMENT_PROPERTIES[measurement];

  const result: { [month: string]: RevenueByCustomerChartModel[] } = {};
  const months = lodashGroupBy(data, (item) => item.Month);
  for (const [month, monthData] of Object.entries(months)) {
    const customers = lodashGroupBy(monthData, (item) => item.Customer);
    const models = Object.entries(customers).map(([key, values]) => {
      const model: RevenueByCustomerChartModel = {
        customer: key,
      } as RevenueByCustomerChartModel;

      for (const value of values) {
        model[value[nameKey]!] = (value[valueKey] as number) || 0;
      }
      return model;
    });
    result[month] = lodashSortBy(models, (model) => {
      const values = Object.entries(model).filter((x) => x[0] !== "customer");
      const totalValue = values.reduce(
        (total, x) => total + (x[1] as number),
        0
      );
      return -totalValue;
    });
  }
  return result;
}

export function getMappingRecord(mapping: FileMappingObject[]) {
  return Object.fromEntries(
    mapping.map((x) => [x.mdtName.toLowerCase(), x.sourceName])
  );
}

export function hasCustSegMapped(
  mapping: Record<string, string | null> | undefined
) {
  if (!mapping) return false;
  return Object.entries(mapping).some(
    (x) => x[0].startsWith("customerseg") && x[1] !== null
  );
}

export function hasProdSegMapped(
  mapping: Record<string, string | null> | undefined
) {
  if (!mapping) return false;
  return Object.entries(mapping).some(
    (x) => x[0].startsWith("product") && x[1] !== null
  );
}

export function hasFilteredOutAll(
  customerSegments: { [segment: string]: number[] },
  products: { [segment: string]: number[] },
  customers?: number[]
): boolean {
  if (customers && customers.length === 0) {
    return true;
  }
  if (Object.values(customerSegments).some((segment) => segment.length === 0)) {
    return true;
  }
  if (Object.values(products).some((segment) => segment.length === 0)) {
    return true;
  }
  return false;
}

export function filteredOtherSegments(
  currentSegment: string,
  segments: { [segment: string]: number[] },
  segmentsData: SegCustResponse[] | SegProdResponse[]
): boolean {
  const segmentations = getSegmentations(segmentsData);
  const filteredSegments = segmentations.filter(
    (s) => s.data.length !== segments[s.segmentName].length
  );

  return filteredSegments.some(
    (segment) => segment.segmentName !== currentSegment
  );
}

export function generateBarLegends(
  segView: "customer" | "product" | null,
  segment: string | null | undefined,
  options: ReportData,
  filter: ReportFilters
) {
  let segmentKeys: string[] = [];
  if (segView === null) {
    return [{ key: "Total", color: PREDEFINED_COLORS[0] }];
  }

  if (segView === "customer") {
    segmentKeys = options.segmentCustomers?.map((x) => x.segment) ?? [];
  }
  if (segView === "product") {
    segmentKeys = options.segmentProducts?.map((x) => x.segment) ?? [];
  }

  const segmentKey =
    segmentKeys.find(
      (x) => x.toLocaleLowerCase().replaceAll(" ", "") === segment
    ) ?? "";

  let filterValues: KeyValue[] | undefined = [];
  let optionValues: KeyValue[] | undefined = [];
  if (segView === "customer") {
    filterValues = filter.segmentCustomers[segmentKey];
    optionValues = options.segmentCustomers?.find(
      (x) => x.segment === segmentKey
    )?.data;
  }
  if (segView === "product") {
    filterValues = filter.segmentProducts[segmentKey];
    optionValues = options.segmentProducts?.find(
      (x) => x.segment === segmentKey
    )?.data;
  }

  let keyValues: KeyValue[] = [];
  keyValues =
    filterValues === undefined || filterValues.length === 0
      ? optionValues ?? []
      : filterValues ?? [];

  if (!keyValues) return [];

  const colors = DISTINCT_COLORS;

  if (keyValues.length > colors.length) {
    colors.push(...generateColors(keyValues.length - colors.length));
  }
  return keyValues.map((x, i) => ({ key: x.value, color: colors[i] }));
}

export function getDatePickerType(
  revenueType: RevenueType
): "month" | "quarter" | "year" {
  switch (revenueType) {
    case RevenueType.Monthly: {
      return "month";
    }
    case RevenueType.Quarterly: {
      return "quarter";
    }
    case RevenueType.Yearly: {
      return "year";
    }
    default: {
      throw new Error("Function not implemented.");
    }
  }
}

export function getLastItemOfEachGroup<T>(items: T[], groupBy: keyof T): T[] {
  const groupMap = new Map<unknown, T>();

  for (const item of items) {
    const key = item[groupBy];
    groupMap.set(key, item);
  }

  return [...groupMap.values()];
}

export function getDatePickerLabel(revenueType: RevenueType) {
  if (revenueType === RevenueType.Monthly) return t("Common.Month");
  if (revenueType === RevenueType.Quarterly) return t("Common.Quarter");
  if (revenueType === RevenueType.Yearly) return t("Common.Year");
  return "";
}

export function shouldRefetchRevenueData(
  measurement: ReportMeasurement,
  prevMeasurement: ReportMeasurement
) {
  if (
    prevMeasurement === ReportMeasurement.ARR ||
    prevMeasurement === ReportMeasurement.MRR ||
    prevMeasurement === ReportMeasurement.QRR
  ) {
    return (
      measurement !== ReportMeasurement.ARR &&
      measurement !== ReportMeasurement.MRR &&
      measurement !== ReportMeasurement.QRR
    );
  }
  if (
    prevMeasurement === ReportMeasurement.CARR ||
    prevMeasurement === ReportMeasurement.CMRR ||
    prevMeasurement === ReportMeasurement.CQRR
  ) {
    return (
      measurement !== ReportMeasurement.CARR &&
      measurement !== ReportMeasurement.CMRR &&
      measurement !== ReportMeasurement.CQRR
    );
  }
}

function getCohortReportMapping(isARR: boolean): {
  [key: string]: keyof CohortReport;
} {
  const arrKeyPrefix = isARR ? "" : "C";

  return {
    "0": "Month",
    "1": "DISPLAYQTR",
    "2": "FISCALYR",
    "3": `${arrKeyPrefix}ARRSTARTDATE` as keyof CohortReport,
    "4": "COHORT_FISCALYR",
    "5": "COHORT_DISPLAYQTR",
    "6": `${arrKeyPrefix}ARRTENURE` as keyof CohortReport,
    "7": `${arrKeyPrefix}ARRTENUREQTR` as keyof CohortReport,
    "8": `${arrKeyPrefix}ARRTENUREYR` as keyof CohortReport,
    "9": "SUM_ENDING_BALANCE",
    "10": "CUSTOMER_COUNT",
    "11": "SUM_UPSELL_PRIOR",
    "12": "SUM_DOWNSELL_PRIOR",
    "13": "SUM_NEW_CUSTOMER_PRIOR" as keyof CohortReport,
    "14": "SUM_LOST_CUSTOMER_PRIOR",
    "15": "PRIOR_LOST_CUSTOMER",
    "16": "SUM_BEGINNING_BALANCE_PRIOR",
    "17": "PRIOR_CUSTOMER",
    "18": "SUM_NEW_CUSTOMER_INITIAL" as keyof CohortReport,
    "19": "SUM_UPSELL_INITIAL",
    "20": "SUM_DOWNSELL_INITIAL",
    "21": "SUM_LOST_CUSTOMER_INITIAL",
    "22": "INITIAL_LOST_CUSTOMER",
    "23": "SUM_BEGINNING_BALANCE_INITIAL",
    "24": "INITIAL_CUSTOMER",
  };
}

export function mapCohortReportData(
  data: { [key: string]: string | number }[],
  measurement: ReportMeasurement
): CohortReport[] {
  const isARR =
    measurement === ReportMeasurement.ARR ||
    measurement === ReportMeasurement.MRR;
  const mapping = getCohortReportMapping(isARR);

  return data.map((item) => {
    return Object.entries(item).reduce(
      (mapped: CohortReport, [responseKey, responseValue]) => {
        const property = mapping[responseKey];
        return {
          ...mapped,
          [property]: responseValue,
        };
      },
      {} as CohortReport
    );
  });
}

export function getFiscalStartMonth(
  mapping: Record<string, string | null> | null | undefined
): number {
  return mapping?.fiscalstartmonth ? Number(mapping.fiscalstartmonth) : 1;
}

export function isCompleteYear(fiscalStartMonth: number, endDate: string) {
  const endMonth = Number.parseInt(endDate.split("-")[1]);

  if (fiscalStartMonth === 1) {
    return endMonth === 12;
  }
  return endMonth === fiscalStartMonth - 1;
}

const janEndMonths = [3, 6, 9, 12];
const febEndMonths = [4, 7, 10, 1];
const marEndMonths = [5, 8, 11, 2];

const lastMonthsOfQuarters: { [month: number]: number[] } = {
  1: janEndMonths,
  4: janEndMonths,
  7: janEndMonths,
  10: janEndMonths,
  2: febEndMonths,
  5: febEndMonths,
  8: febEndMonths,
  11: febEndMonths,
  3: marEndMonths,
  6: marEndMonths,
  9: marEndMonths,
  12: marEndMonths,
};

export function isCompleteQuarter(fiscalStartMonth: number, endDate: string) {
  const endMonth = Number.parseInt(endDate.split("-")[1]);

  return lastMonthsOfQuarters[fiscalStartMonth].includes(endMonth);
}

/**
 *
 * @param fy: format FYXX
 */
function getIncompleteYearLabel(fy: string, view: DashboardView) {
  const year = fy.slice(2);

  return `${view}${year}`;
}

/**
 *
 * @param quarter: format QX-YY
 */
function getIncompleteQuarterLabel(quarter: string, view: DashboardView) {
  const [q, y] = quarter.split("-");

  return `${q}-${view}${y}`;
}

export function getIncompletePeriodLabel<T extends FiscalMonth>(
  period: T,
  view: DashboardView
) {
  if (period.IS_SHOWN === "LTM" || period.IS_SHOWN === "YTD") {
    return getIncompleteYearLabel(period.FISCALYR, view);
  } else if (period.IS_SHOWN === "L3M" || period.IS_SHOWN === "QTD") {
    return getIncompleteQuarterLabel(period.DISPLAYQTR, view);
  }
  return "";
}

interface SeparatedPeriods<T extends FiscalMonth> {
  complete: T[];
  incomplete: T[];
}

export function separateIncompletePeriods<T extends FiscalMonth>(
  data: T[],
  view?: DashboardView
): SeparatedPeriods<T> {
  if (!view) {
    return {
      complete: data.filter((item) => !item.IS_SHOWN),
      incomplete: [],
    };
  }
  return data.reduce(
    ({ complete, incomplete }: SeparatedPeriods<T>, current) => {
      if (!current.IS_SHOWN) {
        return {
          complete: [...complete, current],
          incomplete,
        };
      }
      if (current.IS_SHOWN === view) {
        return {
          complete,
          incomplete: [...incomplete, current],
        };
      }
      return {
        complete,
        incomplete,
      };
    },
    { complete: [], incomplete: [] }
  );
}

export function filterDataByDate<T extends FiscalMonth>(
  data?: T[],
  minDate?: string,
  maxDate?: string
): T[] | undefined {
  if (data === undefined || !minDate || !maxDate) return;

  const startIndex = lodashFindIndex(
    data,
    (x) => x.Month >= minDate!.slice(0, -3)
  );
  const endIndex = lodashFindLastIndex(
    data,
    (x) => x.Month <= maxDate!.slice(0, -3)
  );
  if (startIndex === -1 || endIndex === -1) return;

  return data.slice(startIndex, endIndex + 1);
}
