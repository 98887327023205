import {
  CONTRACT_RENEWAL_CATEGORY_TABLE_ROW_KEYS,
  CONTRACT_RENEWAL_GROSS_NET_RATE_TABLE_ROW_KEYS,
  RevenueType,
} from "common/constants";
import { Column } from "components/Table/models";
import { groupBy } from "lodash";
import lodashSortBy from "lodash/sortBy";
import {
  CategoriesAndPeriodsTableModel,
  ContractRenewalCategoryModel,
  ContractRenewalCategoryResponse,
} from "models/report";
import { ReportSettings } from "slices/models/reportSliceModel";
import {
  formatCurrencyValue,
  formatPercentValue,
  formatPeriodText,
} from "utils/format";

function extractMonth(item: ContractRenewalCategoryResponse) {
  const date = new Date(item.EXPIRINGENDDATE || 0);
  const month = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    "0"
  )}`;
  return month;
}

export function computeReportData(
  reportSettings: ReportSettings,
  data?: ContractRenewalCategoryResponse[]
): ContractRenewalCategoryModel[] {
  if (data === undefined) return [];
  if (!reportSettings.filters.minDate || !reportSettings.filters.maxDate)
    return [];
  const filterByMonthRange = data.filter((item) => {
    const month = extractMonth(item);
    return (
      month >= reportSettings.filters.minDate!.slice(0, -3) &&
      month <= reportSettings.filters.maxDate!.slice(0, -3)
    );
  });
  const groupByPeriods = groupBy(
    lodashSortBy(filterByMonthRange, (x) => {
      if (reportSettings.params.revenueType === RevenueType.Quarterly) {
        const arr = x.DISPLAYQTR.split("-");
        return `${arr[1]}-${arr[0]}`;
      }
      if (reportSettings.params.revenueType === RevenueType.Yearly) {
        return x.FISCALYR;
      }
      return extractMonth(x);
    }),
    (item) => {
      if (reportSettings.params.revenueType === RevenueType.Quarterly) {
        return item.DISPLAYQTR;
      }
      if (reportSettings.params.revenueType === RevenueType.Yearly) {
        return item.FISCALYR;
      }
      return extractMonth(item);
    }
  );
  const computedData = Object.entries(groupByPeriods).map(([key, value]) => {
    return {
      Month: key,
      DISPLAYQTR: key,
      FISCALYR: key,
      EXPIRINGACV: value.reduce(
        (acc, curr) => acc + (curr.EXPIRINGACV || 0),
        0
      ),
      UPSELLACV: value.reduce((acc, curr) => acc + (curr.UPSELLACV || 0), 0),
      DOWNSELLACV: value.reduce(
        (acc, curr) => acc + (curr.DOWNSELLACV || 0),
        0
      ),
      LOSTACV: value.reduce((acc, curr) => acc + (curr.LOSTACV || 0), 0),
      GROSSRENEWALRATE: value.reduce(
        (acc, curr) => acc + (curr.GROSSRENEWALRATE || 0),
        0
      ),
      NETRENEWALRATE: value.reduce(
        (acc, curr) => acc + (curr.NETRENEWALRATE || 0),
        0
      ),
      year: new Date(value[0].EXPIRINGENDDATE || 0).getFullYear(),
      month: `${new Date(value[0].EXPIRINGENDDATE || 0).getFullYear()}-${
        new Date(value[0].EXPIRINGENDDATE || 0).getMonth() + 1
      }`,
    };
  });

  return computedData.map((period) => {
    let xValue = "";
    if (reportSettings.params.revenueType === RevenueType.Monthly)
      xValue = period.Month;
    if (reportSettings.params.revenueType === RevenueType.Quarterly)
      xValue = period.DISPLAYQTR;
    if (reportSettings.params.revenueType === RevenueType.Yearly)
      xValue = period.FISCALYR;

    return {
      xValue,
      "Downsell ACV": period.DOWNSELLACV,
      "Expiring ACV": period.EXPIRINGACV,
      "Lost ACV": period.LOSTACV,
      "Upsell ACV": period.UPSELLACV,
      "Gross Renewal Rate": period.GROSSRENEWALRATE,
      "Net Renewal Rate": period.NETRENEWALRATE,
      year: period.year,
      month: period.month,
    };
  });
}

export function getContractRenewalDetailTableColumns(
  periods: ContractRenewalCategoryModel[]
) {
  const columns: Column<CategoriesAndPeriodsTableModel>[] = [
    {
      header: "Category",
      render: (data) => data.category,
      footer: "Total",
      sx: {
        position: "sticky",
        left: 0,
        zIndex: 3,
        textWrap: "nowrap",
      },
    },
  ];

  for (const period of periods) {
    const periodType = formatPeriodText(period.xValue);

    columns.push({
      header: periodType,
      render: (data) => {
        return formatCurrencyValue(data[periodType]);
      },
      footer: formatCurrencyValue(
        (period["Downsell ACV"] || 0) +
          (period["Upsell ACV"] || 0) +
          (period["Lost ACV"] || 0) +
          (period["Expiring ACV"] || 0)
      ),
      sx: {
        textWrap: "nowrap",
      },
    });
  }

  return columns;
}

export function getContractRenewalDetailTableData(
  periods: ContractRenewalCategoryModel[]
): CategoriesAndPeriodsTableModel[] {
  const data = [
    {
      category: "Expiring ACV",
    },
    {
      category: "Lost ACV",
    },
    {
      category: "Downsell ACV",
    },
    {
      category: "Upsell ACV",
    },
  ] as CategoriesAndPeriodsTableModel[];

  for (const period of periods) {
    for (const [index, category] of data.entries()) {
      const month = formatPeriodText(period.xValue);

      category[month] = period[
        CONTRACT_RENEWAL_CATEGORY_TABLE_ROW_KEYS[index]
      ] as number;
    }
  }

  return data;
}

export function getContractRenewalGrossNetRateTableColumns(
  periods: ContractRenewalCategoryModel[]
) {
  const columns: Column<CategoriesAndPeriodsTableModel>[] = [
    {
      header: "",
      render: (data) => data.category,
      sx: {
        position: "sticky",
        left: 0,
        zIndex: 3,
        textWrap: "nowrap",
      },
    },
  ];

  for (const period of periods) {
    const periodType = formatPeriodText(period.xValue);

    columns.push({
      header: periodType,
      render: (data) => {
        return formatPercentValue(data[periodType]);
      },
      sx: {
        textWrap: "nowrap",
      },
    });
  }

  return columns;
}

export function getContractRenewalGrossNetRateTableData(
  periods: ContractRenewalCategoryModel[]
): CategoriesAndPeriodsTableModel[] {
  const data = [
    {
      category: "Gross Renewal Rate",
    },
    {
      category: "Net Renewal Rate",
    },
  ] as CategoriesAndPeriodsTableModel[];

  for (const period of periods) {
    for (const [index, category] of data.entries()) {
      const month = formatPeriodText(period.xValue);

      category[month] = period[
        CONTRACT_RENEWAL_GROSS_NET_RATE_TABLE_ROW_KEYS[index]
      ] as number;
    }
  }

  return data;
}
