import { RevenueType, ReportMeasurement } from "common/constants";

export const revenueLabelMapping = {
  [RevenueType.Monthly]: "",
  [RevenueType.Quarterly]: "QTR",
  [RevenueType.Yearly]: "YR",
};

export const measurementMapping = {
  [ReportMeasurement.ARR]: "ARR",
  [ReportMeasurement.MRR]: "MRR",
  [ReportMeasurement.CARR]: "CARR",
  [ReportMeasurement.CMRR]: "CMRR",
};

export const measurementKeyMapping = {
  [ReportMeasurement.ARR]: "SumArrAmount",
  [ReportMeasurement.MRR]: "SumMrrAmount",
  [ReportMeasurement.CARR]: "SumCArrAmount",
  [ReportMeasurement.CMRR]: "SumCMrrAmount",
};
