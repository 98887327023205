import LoginOld from "containers/Authentication/Login.old";
import SignUp from "containers/Authentication/SignUp";
import DashboardPage, {
  loader as DashboardPageLoader,
} from "containers/Dashboard";
import ReportSettings from "containers/Dashboard/settings";
import ReportParameters from "containers/Dashboard/settings/Parameters";
import ReportFilters, {
  actions as reportFiltersActions,
} from "containers/Dashboard/settings/Filters";
import TeamSettings from "containers/TeamSettings";
import TeamUserListPage from "containers/TeamSettings/TeamUserListPage";
import TeamListPage from "containers/Teams";
import Workspaces, {
  action as workspaceAction,
  loader as workspaceLoader,
} from "containers/Workspace";
import WorkspaceManagePage, {
  loader as workspaceManageLoader,
} from "containers/Workspace/Manage";
import {
  newWorkspaceConfig,
  workspaceConfigs,
} from "containers/Workspace/config";
import { RouteObject, createBrowserRouter } from "react-router-dom";
import DashboardsPage, {
  loader as DashboardsPageLoader,
} from "containers/Dashboards";
import { ROUTES, ROUTE_PATHS } from "common/constants";
import LoginSuccess from "containers/Authentication/LoginSucess";
import ManageSourceData from "containers/ManageSourceData";
import File, {
  loader as manageSourceDataLoader,
} from "containers/ManageSourceData/File";
import SuperAdminSettings from "containers/SuperAdminSettings";
import SuperAdminList, {
  action as superAdminAction,
  loader as superAdminListLoader,
} from "containers/SuperAdminSettings/SuperAdminList";
import Login from "containers/Authentication/Login";
import Welcome, {
  actions as welcomeActions,
  loader as welcomeLoader,
} from "containers/Authentication/Welcome";
import ReportsContainer from "containers/Dashboard/ReportsContainer";
import ExcelExportView from "containers/Dashboard/ExcelExportView";
import Mapping from "containers/ManageSourceData/Mapping";
import WorkspaceContextProvider from "containers/Workspace/WorkspaceContext";
import Home from "containers/Home";

import InvalidInvitationCode from "./InvalidInvitationCode";
import PageNotFound from "./PageNotFound";
import PermissionDenied from "./PermissionDenied";
import ProtectedRoutes from "./ProtectedRoutes";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <ProtectedRoutes />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: `/${ROUTE_PATHS.TEAMS}`,
        element: <TeamListPage />,
      },
      {
        path: `/${ROUTE_PATHS.TEAMS}/:id/${ROUTE_PATHS.TEAMS_SETTINGS}`,
        element: <TeamSettings />,
        children: [
          {
            path: ROUTE_PATHS.TEAMS_SETTINGS_USERS,
            element: <TeamUserListPage />,
          },
        ],
      },
      {
        path: `/${ROUTE_PATHS.TEAMS}/:id/${ROUTE_PATHS.WORKSPACES}`,
        loader: workspaceManageLoader,
        errorElement: <PageNotFound />,
        element: <WorkspaceManagePage />,
      },
      {
        path: `/${ROUTE_PATHS.TEAMS}/:id/${ROUTE_PATHS.WORKSPACES}/:wsId`,
        action: workspaceAction,
        loader: workspaceLoader,
        errorElement: <PageNotFound />,
        element: <WorkspaceContextProvider />,
        children: [
          ...Object.entries(newWorkspaceConfig).map(([path]) => {
            const route = {
              path,
              element: <Workspaces />,
            } as RouteObject;
            return route;
          }),
          {
            path: ":dashboardId",
            children: [
              ...Object.entries(workspaceConfigs).map(([path, value]) => {
                const route = {
                  path,
                  element: <Workspaces />,
                } as RouteObject;
                if ("action" in value) {
                  route.action = value.action;
                }
                return route;
              }),
              {
                path: "",
                element: <PageNotFound />,
              },
              {
                path: "*",
                element: <PageNotFound />,
              },
            ],
          },
          {
            path: `:dashboardId/${ROUTE_PATHS.MANAGE_SOURCE_DATA}`,
            element: <ManageSourceData />,
            children: [
              {
                path: ROUTE_PATHS.MANAGE_SOURCE_DATA_FILE,
                element: <File />,
                loader: manageSourceDataLoader,
                errorElement: <PageNotFound />,
              },
              {
                path: ROUTE_PATHS.MANAGE_SOURCE_DATA_MAPPING,
                element: <Mapping />,
                errorElement: <PageNotFound />,
              },
            ],
          },
          {
            path: "*",
            element: <PageNotFound />,
          },
        ],
      },
      {
        path: `/${ROUTE_PATHS.TEAMS}/:id/${ROUTE_PATHS.WORKSPACES}/:wsId/${ROUTE_PATHS.WORKSPACES_HOME}`,
        loader: DashboardsPageLoader,
        errorElement: <PageNotFound />,
        element: <DashboardsPage />,
      },
      {
        path: `/${ROUTE_PATHS.TEAMS}/:id/${ROUTE_PATHS.WORKSPACES}/:wsId/:dashboardId/${ROUTE_PATHS.WORKSPACES_REPORT}`,
        element: <DashboardPage />,
        loader: DashboardPageLoader,
        errorElement: <PageNotFound />,
        children: [
          {
            path: "",
            element: <ReportsContainer />,
            errorElement: <PageNotFound />,
          },
          {
            path: ROUTE_PATHS.REPORT_EXCEL_EXPORT,
            element: <ExcelExportView />,
            errorElement: <PageNotFound />,
          },
        ],
      },
      {
        path: ROUTES.REPORT_SETTINGS(":id", ":wsId", ":dashboardId"),
        element: <ReportSettings />,
        children: [
          {
            path: ROUTE_PATHS.REPORT_SETTINGS_PARAMETERS,
            element: <ReportParameters />,
          },
          {
            path: ROUTE_PATHS.REPORT_SETTINGS_FILTERS,
            element: <ReportFilters />,
            action: reportFiltersActions,
          },
        ],
      },
      {
        path: `/${ROUTE_PATHS.SUPER_ADMIN}`,
        element: <SuperAdminSettings />,
        children: [
          {
            path: ROUTE_PATHS.SUPER_ADMIN_SETTINGS,
            loader: superAdminListLoader,
            action: superAdminAction,
            element: <SuperAdminList />,
          },
        ],
      },
    ],
  },
  {
    path: "/login-old",
    element: <LoginOld />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: `/${ROUTE_PATHS.LOGIN_SUCCESS}`,
    element: <LoginSuccess />,
  },
  {
    path: `/${ROUTE_PATHS.WELCOME}`,
    element: <Welcome />,
    loader: welcomeLoader,
    action: welcomeActions,
  },
  {
    path: "/systemadmin-signup",
    element: <SignUp isSuperAdmin />,
  },
  {
    path: "/sign-up",
    element: <SignUp />,
  },
  {
    path: "/denied",
    element: <PermissionDenied />,
  },
  {
    path: "/invalid-code",
    element: <InvalidInvitationCode />,
  },
  {
    path: "/not-found",
    element: <PageNotFound />,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
]);
