import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { CustomerLevel, ReportMeasurement } from "common/constants";
import { useReportSelector, useSelector } from "common/store";
import GradientCard from "components/Card/GradientCard";
import lodashFindLast from "lodash/findLast";
import { RevenueByChangeCategoryChartModel } from "models/report";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  getRollForwardChart,
  getRollForwardChartImpactVolume,
} from "services/reportService";
import {
  computeReportData,
  getRevenueByChangeCategoryTableColumns,
  getRevenueByChangeCategoryTableData,
} from "utils/chartUtils/revenueByChangeCategoryReport";
import useDidUpdateEffect from "utils/hook/useDidUpdateEffect";
import { setChartShouldFetch } from "slices/reportSlice";
import { exportToExcel } from "utils/exportExcelFile/exportExcel";
import moment from "moment";
import { generateFilterSheet } from "utils/exportExcelFile/generateFilterSheet";
import { UNIT } from "utils/exportExcelFile/constants";
import { filterDataByDate } from "utils/report";

import RevenueChangeDrillDownTable from "../charts/RevenueChangeDrillDownTable";
import RollForwardChart from "../charts/RollForward/RollForwardChart";
import RollForwardTable from "../charts/RollForward/RollForwardTable";
import {
  WrapperContainer,
  chartMinHeight,
} from "../components/CommonComponents";

export default function SectionRollForward() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { reportData, reportSettings } = useReportSelector();
  const { params: reportParams, filters: reportFilters } = reportSettings;

  const chartData = useSelector(
    (state) => state.report.chartData["roll-forward"]
  );
  const chartDataVolumeImpact = useSelector(
    (state) => state.report.chartData["roll-forward-impact-volume"]
  );

  const [viewToggle, setViewToggle] = useState<("chart" | "data")[]>(["chart"]);
  const [cMeasure, setCMeasure] = useState<boolean>(false);
  const [selectedPeriod, setPeriod] = useState<string>();
  const [selectedCategory, setCategory] =
    useState<keyof RevenueByChangeCategoryChartModel>();
  const [prevCategory, setPrevCategory] = useState<string>();

  useEffect(() => {
    if (reportData.isLoading || !chartData.shouldFetch) return;
    dispatch(getRollForwardChart());
    if (
      reportSettings.params.customerLevel === CustomerLevel.Customer &&
      reportData.file?.mapping?.some(
        (x) => x.mdtName === "Quantity" && x.sourceName
      )
    ) {
      dispatch(getRollForwardChartImpactVolume());
    }
  }, [reportData.isLoading, chartData.shouldFetch]);

  useDidUpdateEffect(() => {
    dispatch(setChartShouldFetch(["roll-forward"]));
    if (
      reportParams.customerLevel === CustomerLevel.Customer &&
      reportData.file?.mapping?.some(
        (x) => x.mdtName === "Quantity" && x.sourceName
      )
    ) {
      dispatch(setChartShouldFetch(["roll-forward-impact-volume"]));
    }
  }, [
    cMeasure,
    reportParams.customerLevel,
    reportFilters.customers,
    reportFilters.segmentCustomers,
    reportFilters.segmentProducts,
  ]);

  useEffect(() => {
    const hasCMeasure =
      reportSettings.measurement === ReportMeasurement.CARR ||
      reportSettings.measurement === ReportMeasurement.CMRR ||
      reportSettings.measurement === ReportMeasurement.CQRR;
    setCMeasure(hasCMeasure);
  }, [reportSettings.measurement]);

  useEffect(() => {
    if (!prevCategory && selectedCategory) {
      setViewToggle(["chart"]);
    }
  }, [selectedCategory, prevCategory]);

  useEffect(() => {
    setCategory(undefined);
    setPeriod(undefined);
    setPrevCategory(undefined);
  }, [
    reportFilters.minDate,
    reportFilters.maxDate,
    reportFilters.segmentCustomers,
    reportFilters.customers,
    reportParams.reactivationThreshold,
    reportParams.analysisType,
    reportParams.revenueType,
    reportSettings.measurement,
    reportSettings.viewBy,
  ]);

  const filteredByDate = useMemo(
    () =>
      filterDataByDate(
        chartData.data,
        reportFilters.minDate,
        reportFilters.maxDate
      ),
    [chartData.data, reportFilters.minDate, reportFilters.maxDate]
  );

  // Report data with filters applied, ready to put into chart
  const displayableReportData = useMemo(() => {
    if (!filteredByDate) return { computedData: [], subRows: {} };

    return computeReportData(
      filteredByDate,
      reportSettings,
      reportData,
      chartDataVolumeImpact
    );
  }, [filteredByDate, reportSettings, chartDataVolumeImpact, reportData]);

  const monthOfSelectedPeriod = useMemo(() => {
    const p = lodashFindLast(
      displayableReportData.computedData,
      (item: RevenueByChangeCategoryChartModel) =>
        item.xValue === selectedPeriod
    );
    return p?.month || selectedPeriod;
  }, [selectedPeriod, displayableReportData.computedData]);

  const drillldownStartMonth = useMemo(() => {
    return displayableReportData
      ? displayableReportData.computedData[0]?.month || ""
      : "";
  }, [displayableReportData.computedData]);

  const drillldownEndMonth = useMemo(() => {
    return displayableReportData
      ? displayableReportData.computedData.at(-1)?.month || ""
      : "";
  }, [displayableReportData.computedData]);

  const handleOnNextPeriod = useCallback(() => {
    if (!selectedPeriod) {
      return;
    }
    const currentPeriodIndex = displayableReportData.computedData.findIndex(
      (item) => item.xValue === selectedPeriod
    );
    setPeriod(
      displayableReportData.computedData[currentPeriodIndex + 1].xValue
    );
  }, [selectedPeriod]);

  const handleOnPrevPeriod = useCallback(() => {
    if (!selectedPeriod) {
      return;
    }
    const currentPeriodIndex = displayableReportData.computedData.findIndex(
      (item) => item.xValue === selectedPeriod
    );
    setPeriod(
      displayableReportData.computedData[currentPeriodIndex - 1].xValue
    );
  }, [selectedPeriod]);

  useEffect(() => {
    setCategory(undefined);
  }, [reportParams.customerLevel]);

  const handleDoneClick = useCallback(() => {
    setCategory(undefined);
    setPeriod(undefined);
  }, []);

  const columns = useMemo(
    () =>
      getRevenueByChangeCategoryTableColumns(
        displayableReportData.computedData,
        t
      ),
    [displayableReportData.computedData]
  );

  const tableData = useMemo(
    () =>
      getRevenueByChangeCategoryTableData(
        displayableReportData.computedData,
        reportParams.customerLevel
      ),
    [displayableReportData.computedData, reportParams.customerLevel]
  );

  const cardTitle = useMemo(() => {
    const translationKey =
      reportParams.customerLevel === CustomerLevel.Customer
        ? "Dashboard.Charts.RollForwardTitle"
        : "Dashboard.Charts.RollForwardCustomerProductTitle";

    return t(translationKey, {
      view: ReportMeasurement[reportSettings.measurement],
    });
  }, [reportParams.customerLevel, reportSettings.measurement]);

  const handleExport = () => {
    // Category excel file relate to the first column on the table.
    const categories = [
      ...tableData.map((item) => item.category),
      // Must add Ending Balance because no hava category on the header
      "Ending Balance",
    ];

    const headerData = [
      t("Dashboard.DollarsInThounsands"),
      ...displayableReportData.computedData.map((row) =>
        moment(row.xValue, "YYYY-MM", true).isValid()
          ? moment(row.xValue).format("YYYY-MM")
          : row.xValue
      ),
    ];

    const sheet2 = generateFilterSheet(reportSettings, reportData, t);
    const sheet1 = {
      sheetName: "ARR RF",
      header: headerData,
      categories,
      data: displayableReportData.computedData || [],
      subData: displayableReportData.subRows || {},
      option: {
        unitData: UNIT.THOUSANDS,
        highlight: true,
      },
    };

    exportToExcel(
      [sheet1, sheet2],
      `${moment().format("YYYY-MM-DD")}_${cardTitle}`
    );
  };

  return (
    <>
      <Typography variant="h6" marginBottom={2} color="var(--text-secondary)">
        {t("Dashboard.Charts.SectionRollForward", {
          view: ReportMeasurement[reportSettings.measurement],
        })}
      </Typography>
      <GradientCard
        title={cardTitle}
        subheader={
          <Typography variant="body2" fontStyle="italic">
            {t("Dashboard.DollarsInThounsands")}
          </Typography>
        }
        onViewToggle={(x) => setViewToggle(x)}
        onMoreClick={() => console.log("more")}
        viewToggleValue={viewToggle}
        onExport={handleExport}
        isDisabledActions={
          reportData.isLoading ||
          chartData.isLoading ||
          !!chartData.error ||
          !!reportData.error
        }
      >
        <WrapperContainer
          isLoading={reportData.isLoading || chartData.isLoading}
          error={chartData.error || reportData.error}
          isNoData={displayableReportData.computedData.length === 0}
        >
          {viewToggle.includes("chart") && (
            <RollForwardChart
              height={chartMinHeight}
              data={displayableReportData.computedData}
              onDrilldownExit={() => {
                setCategory(undefined);
                setPeriod(undefined);
              }}
              selectedCategory={selectedCategory}
              selectedMonth={selectedPeriod}
              onSelectCategory={(category) => {
                setPrevCategory(selectedCategory);
                setCategory(
                  category as keyof RevenueByChangeCategoryChartModel
                );
              }}
              onSelectMonth={setPeriod}
            />
          )}
          {viewToggle.includes("data") && (
            <Box sx={{ marginTop: 3, width: "100%" }}>
              <RollForwardTable
                tableData={tableData}
                columns={columns}
                subRows={displayableReportData.subRows}
              />
            </Box>
          )}
          {selectedCategory && selectedPeriod && monthOfSelectedPeriod && (
            <Box sx={{ marginTop: 3, width: "100%" }}>
              <RevenueChangeDrillDownTable
                fileId={reportData.file!.id}
                period={selectedPeriod}
                monthOfPeriod={monthOfSelectedPeriod}
                category={selectedCategory}
                startMonth={drillldownStartMonth}
                endMonth={drillldownEndMonth}
                customerLevel={reportParams.customerLevel}
                onNextPeriod={handleOnNextPeriod}
                onPrevPeriod={handleOnPrevPeriod}
                onDone={handleDoneClick}
              />
            </Box>
          )}
        </WrapperContainer>
      </GradientCard>
    </>
  );
}
