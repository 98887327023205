import {
  MoreHorizOutlined,
  ArrowForward,
  EditOutlined,
  LoopOutlined,
  LogoutOutlined,
  LooksOneOutlined,
  MoreHoriz,
  UTurnLeftOutlined,
  ArrowForwardOutlined,
  DownloadOutlined,
} from "@mui/icons-material";
import {
  Stack,
  CardHeader,
  Paper,
  CardContent,
  CardActions,
  Card,
  Typography,
  Button,
  Alert,
  AlertTitle,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  IconButton,
  Menu,
  useTheme,
} from "@mui/material";
import _ from "lodash";
import { FileStatus, ROUTE_PATHS } from "common/constants";
import { PopupState as PopupStateType } from "material-ui-popup-state/hooks";
import MappingSelect from "components/MappingTable/MappingSelect";
import { RowType } from "components/MappingTable/types";
import IconMenu from "components/Menu/IconMenu";
import {
  RevisingStatus,
  useDataMappingCtx,
} from "containers/Workspace/DataMapping/DataMappingContext";
import {
  ErrorsType,
  getErrorType,
} from "containers/Workspace/DataMapping/components/Review.utils";
import { StyledMenuItem } from "containers/Workspace/Manage/components/StyledMenuItem";
import { WORKSPACE_ROUTES, buildReviewRows } from "containers/Workspace/config";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Link,
  Navigate,
  useFetcher,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useToast } from "utils/hook/useNotification";
import Loading from "containers/Workspace/DataMapping/components/Loading";
import SubmitConfirmModal from "containers/Workspace/DataMapping/components/AnalysisConfirmModal";
import Modal from "components/Modal";
import { ErrorTooltip } from "containers/Workspace/DataMapping/components/ErrorTooltip";
import { FISCAL_START_MONTHS } from "common/constants/reportFilters";

export default function Mapping() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { showError } = useToast();
  const [validating, setValidating] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isValidateStarted, setIsValidateStarted] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const fetcher = useFetcher();
  const navigate = useNavigate();
  const {
    fileStatus,
    revising,
    setRevising,
    setFileStatus,
    fields,
    errorSummary,
    errorMessage,
    mappingOrigin,
    mappingResult,
    doMapField,
    resetMapping,
  } = useDataMappingCtx();
  const rows = buildReviewRows(mappingResult);
  const params = useParams();
  const hasAPIErrors =
    !!errorMessage ||
    Object.values(errorSummary || {}).some((item) => item.length > 0);

  const isMappingChanged = useMemo(() => {
    return !_.isEqual(mappingOrigin, mappingResult);
  }, [mappingOrigin, mappingResult]);
  const isSubmitted =
    revising !== RevisingStatus.Doing &&
    fileStatus >= FileStatus.DataValidated &&
    !isMappingChanged;

  const shouldShowMappingError =
    (revising === RevisingStatus.Done && isSubmitted) || isValidateStarted;

  let allErrors = {};
  if (errorSummary) {
    allErrors = { ...allErrors, ...errorSummary };
  }

  if (fetcher.data && fetcher.data.data && fetcher.data.hasError) {
    allErrors = { ...allErrors, ...fetcher.data.data };
  }

  useEffect(() => {
    if (fetcher.data && fetcher.data.data && fetcher.data.hasError) {
      setValidating(false);
    }
    if (fetcher.data && !fetcher.data.ok) {
      showError({ message: fetcher.data.errorMessage });
    }
  }, [fetcher.data]);

  useEffect(() => {
    if (fileStatus >= FileStatus.DataValidated) {
      setValidating(false);
    }
    if (fileStatus === FileStatus.ReportGenerated) {
      setSubmitting(false);
    }
  }, [fileStatus]);

  const getError = useCallback(
    (row: RowType) => {
      return getErrorType(row, allErrors as ErrorsType, mappingResult);
    },
    [mappingResult, allErrors]
  );

  function handleOnMappingChanged(attribute: string, value: string) {
    if (fetcher.data && fetcher.data.data) {
      fetcher.data.hasError = false;
      fetcher.data.data = null;
    }
    doMapField(attribute, value);
    setIsValidateStarted(false);
  }
  if (
    fileStatus === FileStatus.ReportGenerated &&
    !errorSummary &&
    !errorMessage &&
    revising === RevisingStatus.Done
  ) {
    return (
      <Navigate
        to={`/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}/${params.wsId}/${params.dashboardId}/${ROUTE_PATHS.WORKSPACES_REPORT}`}
      />
    );
  }
  if (validating || submitting) {
    return (
      <Stack
        direction="row"
        alignItems="flex-start"
        sx={{
          flexGrow: 1,
          width: "100%",
          height: "calc(100vh - 110px)",
        }}
      >
        <Loading variant={validating ? "validate" : "analyze"} />
      </Stack>
    );
  }
  return (
    <Stack direction="row" alignItems="flex-start" sx={{ flexGrow: 1, mb: 2 }}>
      <Card sx={{ flexGrow: 1 }} elevation={0}>
        <CardHeader
          title={
            <Typography variant="h5">
              {t("ManageSourceData.Mapping.Header")}
            </Typography>
          }
          action={
            <IconMenu
              icon={<MoreHorizOutlined sx={{ color: "white" }} />}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              options={[
                {
                  id: "export",
                  icon: <DownloadOutlined />,
                  title: t("ManageSourceData.Mapping.Export"),
                },
                {
                  id: "divider-1",
                },
                {
                  id: "replace",
                  icon: <EditOutlined />,
                  color: theme.palette.warning.main,
                  title: t("ManageSourceData.Mapping.ReviseBtn"),
                  disabled:
                    revising !== RevisingStatus.Unset ||
                    fileStatus === FileStatus.DataSubmitted,
                  onClick: () => {
                    setOpenConfirmModal(true);
                  },
                },
              ]}
            />
          }
          sx={{
            backgroundImage: `var(--pattern-card-background-image)`,
            backgroundSize: "auto 100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right",
            color: "var(--white)",
          }}
        />
        <Paper elevation={0}>
          <CardContent>
            <Modal
              open={openConfirmModal}
              onClose={() => setOpenConfirmModal(false)}
            >
              <Card elevation={0}>
                <CardHeader
                  title={
                    <Typography variant="h5" color="error.main">
                      {t("ManageSourceData.File.ConfirmHeader")}
                    </Typography>
                  }
                  subheader={
                    <Typography variant="body2" color="error.main">
                      {t("ManageSourceData.File.ConfirmSubHeader")}
                    </Typography>
                  }
                />
                <CardContent>
                  <ul className="list-disc pl-5">
                    <li>
                      <Typography variant="subtitle1" color="text.primary">
                        {t("ManageSourceData.Mapping.ConfirmWarning")}
                      </Typography>
                    </li>
                  </ul>
                </CardContent>
                <CardActions>
                  <Button
                    variant="outlined"
                    color="error"
                    sx={{ borderRadius: "24px" }}
                    onClick={() => {
                      setRevising(RevisingStatus.Doing);
                      setOpenConfirmModal(false);
                    }}
                  >
                    {t("ManageSourceData.Mapping.ConfirmButton")}
                  </Button>
                  <Button
                    sx={{ borderRadius: "24px" }}
                    onClick={() => setOpenConfirmModal(false)}
                  >
                    {t("ManageSourceData.Mapping.CancelButton")}
                  </Button>
                </CardActions>
              </Card>
            </Modal>
            <SubmitConfirmModal
              open={openModal}
              setOpenModal={setOpenModal}
              onSubmit={() => {
                setSubmitting(true);
                setRevising(RevisingStatus.Done);
                fetcher.submit(null, {
                  action: `/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}/${params.wsId}/${params.dashboardId}/${WORKSPACE_ROUTES.CUSTOMER_DATA_REVIEW}`,
                  method: "post",
                });
              }}
            />
            <Card elevation={0}>
              {revising === RevisingStatus.Done &&
                fileStatus >= FileStatus.DataValidated && (
                  <Typography
                    sx={{ pl: "16px" }}
                    variant="h6"
                    textTransform="uppercase"
                    color="text.primary"
                  >
                    {t("MappingReview.ReviewPageHeader")}
                  </Typography>
                )}
              {revising !== RevisingStatus.Unset && (
                <Typography
                  variant="h4"
                  color="text.primary"
                  sx={{ pl: "16px", mb: 2 }}
                >
                  {t("ManageSourceData.Mapping.SubHeader")}
                </Typography>
              )}
              <Box
                sx={{
                  pl: "16px",
                }}
              >
                {revising === RevisingStatus.Doing && (
                  <Typography variant="h5">
                    {t("ManageSourceData.Mapping.SubHeader2")}
                  </Typography>
                )}
                {revising === RevisingStatus.Done ? (
                  <>
                    {fileStatus === FileStatus.DataValidated &&
                      !hasAPIErrors &&
                      !isMappingChanged && (
                        <Alert severity="success">
                          <Typography color="#2E7D57">
                            {t("MappingReview.ValidatedAlertMsg")}
                          </Typography>
                        </Alert>
                      )}
                    {((fetcher.data && fetcher.data.hasError) ||
                      (fileStatus === FileStatus.DataValidated &&
                        hasAPIErrors &&
                        !isMappingChanged)) && (
                      <Alert
                        severity="error"
                        sx={{
                          backgroundColor: "#FDEDED",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#5F2120",
                          }}
                        >
                          {t("MappingReview.ValidationErrorMsg")}
                        </Typography>
                      </Alert>
                    )}
                  </>
                ) : (
                  <>
                    {fileStatus === FileStatus.DataSubmitted && (
                      <Alert
                        security="info"
                        color="info"
                        action={
                          <Button
                            onClick={() =>
                              navigate(
                                `/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}`
                              )
                            }
                            sx={{ color: "#014361" }}
                          >
                            {t("MappingReview.ToWorkspacesAction")}
                          </Button>
                        }
                      >
                        <AlertTitle>
                          {t("MappingReview.AnalyzingHeader")}
                        </AlertTitle>
                        {t("MappingReview.AnalyzingDescription")}
                      </Alert>
                    )}
                  </>
                )}
              </Box>
              <CardContent>
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {shouldShowMappingError && (
                          <TableCell
                            sx={{
                              border: "none",
                              width: "15px",
                            }}
                          />
                        )}
                        <TableCell>
                          {t(
                            "workspace.dataMapping.mappingTableFields.attribute"
                          )}
                        </TableCell>
                        <TableCell>
                          {t(
                            "workspace.dataMapping.mappingTableFields.sourceDataField"
                          )}
                        </TableCell>
                        <TableCell>
                          {t(
                            "workspace.dataMapping.mappingTableFields.example"
                          )}
                        </TableCell>
                        <TableCell>
                          {t(
                            "workspace.dataMapping.mappingTableFields.dataType"
                          )}
                        </TableCell>
                        {revising !== RevisingStatus.Unset && (
                          <TableCell sx={{ width: "30px", border: "none" }} />
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <>
                          {row.sectionName && (
                            <TableRow
                              key={row.sectionName}
                              sx={{
                                width: "100%",
                              }}
                            >
                              {shouldShowMappingError && (
                                <TableCell sx={{ border: "none" }} />
                              )}
                              <TableCell
                                sx={{
                                  padding: 0,
                                  border: "none",
                                }}
                              >
                                {row.sectionName && (
                                  <CardHeader
                                    sx={{
                                      margin: 0,
                                      padding: 0,
                                      height: "24px",
                                    }}
                                    title={
                                      <Typography
                                        variant="overline"
                                        color="primary.dark"
                                        textTransform="uppercase"
                                      >
                                        {row.sectionName}
                                      </Typography>
                                    }
                                  />
                                )}
                              </TableCell>
                              <TableCell
                                sx={{
                                  border: "none",
                                }}
                              />
                              <TableCell
                                sx={{
                                  border: "none",
                                }}
                              />
                              <TableCell
                                sx={{
                                  border: "none",
                                }}
                              />
                            </TableRow>
                          )}
                          <TableRow
                            key={row.attribute}
                            sx={{
                              backgroundColor:
                                getError(row).errorCode > 0
                                  ? "rgba(211, 47, 47, 0.04)"
                                  : "unset",
                            }}
                          >
                            {shouldShowMappingError && (
                              <TableCell
                                sx={{
                                  border: "none",
                                  width: "fit-content",
                                  px: 0,
                                  pr: "8px",
                                  backgroundColor: "#FFF",
                                }}
                              >
                                <ErrorTooltip
                                  errorType={getError(row)}
                                  row={row}
                                />
                              </TableCell>
                            )}
                            <TableCell>
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {t(row.attributeLabel || "attribute") ||
                                    row.attribute}
                                </Typography>
                                <ArrowForward
                                  sx={{
                                    color: "action.active",
                                    width: "24px",
                                    height: "24px",
                                  }}
                                />
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <Stack
                                sx={{ minWidth: 250 }}
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                gap={2}
                              >
                                {revising !== RevisingStatus.Unset ? (
                                  <MappingSelect
                                    invalid={getError(row).errorCode > 0}
                                    sourceFields={fields}
                                    mappingValues={mappingResult}
                                    onChange={handleOnMappingChanged}
                                    row={row}
                                  />
                                ) : (
                                  <Typography
                                    variant="body2"
                                    color={
                                      getError(row).errorCode > 0
                                        ? "error"
                                        : "primary.main"
                                    }
                                    fontWeight="700"
                                  >
                                    {row.attribute === "FiscalStartMonth"
                                      ? FISCAL_START_MONTHS.find(
                                          (item) =>
                                            item.value ===
                                            mappingResult[row.attribute]
                                        )?.label
                                      : mappingResult[row.attribute]}
                                  </Typography>
                                )}
                                <ArrowForward
                                  sx={{
                                    color: "action.active",
                                    width: "24px",
                                    height: "24px",

                                    visibility:
                                      row.attribute === "FiscalStartMonth"
                                        ? "hidden"
                                        : "visible",
                                  }}
                                />
                              </Stack>
                            </TableCell>
                            <TableCell
                              sx={{ maxWidth: "140px", padding: "16px" }}
                            >
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{
                                  visibility:
                                    row.attribute === "FiscalStartMonth"
                                      ? "hidden"
                                      : "visible",
                                }}
                              >
                                {row.example}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Stack
                                direction="row"
                                alignItems="center"
                                gap={1}
                              >
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                  sx={{
                                    visibility:
                                      row.attribute === "FiscalStartMonth"
                                        ? "hidden"
                                        : "visible",
                                  }}
                                >
                                  {t(row.dataType)}
                                </Typography>
                              </Stack>
                            </TableCell>
                            {revising !== RevisingStatus.Unset && (
                              <TableCell
                                sx={{
                                  px: 0,
                                  backgroundColor: "#FFF",
                                  border: "none",
                                }}
                              >
                                <IconButton>
                                  <PopupState
                                    variant="popover"
                                    popupId="teams-cardview-popup-menu"
                                  >
                                    {(popupState: PopupStateType) => (
                                      <>
                                        <MoreHoriz
                                          {...bindTrigger(popupState)}
                                        />
                                        <Menu {...bindMenu(popupState)}>
                                          <StyledMenuItem
                                            onClick={() => {
                                              row.stepURL &&
                                                navigate(
                                                  `/${ROUTE_PATHS.TEAMS}/${
                                                    params.id
                                                  }/${ROUTE_PATHS.WORKSPACES}/${
                                                    params.wsId
                                                  }/${
                                                    params.dashboardId
                                                  }${row.stepURL.replace(
                                                    "..",
                                                    ""
                                                  )}`
                                                );
                                              popupState.close();
                                            }}
                                          >
                                            <UTurnLeftOutlined />{" "}
                                            {t(
                                              "MappingReview.FieldMenu.ToStep"
                                            )}
                                          </StyledMenuItem>
                                          <StyledMenuItem
                                            onClick={() => {
                                              navigate(
                                                `/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}/${params.wsId}/${params.dashboardId}/${WORKSPACE_ROUTES.CUSTOMER_DATA}`
                                              );
                                              popupState.close();
                                            }}
                                          >
                                            <LooksOneOutlined />{" "}
                                            {t(
                                              "MappingReview.FieldMenu.ToFirstStep"
                                            )}
                                          </StyledMenuItem>
                                          {!row.isRequired && <Divider />}
                                          {!row.isRequired && (
                                            <StyledMenuItem
                                              onClick={() => {
                                                doMapField(row.attribute, "");
                                                popupState.close();
                                              }}
                                            >
                                              <LogoutOutlined />{" "}
                                              {t(
                                                "MappingReview.FieldMenu.RemoveAttr"
                                              )}
                                            </StyledMenuItem>
                                          )}
                                        </Menu>
                                      </>
                                    )}
                                  </PopupState>
                                </IconButton>
                              </TableCell>
                            )}
                          </TableRow>
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </CardContent>
          <CardActions
            sx={{
              pl: "24px",
              mb: "24px",
            }}
          >
            {revising !== RevisingStatus.Unset && (
              <>
                {isSubmitted &&
                fileStatus >= FileStatus.DataValidated &&
                !hasAPIErrors &&
                !isMappingChanged ? (
                  <Button
                    variant="contained"
                    disabled={fileStatus >= FileStatus.DataSubmitted}
                    onClick={() => setOpenModal(true)}
                  >
                    {fileStatus >= FileStatus.DataSubmitted ? (
                      <Stack direction="row" gap="4px">
                        <LoopOutlined
                          sx={{
                            width: "24px",
                            height: "24px",
                          }}
                        />
                        {t("MappingReview.AnalyzingLabel")}
                      </Stack>
                    ) : (
                      <>{t("MappingReview.AnalysisBtn")}</>
                    )}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    disabled={
                      fileStatus === FileStatus.DataSubmitted ||
                      !isMappingChanged
                    }
                    sx={{ borderRadius: "120px" }}
                    onClick={() => {
                      setIsValidateStarted(true);
                      setFileStatus(FileStatus.DataImported);
                      if (fetcher.data && fetcher.data.hasError) {
                        fetcher.data.hasError = false;
                      }
                      setValidating(true);
                      setRevising(RevisingStatus.Done);
                      fetcher.submit(
                        {
                          data: JSON.stringify({
                            mappingObj: mappingResult,
                            columns: fields,
                          }),
                        },
                        {
                          action: `/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}/${params.wsId}/${params.dashboardId}/${WORKSPACE_ROUTES.CUSTOMER_DATA_REVIEW}`,
                          method: "post",
                        }
                      );
                    }}
                  >
                    {t("MappingReview.ValidateBtn")}
                  </Button>
                )}
                <Button
                  sx={{ borderRadius: "120px" }}
                  onClick={() => {
                    resetMapping();
                    setRevising(RevisingStatus.Unset);
                  }}
                >
                  {t("workspace.dataMapping.cancelBtn")}
                </Button>
              </>
            )}
            {revising === RevisingStatus.Unset && (
              <Button
                startIcon={<EditOutlined />}
                disabled={fileStatus === FileStatus.DataSubmitted}
                color="warning"
                sx={{
                  textTransform: "capitalize",
                  borderRadius: "120px",
                }}
                onClick={() => {
                  setOpenConfirmModal(true);
                }}
              >
                {t("ManageSourceData.Mapping.ReviseBtn")}
              </Button>
            )}
          </CardActions>
          {((fileStatus < FileStatus.DataValidated &&
            fileStatus > FileStatus.DataImported) ||
            revising === RevisingStatus.Doing) && (
            <CardActions
              sx={{
                mt: "-24px",
                mb: "24px",
                ml: "24px",
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                gap="8px"
              >
                <Typography variant="body1" color="text.primary">
                  {t("ManageSourceData.Mapping.AddNewAttr")}
                </Typography>
                <Link
                  to={`/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}/${params.wsId}/${params.dashboardId}/${WORKSPACE_ROUTES.CUSTOMER_DATA}`}
                >
                  <Stack direction="row" alignItems="center" gap="4px">
                    <Typography variant="body1" color="primary">
                      {t("ManageSourceData.Mapping.StartFromStep1")}
                    </Typography>
                    <ArrowForwardOutlined color="primary" />
                  </Stack>
                </Link>
              </Stack>
            </CardActions>
          )}
        </Paper>
      </Card>
      <Card elevation={0}></Card>
    </Stack>
  );
}
