import { administrationClient } from "common/client/administrationClient";
import { LAST_ACESS_DEFAULT, ROUTE_PATHS } from "common/constants";
import { getAccessToken } from "common/providers/AuthUserProvider";
import { useSelector } from "common/store";
import SplashScreen from "components/Router/SplashScreen";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthUser } from "utils/hook/useAuthUser";

export default function LoginSuccess() {
  const navigate = useNavigate();
  const {
    isSystemAdmin,
    isFirstLogin,
    teams,
    isLoading: isLoadingTeam,
  } = useSelector((state) => state.team);

  const { fetchAuthUser } = useAuthUser();

  useEffect(() => {
    if (!getAccessToken()) return;
    fetchAuthUser();
  }, []);

  useEffect(() => {
    if (isLoadingTeam || !teams) return;

    if (isFirstLogin) {
      navigate(`/${ROUTE_PATHS.WELCOME}`);
      return;
    }
    administrationClient.get("/profile/last-access").then((response) => {
      let teamId =
        response?.data?.data === LAST_ACESS_DEFAULT
          ? teams?.[0]?.id
          : response?.data?.data;
      if (teams.length > 0 && !teams.some((item) => item.id === teamId)) {
        teamId = teams[0].id;
      }
      if (!teamId || teams.length === 0) teamId = LAST_ACESS_DEFAULT;
      if (isSystemAdmin) {
        localStorage.setItem("lastTeamId", teamId);
        navigate(`/${ROUTE_PATHS.TEAMS}`);
      } else {
        navigate(`/${ROUTE_PATHS.TEAMS}/${teamId}/${ROUTE_PATHS.WORKSPACES}`);
      }
    });
  }, [isSystemAdmin, teams, isLoadingTeam]);
  return <SplashScreen />;
}
