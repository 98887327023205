import Table from "components/Table";
import { Column } from "components/Table/models";
import { CategoriesAndPeriodsTableModel } from "models/report";

interface ContractRenewalDetailTableProps {
  columns: Column<CategoriesAndPeriodsTableModel>[];
  tableData: CategoriesAndPeriodsTableModel[];
}

export default function ContractRenewalDetailTable(
  props: ContractRenewalDetailTableProps
) {
  const { columns, tableData } = props;

  return (
    <Table
      data={tableData}
      columns={columns}
      rowKey="category"
      bodySx={{ zIndex: 0, position: "relative" }}
      footerSx={{
        bottom: 0,
        position: "sticky",
        backgroundColor: "white",
      }}
      stickyHeader
    />
  );
}
