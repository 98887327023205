import {
  MenuItem,
  Stack,
  Typography,
  Box,
  Button,
  Collapse,
} from "@mui/material";
import {
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
} from "@mui/icons-material";
import { ReactNode, forwardRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDataMappingCtx } from "containers/Workspace/DataMapping/DataMappingContext";
import { getLabelFromAttrHelper } from "containers/Workspace/config";

import { RowType } from "./types";

interface MappingMenuItemProp {
  value: string;
  children: ReactNode;
  row: RowType;
  handleClose: () => void;
}

const MappingMenuItem = forwardRef<HTMLLIElement, MappingMenuItemProp>(
  function MappingMenuItem(props, ref) {
    const { children, row, handleClose } = props;
    const { setOpenModal, mappingResult } = useDataMappingCtx();
    const { t } = useTranslation();
    const currentMappedTo = Object.entries(mappingResult).find(([, value]) => {
      return value === children;
    })?.[0];
    const [expanded, setExpanded] = useState(false);

    return (
      <MenuItem
        {...props}
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
        }}
        sx={{
          p: 0,
          cursor: "default",
          "&:hover": {
            background: "#FFF",
          },
        }}
      >
        <Stack sx={{ width: "100%" }}>
          <Stack
            direction="row"
            sx={{
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
              p: "8px 16px",
            }}
          >
            <Typography color="text.disabled">{children}</Typography>
            {expanded ? (
              <ArrowDropUpOutlined
                data-testid="MappingMenuItem__collapseBtn"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setExpanded(false);
                }}
              />
            ) : (
              <ArrowDropDownOutlined
                data-testid="MappingMenuItem__expandBtn"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setExpanded(true);
                }}
              />
            )}
          </Stack>
          <Collapse in={expanded}>
            <Box
              sx={{
                width: "100%",
                background: "var(--grey-200, #EEE)",
                p: "8px 16px",
              }}
            >
              <Typography>
                <Trans
                  i18nKey="MappingTable.LabelCurrentlyMapped"
                  components={{
                    AttrLabel: <em />,
                  }}
                  values={{
                    attr: t(getLabelFromAttrHelper(currentMappedTo || "")),
                  }}
                />
              </Typography>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setOpenModal(
                    true,
                    {
                      row,
                      fieldFromFile: children as string,
                      currentMappedTo: currentMappedTo || "",
                    },
                    handleClose
                  );
                }}
              >
                {t("MappingTable.ButtonRemap")}
              </Button>
            </Box>
          </Collapse>
        </Stack>
      </MenuItem>
    );
  }
);

export default MappingMenuItem;
