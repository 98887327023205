import { ReportMeasurement } from "common/constants";
import { Show } from "models/report";

interface Option {
  value: string;
  labelKey: string;
  disabled?: boolean;
}
interface ByOption extends Option {
  availableFor: ("revenue" | "customer" | "volume")[];
}

export const MEASUREMENT_MAPPING = {
  [ReportMeasurement.ARR]: "arr",
  [ReportMeasurement.MRR]: "mrr",
  [ReportMeasurement.CARR]: "carr",
  [ReportMeasurement.CMRR]: "cmrr",
  [ReportMeasurement.QRR]: "qrr",
  [ReportMeasurement.CQRR]: "cqrr",
};
export const MEASUREMENT_LABEL_MAPPING = {
  [ReportMeasurement.ARR]: "Common.ARR",
  [ReportMeasurement.MRR]: "Common.MRR",
  [ReportMeasurement.CARR]: "Common.CARR",
  [ReportMeasurement.CMRR]: "Common.CMRR",
  [ReportMeasurement.QRR]: "Common.QRR",
  [ReportMeasurement.CQRR]: "Common.CQRR",
};

const byOptions: ByOption[] = [
  {
    value: "base",
    labelKey: "Dashboard.CohortsCard.BySelect.Base",
    availableFor: ["revenue", "customer"],
  },
  {
    value: "churn-only",
    labelKey: "Dashboard.CohortsCard.BySelect.ChurnOnly",
    availableFor: ["revenue"],
  },
  {
    value: "gross",
    labelKey: "Dashboard.CohortsCard.BySelect.GrossRetention",
    availableFor: ["revenue"],
  },
  {
    value: "net",
    labelKey: "Dashboard.CohortsCard.BySelect.NetRetention",
    availableFor: ["revenue"],
  },
  {
    value: "customer",
    labelKey: "Dashboard.CohortsCard.BySelect.CustomerRetention",
    availableFor: ["customer"],
  },
];

function shouldDisplayOptions(option: ByOption, showValue: Show) {
  if (showValue === "count") {
    return option.availableFor.includes("customer");
  }
  if (showValue === "volume") {
    return option.availableFor.includes("volume");
  }
  return option.availableFor.includes("revenue");
}

export function getByOptions(showValue?: Show) {
  if (!showValue) {
    return [];
  }
  return byOptions.filter((o) => shouldDisplayOptions(o, showValue));
}

export function getShowOptions(measurement: ReportMeasurement): Option[] {
  return [
    {
      value: MEASUREMENT_MAPPING[measurement],
      labelKey: MEASUREMENT_LABEL_MAPPING[measurement],
    },
    {
      value: "count",
      labelKey: "Dashboard.CohortsCard.LabelCustomerCount",
    },
    {
      value: "volume",
      labelKey: "Common.Volume",
      disabled: true,
    },
  ];
}
