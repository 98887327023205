import { Box, Stack } from "@mui/material";
import { useOutletContext } from "react-router-dom";

import classes from "./index.module.css";
import SectionCohorts from "./sections/SectionCohorts";
import SectionCountChanges from "./sections/SectionCountChanges";
import SectionOverview from "./sections/SectionOverview";
import SectionRollForward from "./sections/SectionRollForward";
import SectionTopCustomers from "./sections/SectionTopCustomers";
import SectionContractRenewal from "./sections/SectionContractRenewal";
import SectionARRChangesOverview from "./sections/SectionARRChangesOverview";
import { ReportRouteContext } from "./types";
import SectionARROverview from "./sections/SectionARROverview";
import DashboardToolbar from "./components/DashboardToolbar";
import { DRAWER_WIDTH } from "./constants";

export default function ReportsContainer() {
  const { sectionRefs, workspace } = useOutletContext<ReportRouteContext>();

  return (
    <>
      <Stack
        sx={{
          position: "fixed",
          width: `calc(100% - ${DRAWER_WIDTH + 48}px)`,
          backgroundColor: "var(--color-mapping-layout-bg)",
          opacity: 1,
          zIndex: 10,
          color: "text.primary",
          top: "64px",
          pt: 2,
          maxHeight: "180px",
          overflowY: "auto",
          overflowX: "auto",
          boxShadow: "none",
        }}
      >
        <DashboardToolbar workspace={workspace} />
      </Stack>
      <section
        ref={sectionRefs.SectionARROverView}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
          gap: "23px",
        }}
      >
        <Box marginBottom={3} sx={{ width: "50%" }}>
          <SectionARROverview />
        </Box>
        <Box marginBottom={3} sx={{ width: "50%" }}>
          <SectionARRChangesOverview />
        </Box>
      </section>
      <section
        className={classes.sectionRollForward}
        ref={sectionRefs.SectionRollForward}
      >
        <Box marginBottom={3}>
          <SectionRollForward />
        </Box>
      </section>
      <section ref={sectionRefs.SectionGrowthRate}>
        <Box marginBottom={3}>
          <SectionOverview />
        </Box>
      </section>
      <section
        className={classes.sectionCountChanges}
        ref={sectionRefs.SectionCountChanges}
      >
        <Box marginBottom={3}>
          <SectionCountChanges />
        </Box>
      </section>
      <section ref={sectionRefs.SectionTopCustomers}>
        <Box marginBottom={3}>
          <SectionTopCustomers />
        </Box>
      </section>
      <section ref={sectionRefs.SectionCohorts}>
        <Box marginBottom={3}>
          <SectionCohorts />
        </Box>
      </section>
      <section ref={sectionRefs.SectionContractRenewal}>
        <Box marginBottom={3}>
          <SectionContractRenewal />
        </Box>
      </section>
    </>
  );
}
